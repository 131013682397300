import React, { useEffect, useState } from "react";
import ProjectService from "../services/ProjectService";
import Select from "./Select";

const ProjectSelect = ({ label, onInputChange, projectList, oninputProjectChange,required }) => {
  const [projectsList, setProjectList] = useState([]);

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = async () => {
    const data = await ProjectService.getProjectList();
    setProjectList(data);
    projectList && projectList(data);

    return data;
  };

  const projectChange = (e) => {
    oninputProjectChange && oninputProjectChange(e?.values?.projectName)
  };

  return (
    <div>
      <Select
        name="projectName"
        options={projectsList}
        onInputChange={onInputChange ? onInputChange : projectChange}
        label={label}
        menuPortal=""
        required={required}
      />
    </div>
  );
};

export default ProjectSelect;
