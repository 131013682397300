import React from "react"
import Select from "./Select"
import ProjectComponentService from "../services/ProjectComponentService"
import { useEffect } from "react"
import { useState } from "react"
import Url from "../lib/Url"
import Status from "../helpers/ProjectComponent"

const ProjectComponent = (props) => {

  let { name, placeholder, handleProjectComponentChange, required, label, projectId, typeList, showDefaultValue } = props;

  const [component, setComponent] = useState([])

  useEffect(() => {
    getProjectComponentDetails()
  }, [projectId])

  let getProjectComponentDetails = async () => {
    const project_id = projectId ? projectId.toString() : Url.GetParam("projectId")
    let response = await ProjectComponentService.search({ projectId: project_id, status: Status.Active })
    let data = response && response?.data && response?.data?.data;
    if (response && response?.data && response?.data?.data) {
      let list = []
      for (let i = 0; i < data.length; i++) {
        const { name, id, userId, userName, default_story_point } = data[i];
        list.push({
          label: name, id: id, value: id, assigneeId: userId, assigneeName: userName, default_story_point: default_story_point
        })
      }

      setComponent(list)
      typeList && typeList(list)
    } else {
      setComponent([])
    }
  }

  return (
    <div>
      <Select
        name={name ? name : "component"}
        placeholder={placeholder ? placeholder : "Select Component"}
        onInputChange={handleProjectComponentChange}
        label={label ? label : ""}
        options={component}
        showDefaultValue={showDefaultValue}
        required={required}
      />
    </div>
  )
}

export default ProjectComponent
