import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import AvatarCard from "../../../components/AvatarCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";

const StoreNotAssignedUserList = (props) => {
  const { filteredUserList } = props

  
  return (
    <>
      <ReduxTable
        id={"storeNotAssignedList"}
        showHeader
        apiURL={[]}
        icon={<FontAwesomeIcon icon={faStar} />}
        message="You can start by clicking add."
        sortByDropdown
        dataList={filteredUserList}
      >
      <ReduxColumn
          field="userName"
          width="110px"
          sortBy="userName"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <div className="d-flex text-break">
                  <AvatarCard
                    id="avatar"
                    firstName={row.first_name}
                    lastName={row.last_name}
                    url={row.media_url}
                  />
            </div>
          )}
        >
          Store Not Assigned User List
        </ReduxColumn>
      </ReduxTable>
    </>
  )
}

export default StoreNotAssignedUserList