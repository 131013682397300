import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import BreadCrumb from "../../../components/Breadcrumb";
import DeleteModal from "../../../components/DeleteModal";
import Drawer from "../../../components/Drawer";
import PageTitle from "../../../components/PageTitle";
import SaveButton from "../../../components/SaveButton";
import Text from "../../../components/Text";
import TextArea from "../../../components/TextArea";
import PageBlockFieldsService from "../../../services/PageBlockFieldsService";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import { endpoints } from "../../../api/endPoints";
import { TagsIcon } from "../../../assets/icons";
import FeatureImage from "../../../components/Image";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";
import ObjectName from "../../../helpers/ObjectName";
import { fetchList } from "../../../actions/table";
import Media from "../../../helpers/Media";
import MediaUpload from "../../../helpers/MediaUpload";
import DragAndDropField from "../../../components/FileUpload";

const PageBlockFields = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [row, setRow] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState()
  let dispatch = useDispatch();

  let urlValue = props && props?.match && props?.match?.url && props?.match?.url?.split("/")

  let initialValues = {
    title: row && row?.title ? row?.title : "",
    description: row && row?.description ? row?.description : ""
  }


  const openToggle = () => {
    setIsOpen(!isOpen)
  }

  const closeToggle = () => {
    setIsOpen(!isOpen)
    setRow(null)
    setSelectedFile()
  }

  const handleSubmit = async (values) => {
    let data = new FormData();
    data.append("title", values && values?.title ? values?.title : "");
    data.append("description", values && values?.description ? values?.description : "");
    data.append(
      "media_file",
      selectedFile !== undefined ? selectedFile : null
    );
    data.append("page_block_id", props?.match?.params?.id)
    if (row && row?.id) {
      dispatch(await PageBlockFieldsService.update(row?.id, data, async (res) => {
        if (res) {
          if (selectedFile && selectedFile !== undefined) {
            await MediaUpload.uploadFile(
              selectedFile,
              row?.id,
              ObjectName.PAGE_BLOCK_FIELDS,
              "",
              Media.VISIBILITY_PUBLIC,
              "",
              (res) => {
                if (res) {
                  dispatch(
                    fetchList(
                      "pageBlockField",
                      `${endpoints().PageBlockFieldsAPI}/search`,
                      1,
                      25,
                      {
                        page_block_id: props?.match?.params?.id
                      }
                    )
                  );
                  closeToggle()

                }
              }
            );
          } else {
            if (res) {
              dispatch(
                fetchList(
                  "pageBlockField",
                  `${endpoints().PageBlockFieldsAPI}/search`,
                  1,
                  25,
                  {
                    page_block_id: props?.match?.params?.id
                  }
                )
              );
              closeToggle()
            }
          }
        }
      }))
    } else {
      dispatch(await PageBlockFieldsService.create(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "pageBlockField",
              `${endpoints().PageBlockFieldsAPI}/search`,
              1,
              25,
              {
                page_block_id: props?.match?.params?.id
              }
            )
          );
          closeToggle()
        }


        if (res) {
          if (selectedFile && selectedFile !== undefined) {
            MediaUpload.uploadFile(
              selectedFile,
              res?.data?.data?.id,
              ObjectName.PAGE_BLOCK_FIELDS,
              "",
              Media.VISIBILITY_PUBLIC,
              "",
              (res) => {
                if (res) {
                  dispatch(
                    fetchList(
                      "pageBlockField",
                      `${endpoints().PageBlockFieldsAPI}/search`,
                      1,
                      25,
                      {
                        page_block_id: props?.match?.params?.id
                      }
                    )
                  );
                  closeToggle()

                }
              }
            );
          } else {
            if (res) {
              dispatch(
                fetchList(
                  "pageBlockField",
                  `${endpoints().PageBlockFieldsAPI}/search`,
                  1,
                  25,
                  {
                    page_block_id: props?.match?.params?.id
                  }
                )
              );
              closeToggle()
            }
          }
        }
      }))
    }
  }


  const deletePageBlockField = async (id) => {
    dispatch(await PageBlockFieldsService.delete(id, (res) => {
      dispatch(
        fetchList(
          "pageBlockField",
          `${endpoints().PageBlockFieldsAPI}/search`,
          1,
          25,
          {
            page_block_id: props?.match?.params?.id
          }
        )
      );
      setDeleteModal(false);
      setRow(null)
    }))
  };


  const breadCrumbList = [
    { label: "Settings", link: "/setting/Home" },
    {
      label: "Home",
      link: "/setting/Home",
    },
    {
      label: "Page Block",
      link: `/setting/Statues/Home/pageBlock/${urlValue[5]}`,
    },
    {
      label: "Page Block Fields",
      link: "",
    },
  ];


  const onDropImage = async (file) => {
    setSelectedFile(file && file[0]);
  }


  let modelBody = (
    <>
      <Text name="title" label="Title" required />
      <TextArea name="description" label="Description" />
      <DragAndDropField
        label="Attachement"
        onDrop={onDropImage}
        width="100%"
        selectedFile={selectedFile}
        initialValue={
          selectedFile == undefined ? row && row?.media_url : ""
        }
      />
    </>
  );

  let modelFooter = (
    <>
      <div className="d-flex align-items-center">
        <SaveButton type="submit" label={row?.id ? "Edit" : "Add"} />
      </div>
    </>
  )

  return (
    <div>
      <BreadCrumb list={breadCrumbList} />
      <DeleteModal
        label={row?.title}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Page Block Field"
        deleteFunction={() => deletePageBlockField(row?.id)}
      />
      <PageTitle label="Page Blocks Field" buttonLabel="Add" buttonHandler={() => {
        openToggle()
      }} />

      <Drawer
        DrawerBody={modelBody}
        initialValues={initialValues}
        handleOpenModal={openToggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        enableReinitialize
        modelTitle={row?.id ? "Edit" : "Add"}
        DrawerFooter={modelFooter}
        onSubmit={handleSubmit}
      />
      <ReduxTable
        id="pageBlockField"
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().PageBlockFieldsAPI}/search`}
        newTableHeading
        icon={<TagsIcon />}
        message="You can start by clicking Add New"
        paramsToUrl={true}
        history={props.history}
        params={{page_block_id: props?.match?.params?.id}}
        sortByDropdown
      >
        <ReduxColumn
          field="title"
          sortBy="title"
          className="text-center"
        >
          Title
        </ReduxColumn>
        <ReduxColumn
          field="description"
          sortBy="description"
          className="text-center"
        >
          Description
        </ReduxColumn>
        <ReduxColumn
          field="attachement"
          sortBy="attachement"
          className="text-center"
          renderField={(row) => (
            <FeatureImage
              size="imageSize"
              src={row?.media_url}
              className="img-fluid p-1 bg-white border rounded"
              minWidth="70px"
              maxHeight="80px"
            />
          )}
        >
          Attachement
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          width="90px"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    openToggle()
                    setRow(row)
                  }}
                >
                  Quick View
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setDeleteModal(true);
                    setRow(row)
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>

      </ReduxTable>


    </div>
  )
}

export default PageBlockFields;
