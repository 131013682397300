import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MediaUpload from "../helpers/MediaUpload";
import ObjectName from "../helpers/ObjectName";
import { AtomicBlockUtils, EditorState } from "draft-js";
import { getRandomId } from "../lib/Helper";

function DraftEditor(props) {
  const {
    onChange,
    editorState,
    showEditButton,
    onBlur,
    mentionList = [],
    readOnly,
    placeholder,
    hideOutLine,
    height,
    disabled,
    error,
    errorMessage,
  } = props;

  const [editable, setEditable] = useState(false);

  const uploadImageCallBack = async (file) => {
    let objectId = getRandomId();
    //Image Upload and Drag and Drop callback function
    return new Promise(async (resolve, reject) => {
      await MediaUpload.uploadFile(
        file && file,
        objectId,
        ObjectName.COMMENT,
        "",
        null,
        null,
        (res) => {
          resolve({ data: { link: res?.mediaUrl } });
        }
      );
    });
  };

  const handleDragAndDropFileUpload = async (file) => {
    /* ✴---Pasted images and file upload in s3---✴ */
    let objectId = getRandomId();
    await MediaUpload.uploadFile(
      file[0] && file[0],
      objectId,
      ObjectName.COMMENT,
      "",
      null,
      null,
      (res) => {
        /* ✴---Get S3 Media Url---✴ */
        onChange && onChange(insertImage(res?.mediaUrl));
      }
    );
  };

  const insertImage = (imgUrl) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: imgUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  return (
    <>
      {props?.label && <h6 className="fw-normal">{props?.label}</h6>}
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName={!hideOutLine ? "wrapperClassName" : ""}
        toolbarHidden={!editable || disabled}
        editorClassName="editorClassName"
        onEditorStateChange={onChange}
        handlePastedFiles={handleDragAndDropFileUpload}
        editorStyle={{
          border: !hideOutLine
            ? "1px solid var(--page-create-ticket-description-border-color)"
            : "",
          minHeight: height ? height : "200px",
          padding: "10px",
          backgroundColor: "var(--page-create-ticket-description-bg-color)",
          borderRadius: "0px 0px 1px 1px",
          overflowY: "scroll",
          height: height ? height : "200px",
        }}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false },
            previewImage: true,
            inputAccept: "image/*",
          },
          options: [
            "image",
            "emoji",
            "embedded",
            "colorPicker",
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "link",
            "remove",
            "history",
          ],
        }}
        customStyleMap={{
          HIGHLIGHT: {
            backgroundColor:
              "var(--page-create-ticket-description-text-bg-color)",
          },
        }}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: mentionList,
        }}
        onFocus={() => {
          if (!disabled) {
            setEditable(true);
          }
        }}
        readOnly={readOnly || disabled}
        placeholder={placeholder}
      />
      {error && (
        <div className="text-danger">
          {errorMessage || "Comment is required."}
        </div>
      )}
      {showEditButton && (
        <div className="section-title inline-edit-section">
          <div
            className={`test-suite-page-edit ${
              editable ? "d-block float-end" : "d-none"
            }`}
          >
            <i
              className="fas fa-check me-2"
              onClick={() => {
                onBlur && onBlur();
                setEditable(false);
              }}
            ></i>
            <i
              className="fas fa-times"
              onClick={() => {
                setEditable(false);
              }}
            ></i>
          </div>
        </div>
      )}
    </>
  );
}

export default DraftEditor;
