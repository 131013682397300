import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, TabContent, TabPane } from "reactstrap";

// Components
import Action from "../../components/Action";
import AddButton from "../../components/AddButton";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import DragAndDropTable from "../../components/StatusTable/StatusDragAndDropTable";
import Tab from "../../components/Tab";
import ObjectName from "../../helpers/ObjectName";
import Url from "../../lib/Url";
import orderTypeService from "../../services/OrderTypeService";
import OrderTypeForm from "./components/OrderTypeForm";

const Tabs = {
  GENERAL: "General",
  STATUS: "Status",
};

const OrderTypeDetail = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.GENERAL);
  const [details, setDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [row, setRow] = useState("");

  let dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getOrderTypeDetail();
  }, []);


  const deleteOrderType = async () => {
    let id = props.match.params.id;
    let params = {
      ...Url.GetAllParams(),
    };
    dispatch(await orderTypeService.delete(id, params));
    props.history.push("/setting/Order");
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    {
      label: "Order Types",
      link: "/setting/Order",
    },
    {
      label: details.name,
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
  };


  const getOrderTypeDetail = async () => {
    let id = props.match.params.id;
    const data = await orderTypeService.get(id);
    setDetails(data);
  };

  const _toggle = () => {
    setIsOpen(!isOpen);
  };


  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <PageTitle label={details.name} />
        <div className="d-flex justify-content-end">
          {activeTab == Tabs.STATUS && (
            <AddButton
              label={"Add New"}
              onClick={() => {
                _toggle();
                setRow("");
              }}
            />
          )}
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Order Type"
        label={details?.name}
        deleteFunction={() => {
          deleteOrderType();
        }}
      />
      <Nav tabs className="admin-tabs">
        <Tab
          name={Tabs.GENERAL}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.STATUS}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* General Tab */}
        <TabPane tabId={Tabs.GENERAL}>
          <div className="card">
            <div className="col-12 p-3">
              <div className="field-wrapper mb-0 form-wrapper">
                <OrderTypeForm getDetails={getOrderTypeDetail} form="Edit" details={details} history={props.history} />
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        {/* Status Tab */}
        {activeTab == Tabs.STATUS && (
          <TabPane tabId={Tabs.STATUS}>
            <DragAndDropTable
              history={props.history}
              objectName={ObjectName.ORDER_TYPE}
              showUrl
              _toggle={_toggle}
              isOpen={isOpen}
              row={row}
              setRow={setRow}
              // orderTypeId={props.match.params.id}
              objectId={props.match.params.id}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default OrderTypeDetail;
