import React from "react";
import HourComponent from "../../../components/HourComponent";
import NumberComponent from "../../../components/Number";
import Select from "../../../components/Select";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Text from "../../../components/Text";

import {
    statusOptions
} from "../../../helpers/AttendanceType";
import SaveButton from "../../../components/SaveButton";

const  AttendanceTypeForm=(props)=> {
    let { daysArray, showSaveButton } = props;


  return (
    <div>
      <Text name="name" label="Name" />
      <NumberComponent name="days_count" label="Days Count" required />
      <Select name="status" label="Status" required options={statusOptions} />
      <NumberComponent
        name="maximum_leave_allowed"
        label="Maximum Leave Allowed"
        placeholder="Enter Leave .."
      />
      <HourComponent name="cutoff_time" label="Cut Off Time" range={12} />
      <div className="d-inline-block">
        <div>
          <SingleCheckbox
            name="allow_late_checkin"
            label={"Allow Late Check-In"}
          />
        </div>
        <div>
          <SingleCheckbox
            name="is_additional_day"
            label={"Is Additional Day"}
          />
        </div>
        <div>
          <SingleCheckbox
            name="is_additional_shift"
            label={"Is Additional Shift"}
          />
        </div>
        <div>
          <SingleCheckbox
            name="is_additional_leave"
            label={"Is Additional Leave"}
          />
        </div>
        <div>
          <SingleCheckbox
            name="is_leave"
            label={"Is Leave"}
          />
        </div>
        <div>
          <SingleCheckbox
            name="is_working_day"
            label={"Is Working Day"}
          />
        </div>
        <div>
          <SingleCheckbox
            name="is_absent"
            label={"Is Absent"}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between">
          {daysArray.map((day)=>
                 <div>
                 <SingleCheckbox
                   name={day}
                   label={day}
                 />
               </div>
          )}
        </div>
        {showSaveButton && <SaveButton />}

    </div>
  )
}

export default AttendanceTypeForm;
