import React from "react";
import Select from "./Select";
import { useEffect } from "react";
import { useState } from "react";
import { Jobs } from "../helpers/Job";
import JobService from "../services/JobService";

const JobsList = (props) => {
  let { name, placeholder, handleJobsChange, required, label } = props;

  const [Job, setJobs] = useState([]);

  useEffect(() => {
    getJobDetails();
  }, []);

  let getJobDetails = async () => {
    let response = await JobService.search({
      status: Jobs.STATUS_ACTIVE,
    });
    let data = response && response?.data && response?.data?.data;
    if (response && response?.data && response?.data?.data) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { job_title, id } = data[i];
        list.push({
          label: job_title,
          id: id,
          value: id,
        });
      }

      setJobs(list);
    } else {
      setJobs([]);
    }
  };

  return (
    <div>
      <Select
        name={name ? name : "position"}
        placeholder={placeholder ? placeholder : "Select Job"}
        onInputChange={handleJobsChange}
        label={label ? label : "position"}
        options={Job}
        required={required}
      />
    </div>
  );
};

export default JobsList;
