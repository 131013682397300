import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import CsvDownload from "react-csv-downloader";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import SaveButton from "../../components/SaveButton";

// API
import { endpoints } from "../../api/endPoints";

// Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { IconDownload } from "../../assets/icons";

// Lib
import Currency from "../../lib/Currency";
import Cookies, { setCookie } from "../../lib/Helper";

// Helpers
import Cookie from "../../helpers/Cookie";

// Services
import SalaryService from "../../services/SalaryService";
import Action from "../../components/Action";
import DeleteModal from "../../components/DeleteModal";
import Url from "../../lib/Url";
import { fetchList } from "../../actions/table";
import UserCard from "../../components/UserCard";
import StatusText from "../../components/StatusText";
import BreadCrumb from "../../components/Breadcrumb";
import StatusSelect from "../../components/SelectStatus";
import ObjectName from "../../helpers/ObjectName";
import AddModal from "../../components/Modal";
import Toast from "../../components/Toast";
import { toast } from "react-toastify";
import StatusService from "../../services/StatusService";
import MoreDropdown from "../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";

function salaryList(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const [arrays, setArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [isOpenBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [nextStatusList, setNextStatusList] = useState([]);

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.SALARY_LIST_COLUMNS);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  const FieldLabel = {
    MONTH: "Month",
    YEAR: "YEAR",
    MONTHLY_SALARY: "Monthly Salary",
    WORKING_DAYS: "Working Days",
    WORKED_DAYS: "Worked Days",
    BASIC: "Basic",
    HRA: "HRA",
    STANDARD_ALLOWANCE: "Standard Allowance",
    SPECIAL_ALLOWANCE: "Special Allowance",
    MEDICAL_INSURANCE: "Medical Insurance",
    GRATUITY: "Gratuity",
    WORKED_DAYS_SALARY: "Worked Days Salary",
    ADDITIONAL_DAYS: "Additional Days",
    ADDITIONAL_DAYS_SALARY: "Additional Days Salary",
    ADDITIONAL_HOURS: "Additional Hours",
    ADDITIONAL_HOURS_SALARY: "Additional Hours Salary",
    ABSENT_DAYS: "Absent Days",
    LEAVE_DAYS: "Leave Days",
    LEAVE_DAYS_SALARY: "Leave Days Salary",
    OTHER_DEDUCTIONS: "Other Deductions",
    OTHER_ALLOWANCE: "Other Allowance",
    FINE_AMOUNT: "Fine Amount",
    NOTES: "Notes",
    PROFESSIONAL_TAX: "Professional Tax",
    SALARY_PER_DAY: "Salary Per Day",
    PF: "PF",
    TDS: "Tds",
  };

  const FieldLabels = Object.entries(FieldLabel)
    .map(([key, value]) => ({
      value: value,
      label: value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const enableFlags = {};

  Object.keys(FieldLabel).forEach((key) => {
    const label = FieldLabel[key];
    enableFlags[`${key.toLowerCase()}`] =
      arrays && getKeyByValue(arrays, label) ? true : false;
  });

  const sortOptions = [
    {
      value: "user_name:ASC",
      label: "Name",
    },
    {
      value: "year:DESC",
      label: "Most Recent",
    },
    {
      value: "month:ASC",
      label: "month",
    },
  ];

  const actionsMenuList = [
    {
      value: "Bulk Delete",
      label: "Bulk Delete",
    },
    {
      value: "Update",
      label: "Update",
    },
    {
      value: "Recalculate",
      label: "Recalculate",
    },
    {
      value: "Export",
      label: "Export",
    },
  ];

  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.SALARY_LIST_COLUMNS, arrayList);
    setArray(array);
    setArrayList(array);
  };

  // Bulk update handler for Select
  const handleBulkSelect = (ids) => {
    setSelectedIds({ selectedIds: ids });
  };

  const calculate = async () => {
    dispatch(SalaryService.bulkUpdate(selectedIds,(res)=>{
      if(res){
        dispatch(
          fetchList(
            "salaryList",
            `${endpoints().salaryAPI}/search`,
            1,
            25,
            {
              ...Url.GetAllParams()
            }
          )
        );
      }
    }));
    setSelectedIds([]);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  const salaryBulkDelete = async (selectedIds) => {
    let ids = { selectedId: selectedIds?.selectedIds };

    if (selectedIds && selectedIds?.selectedIds?.length > 0) {
      await SalaryService.bulkDelete(ids, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "salaryList",
              `${endpoints().salaryAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
          setIsDeleteModel(false);
          setSelectedIds([]);
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      });
    }
  };

  const handleActionChange = (e) => {
    if (e == "Bulk Delete") {
      if (selectedIds && selectedIds?.selectedIds?.length > 0) {
        setIsDeleteModel(true);
      }
    }
    if (e == "Update") {
      if (selectedIds && selectedIds?.selectedIds?.length > 0) {
        setOpenBulkUpdateModal(true);
      } else {
        toast.error("Select Atleast One Item");
      }
    }
    if (e === "Export") {
      const csvDownloadElement = document.getElementById("csvDownload");
      if (csvDownloadElement) {
        csvDownloadElement.click();
      }
    }
    if (e === "Recalculate") {
      calculate();
    }
  };

  const getSalaryList = async () => {
    try {
      const salaryDetails = await dispatch(SalaryService.bulkGet(selectedIds));
      setSelectedIds([]);
      setSelectedCheckBox(false);
      setSelectedCheckBox(true);

      return salaryDetails;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/salary" },

    {
      label: "Salary List",
      link: "",
    },
  ];

  const bulkUpdateFooter = (
    <div>
      <SaveButton />
    </div>
  );

  const bulkUpdateBody = (
    <>
      <StatusSelect
        objectName={ObjectName.SALARY}
        name="status"
        label="Status"
      />
    </>
  );
  const toggleBulkUpdateModal = () => {
    setOpenBulkUpdateModal(!isOpenBulkUpdateModal);
  };

  const handleSumbit = async (values) => {
    try {
      let data = new FormData();
      data.append("selectedIds", selectedIds?.selectedIds);
      data.append("status", values && values?.status && values?.status?.value);
      SalaryService.statusUpdate(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "salaryList",
              `${endpoints().salaryAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
          toggleBulkUpdateModal();
          setSelectedIds("");
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      });
    } catch (err) {
      const res = err.response;
      res && Toast.error(res.data.message);
    }
  };

  const onStatusChange = async (value, id) => {
    const data = {};
    data.selectedIds = id;
    data.status = value;
    dispatch(
      await SalaryService.statusUpdate(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "salaryList",
              `${endpoints().salaryAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
        }
      })
    );
  };

  const getNextStatusList = async (currentStatusId, allowed_statuses) => {
    try {
      if (currentStatusId) {
        const data = await StatusService.nextStatusSearch(
          ObjectName.SALARY,
          currentStatusId,
          "",
          allowed_statuses
        );

        if (data && data.length > 0) {
          setNextStatusList(data);
          return data;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Salary"
        id={selectedIds?.id}
        label={"Bulk Delete"}
        deleteFunction={() => {
          salaryBulkDelete(selectedIds);
        }}
      />
      <AddModal
        modalTitle="Update"
        modalBody={bulkUpdateBody}
        modalFooter={bulkUpdateFooter}
        isOpen={isOpenBulkUpdateModal}
        toggle={toggleBulkUpdateModal}
        toggleModalClose={toggleBulkUpdateModal}
        initialValues={{ status: "" }}
        hideDefaultButtons
        onSubmit={handleSumbit}
      />
      <div className="d-flex justify-content-between">
        <BreadCrumb list={breadcrumbList} />

        <div className="d-flex justify-content-around mb-3">
          <div className="me-2 mt-1 d-none">
            <CsvDownload
              filename="salaryList.csv"
              id="csvDownload"
              extension=".csv"
              datas={getSalaryList}
              text={
                <span>
                  <IconDownload />
                  <span>Export</span>
                </span>
              }
              disabled={
                (selectedIds && selectedIds.length === 0) ||
                selectedIds?.selectedIds?.length === 0
              }
            />
          </div>
          <div className="me-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <ReduxTable
        id="salaryList"
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().salaryAPI}/search`}
        bulkSelect
        onBulkSelect={handleBulkSelect}
        paramsToUrl={true}
        history={history}
        sortByOptions={sortOptions}
        params={{
          objectName: ObjectName.SALARY,
          showTotalAmount: true,
        }}
        FieldLabel={FieldLabels}
        handleColumnChange={handleColumnChange}
        arrayList={arrayList}
        showMonthFilter
        showYearFilter
        showStatusFilter
        DropdownWithCheckbox
        icon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
        message="You can start by clicking on Add New"
        selectedCheckBox={selectedCheckBox}
        showBackgroundColor
      >
        <ReduxColumn
          field="salary_number"
          sortBy="salary_number"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          className="text-center"
          renderField={(row) => (
            <Link to={`/salary/detail/${row.id}`} className="link-opacity-75">
              {row.salary_number}
            </Link>
          )}
        >
          Salary#
        </ReduxColumn>
        <ReduxColumn
          field="user"
          sortBy="user_name"
          width="310px"
          minWidth="310px"
          maxWidth="310px"
          className="text-center"
          renderField={(row) => (
            <>
              <Link to={`/user/${row.user_id}`} className="link-opacity-75">
                <UserCard
                  firstName={row.first_name}
                  url={row.image_url}
                  lastName={row.last_name}
                  status={row?.user_status ? ` (${row?.user_status})`:null}
                />
              </Link>
            </>
          )}
        >
          User
        </ReduxColumn>
        {enableFlags.month && (
          <ReduxColumn
            field="month"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="month"
          >
            Month
          </ReduxColumn>
        )}
        {enableFlags.year && (
          <ReduxColumn
            field="year"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="year"
          >
            Year
          </ReduxColumn>
        )}
        {enableFlags.monthly_salary && (
          <ReduxColumn
            field="monthly_salary"
            sortBy="monthly_salary"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.monthly_salary)}</span>
            )}
          >
            Monthly Salary
          </ReduxColumn>
        )}
        {enableFlags.working_days && (
          <ReduxColumn
            field="working_days"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="working_days"
          >
            Working Days
          </ReduxColumn>
        )}
        {enableFlags.worked_days && (
          <ReduxColumn
            field="worked_days"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="worked_days"
          >
            Worked Days
          </ReduxColumn>
        )}
        {enableFlags.basic && (
          <ReduxColumn
            field="basic"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="basic"
            renderField={(row) => <span>{Currency.GetFormatted(row.basic)}</span>}
          >
            Basic
          </ReduxColumn>
        )}

        {enableFlags.hra && (
          <ReduxColumn
            field="hra"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="hra"
            renderField={(row) => <span>{Currency.GetFormatted(row.hra)}</span>}
          >
            HRA
          </ReduxColumn>
        )}
        {enableFlags.standard_allowance && (
          <ReduxColumn
            field="standard_allowance"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="standard_allowance"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.standard_allowance)}</span>
            )}
          >
            Standard Allowance
          </ReduxColumn>
        )}
        {enableFlags.special_allowance && (
          <ReduxColumn
            field="special_allowance"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="special_allowance"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.special_allowance)}</span>
            )}
          >
            Special Allowance
          </ReduxColumn>
        )}
        {enableFlags.medical_insurance && (
          <ReduxColumn
            field="medical_insurance"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="medical_insurance"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.medical_insurance)}</span>
            )}
          >
            Medical Insurance
          </ReduxColumn>
        )}
        {enableFlags.gratuity && (
          <ReduxColumn
            field="gratuity"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="gratuity"
            renderField={(row) => <span>{Currency.GetFormatted(row.gratuity)}</span>}
          >
            Gratuity
          </ReduxColumn>
        )}
        {enableFlags.tds && (
          <ReduxColumn
            field="tds"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="tds"
            renderField={(row) => <span>{Currency.GetFormatted(row.tds)}</span>}
          >
            Tds
          </ReduxColumn>
        )}
        {enableFlags.pf && (
          <ReduxColumn
            field="provident_fund"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="provident_fund"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.provident_fund)}</span>
            )}
          >
            PF
          </ReduxColumn>
        )}

        {enableFlags.worked_days_salary && (
          <ReduxColumn
            field="worked_days_salary"
            sortBy="worked_days_salary"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.worked_days_salary)}</span>
            )}
          >
            Worked Days Salary
          </ReduxColumn>
        )}
        {enableFlags.salary_per_day && (
          <ReduxColumn
            field="salary_per_day"
            sortBy="salary_per_day"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.salary_per_day)}</span>
            )}
          >
            Salary Per Day
          </ReduxColumn>
        )}
        {enableFlags.additional_days && (
          <ReduxColumn
            field="additional_days"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="additional_days"
          >
            Additional Days
          </ReduxColumn>
        )}
        {enableFlags.additional_days_salary && (
          <ReduxColumn
            field="additional_day_allowance"
            sortBy="additional_day_allowance"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.additional_day_allowance)}</span>
            )}
          >
            Additional Day Salary
          </ReduxColumn>
        )}
        {enableFlags.additional_hours && (
          <ReduxColumn
            field="additional_hours"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="additional_hours"
          >
            Additional Hours
          </ReduxColumn>
        )}
        {enableFlags.additional_hours_salary && (
          <ReduxColumn
            field="additionalHourAmount"
            sortBy="additional_hours_salary"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.additionalHourAmount)}</span>
            )}
          >
            Additional Hours Salary
          </ReduxColumn>
        )}

        {enableFlags.absent_days && (
          <ReduxColumn
            field="absent"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="absent"
          >
            Absent Days
          </ReduxColumn>
        )}
        {enableFlags.leave_days && (
          <ReduxColumn
            field="leave"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="leave"
          >
            Leave Days
          </ReduxColumn>
        )}
        {enableFlags.leave_days_salary && (
          <ReduxColumn
            field="leave_salary"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="leave_salary"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.leave_salary)}</span>
            )}
          >
            Leave Days Salary
          </ReduxColumn>
        )}
        {enableFlags.other_allowance && (
          <ReduxColumn
            field="other_allowance"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="other_allowance"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.other_allowance)}</span>
            )}
          >
            Other Allowance
          </ReduxColumn>
        )}
        {enableFlags.other_deductions && (
          <ReduxColumn
            field="other_deductions"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="other_deductions"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.other_deductions)}</span>
            )}
          >
            Other Deductions
          </ReduxColumn>
        )}
        {enableFlags.fine_amount && (
          <ReduxColumn
            field="fine"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="fine"
            renderField={(row) => <span>{Currency.GetFormatted(row.fine)}</span>}
          >
            Fine Amount
          </ReduxColumn>
        )}
        {enableFlags.professional_tax && (
          <ReduxColumn
            field="professional_tax"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="professional_tax"
            renderField={(row) => (
              <span>{Currency.GetFormatted(row.professional_tax)}</span>
            )}
          >
            Professional Tax
          </ReduxColumn>
        )}
        <ReduxColumn
          field="net_salary"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          className="text-center"
          sortBy="net_salary"
          renderField={(row) => <span>{Currency.GetFormatted(row.net_salary)}</span>}
        >
          Net Salary
        </ReduxColumn>

        {enableFlags.notes && (
          <ReduxColumn
            field="notes"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            sortBy="notes"
          >
            Notes
          </ReduxColumn>
        )}

        <ReduxColumn
          field="status"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          className="text-center"
          sortBy="status"
          renderField={(row) => (
            <StatusText backgroundColor={row?.colorCode} status={row?.status} />
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown
                  onClick={() => {
                    setNextStatusList([]);
                    getNextStatusList(row.statusId, row.next_status_id);
                  }}
                >
                  {nextStatusList &&
                    nextStatusList.length > 0 &&
                    nextStatusList.map((data) => {
                      return (
                        <DropdownItem
                          onClick={() => {
                            onStatusChange(data?.value, row?.id);
                          }}
                        >
                          {data?.label}
                        </DropdownItem>
                      );
                    })}
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
}

export default salaryList;
