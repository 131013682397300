import React from "react";
import Select from "./Select";
import { CUSTOM_VALUE, DateOptions } from "../helpers/Date";
import DateSelector from "./Date";
import { useFormikContext } from "formik";
import Number from "../lib/Number";

const DateSelect = (props) => {
  let { name, label, placeholder, required } = props;
  const { values } = useFormikContext();

  return (
    <div>
      <Select
        name={name ? name : "date"}
        label={label}
        placeholder={placeholder ? placeholder : "Select Date"}
        options={DateOptions}
        required={required}
      />
      {Number.isNotNull(values["date"]) &&
        (values["date"].value == CUSTOM_VALUE ||
          values["date"][0]?.value == CUSTOM_VALUE) && (
          <div className="row">
            <div className="col">
              <DateSelector
                name="startDate"
                placeholder="Start Date"
                onChange={props.handleStartDateChange}
                isClearable
              />
            </div>
            <div className="col">
              <DateSelector
                name="endDate"
                placeholder="End Date"
                onChange={props.handleEndDateChange}
                isClearable
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default DateSelect;
