import React, { useEffect, useState } from "react";

// Assets
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// API
import { endpoints } from "../../api/endPoints";

// Components
import AvatarCard from "../../components/AvatarCard";
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

// Lib
import Url from "../../lib/Url";
import DateTime from "../../lib/DateTime";

// Services
import { getUserRole } from "../../services/UserSettingService";

const StockEntryReport = (props) => {
  const [userRole, setUserRole] = useState([]);

  useEffect(() => {
    getUserRoleValue();
  }, []);

  const getUserRoleValue = async () => {
    const roleData = await getUserRole();
    setUserRole(roleData);
  };

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/Report",
    },
    {
      label: "StockEntry Report",
      link: "",
    },
  ];

  const sortByOption = [
    {
      value: "product_count:ASC",
      label: "Product Count",
    },
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "date:DESC",
      label: "Date",
    },
  ];

  return (
    <>
      <BreadCrumb list={breadcrumbList} />

      <div className="row mx-1 justify-content-between mb-2">
        <PageTitle label="StockEntry Report" />
      </div>

      <ReduxTable
        id="stockEntryReport"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().stockEntryReportApi}/search`}
        params={{
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
          location: Url.GetParam("location"),
          startDate: Url.GetParam("startDate"),
          endDate: Url.GetParam("endDate"),
          role: Url.GetParam("role"),
        }}
        paramsToUrl={true}
        history={props.history}
        sortByOptions={sortByOption}
        showDateFilter
        showStoreFilter
        showUserFilter
        roleList={userRole}
        showRoleFilter
        showShiftFilter
        message="You can start by clicking on Add New"
        icon={<FontAwesomeIcon icon={faCartShopping} />}
      >
        <ReduxColumn
          field="date"
          sortBy="date"
          width="130px"
          minWidth="130px"
          maxWidth="130px"
          className="text-center"
          renderField={(row) => <span>{DateTime.getDate(row.date)}</span>}
        >
          Date
        </ReduxColumn>

        <ReduxColumn
          field="user_name"
          sortBy="name"
          width="280px"
          minWidth="280px"
          maxWidth="280px"
          renderField={(row) => (
            <>
              <div className="d-flex text-break">
                <AvatarCard
                  id="avatar"
                  firstName={row.first_name}
                  lastName={row.last_name}
                  url={row.media_url}
                />
              </div>
            </>
          )}
        >
          User
        </ReduxColumn>
        <ReduxColumn
          field="shift_name"
          sortBy="shift"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Shift
        </ReduxColumn>

        <ReduxColumn
          field="location"
          sortBy="location"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
        >
          Location
        </ReduxColumn>

        <ReduxColumn
          field="product_count"
          sortBy="product_count"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Product Count
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default StockEntryReport;
