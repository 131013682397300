



import React, { useEffect, useState } from 'react'
import StoreService from '../../../services/StoreService'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NoRecordsFound from '../../../components/NoRecordsFound';
import { Link } from "react-router-dom";
import AvatarCard from '../../../components/AvatarCard';
import { Status } from '../../../helpers/Product';
import Number from '../../../lib/Number';
import MoreDropdown from '../../../components/authentication/moreDropdown';
import { DropdownItem } from 'react-bootstrap';
import Currency from '../../../lib/Currency';
import LocationFilter from './Filter';
import Url from '../../../lib/Url';
import Pagination from '../../../components/Pagination';
import { Store } from '../../../helpers/Store';

const LocationDragAndDropTable = (props) => {
    let { setRowValue, _toggle, isLoading, setTotalCount, totalCount } = props;
    const [rows, setRows] = useState([]);
    const [values, setValues] = useState({
        search: Url.GetParam("search"),
        status: Url.GetParam("status") || Store.STATUS_ACTIVE,
        page: Url.GetParam("page"),
        pageSize: Url.GetParam("pageSize"),
    });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    useEffect(() => {
        getLocationList(values)
    }, [])

    useEffect(() => {
        getLocationList(values)
    }, [isLoading])


    const getLocationList = async (param) => {
        let params = {
            sort: "sort_order",
            sortDir: "ASC",
            ...param
        }
        await StoreService.search(({ storeData, totalCount, pageSize }) => {
            setPageSize(pageSize)
            setRows(storeData)
            setTotalCount(totalCount)
        }, params)
    }

    const onDragEnd = async (result) => {
        if (!result.destination) return;
        const draggedRow = rows[result.source.index];
        const newRows = [...rows];
        newRows.splice(result.source.index, 1);
        newRows.splice(result.destination.index, 0, draggedRow);
        newRows.forEach((item, index) => {
            item.index = index;
        });
        setRows(newRows);
        await StoreService.updateOrder(newRows);
    };

    const currentPage = page;
    const totalCounts = totalCount;
    const pageSizes = pageSize;
    const startPage = (currentPage - 1) * pageSize + 1;
    const firstPage =
        startPage > totalCount ? totalCount : startPage;
    const endPage = currentPage * pageSize;
    const lastPage =
        endPage > totalCount ? totalCount : endPage;


    return (
        <div>
            <LocationFilter history={props.history} getLocationList={getLocationList} setValues={setValues} values={values} page={page} pageSize={pageSize} setPageSize={setPageSize} />
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="table-responsive">
                    <table className="w-100 table table-hover">
                        <thead>
                            <tr className="table-dark">
                                <th className="mx-3 p-3">Name</th>
                                <th className="mx-3 p-3">Minimum Cash</th>
                                <th className="mx-3 p-3">Cash in Location</th>
                                <th className="mx-3 p-3">Action</th>
                            </tr>
                        </thead>
                        {rows && rows.length > 0 ? (
                            <Droppable droppableId="table-rows">
                                {(provided) => (
                                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                        {rows &&
                                            rows.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={`table-item-${item.id}`}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <tr
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            className={Number.Get(item["minimum_cash_in_store"]) !== Number.Get(item["cash_in_location"]) ? "table-danger" : ""}
                                                        >
                                                            <td className="m-2 p-4 text-start">

                                                                <div className="d-flex align-items-center">
                                                                    <Link
                                                                        to={`/location/${item.id}`}
                                                                        className='link-opacity-75'
                                                                    >
                                                                        <AvatarCard firstName={item?.label} url={item?.image} />
                                                                    </Link>
                                                                    {item.status === Status.INACTIVE && (
                                                                        <h6 className="fw-bold mx-2 text-danger">
                                                                            {`(${item.status})`}
                                                                        </h6>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="m-2 p-4 text-center">
                                                                {Currency.Format(item.minimum_cash_in_store)}
                                                            </td>
                                                            <td className="m-2 p-4 text-center">
                                                                {Currency.Format(item.cash_in_location)}
                                                            </td>
                                                            <td className="m-2 p-4 text-center">
                                                                <MoreDropdown>
                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            setRowValue(item);
                                                                            _toggle();
                                                                        }}
                                                                    >
                                                                        Quick View
                                                                    </DropdownItem>
                                                                </MoreDropdown>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        ) : (
                            <tr>
                                <td className="text-center" colSpan="8">
                                    <NoRecordsFound
                                        showMessage={true}
                                        hideCard={true}
                                        message="No Status Found"
                                        middleHeight={"70vh"}
                                    />
                                </td>
                            </tr>
                        )}
                    </table>
                </div>
            </DragDropContext>
            {totalCounts > 0 && (
                <div className="d-flex justify-content-between">
                    <div>
                        Showing {firstPage} to {lastPage} of {totalCounts} entries
                    </div>

                    <Pagination
                        currentPage={page}
                        totalCount={totalCounts}
                        pageSize={pageSizes}
                        onPageChange={(page) => {
                            setPage(page);
                            getLocationList({ ...values, page })
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default LocationDragAndDropTable;
