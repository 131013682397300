import React from "react"
import Select from "./Select"
import ProjectTicketTypeService from "../services/ProjectTicketTypeService"
import { useEffect } from "react"
import { useState } from "react"
import Url from "../lib/Url"

const TicketType = (props) => {

  let { name, placeholder, handleTicketTypeChange, required, label, projectId, typeList, isMulti,ticketTypeOption, showDefaultValue, defaultValue=null } = props;

  const [ticketType, setTicketType] = useState([])

  useEffect(() => {
    getTicketType()
  }, [projectId])

  let getTicketType = async () => {
    const project_id = projectId ? projectId.toString() : Url.GetParam("projectId")
    let response = await ProjectTicketTypeService.list({ projectId: project_id, defaultValue: defaultValue  });
    if (response) {
      setTicketType(response)
      typeList && typeList(response)
    } else {
      setTicketType([])
    }
  }

  return (
    <div>
      <Select
        name={name ? name : "ticketType"}
        placeholder={placeholder ? placeholder : "Select Ticket Type"}
        onInputChange={handleTicketTypeChange}
        label={(label == "" || label !=="") ? label : "Ticket Type"}
        options={ticketTypeOption?ticketTypeOption:ticketType}
        showDefaultValue={showDefaultValue}
        required={required}
        isMulti={isMulti}
      />
    </div>
  )
}

export default TicketType
