import React, { useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MediaUpload from "../helpers/MediaUpload";
import ObjectName from "../helpers/ObjectName";
import { AtomicBlockUtils, EditorState } from "draft-js";
import { getRandomId } from "../lib/Helper";
import Media from "../helpers/Media";

function DraftEditor(props) {
  const {
    onChange,
    editorState,
    showEditButton,
    onBlur,
    mentionList = [],
    readOnly,
    placeholder,
    hideOutLine,
    height = "200px",
    disabled,
    error,
    errorMessage,
  } = props;

  const [editable, setEditable] = useState(false);
  const [editorHeight, setEditorHeight] = useState(height); 
  const editorRef = useRef(null);

  const uploadImageCallBack = async (file) => {
    let objectId = getRandomId();

    let mediaParams = {
      selectedFile: file && file,
      objectId: objectId,
      ObjectName: ObjectName.COMMENT,
      visiblity: Media.VISIBILITY_PUBLIC,
    };

    return new Promise(async (resolve, reject) => {
      await MediaUpload.uploadFile(mediaParams, (res) => {
        resolve({ data: { link: res?.mediaUrl } });
      });
    });
  };

  const handleDragAndDropFileUpload = async (file) => {

    let mediaParams = {
      selectedFile: file && file[0],
      objectId: objectId,
      ObjectName: ObjectName.COMMENT,
      // values:,
      visiblity: Media.VISIBILITY_PUBLIC,
      // 
    }
    /* ✴---Pasted images and file upload in s3---✴ */
    let objectId = getRandomId();
    await MediaUpload.uploadFile(mediaParams, (res) => {
      /* ✴---Get S3 Media Url---✴ */
      onChange && onChange(insertImage(res?.mediaUrl));
    }
    );
  };

  const insertImage = (imgUrl) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: imgUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const startResize = (e) => {
    const startY = e.clientY;
    const startHeight = editorRef.current.offsetHeight;

    const resize = (e) => {
      const newHeight = startHeight + (e.clientY - startY);
      setEditorHeight(`${newHeight}px`);
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResize);
    };

    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResize);
  };

  return (
    <>
      {props?.label && <h6 className="fw-normal">{props?.label}</h6>}
      <div
        className="editor-container"
        ref={editorRef}
        style={{ minHeight: editorHeight, position: "relative" }} // Apply dynamic height
      >
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName={!hideOutLine ? "wrapperClassName" : ""}
          toolbarHidden={!editable || disabled}
          editorClassName="editorClassName"
          onEditorStateChange={onChange}
          handlePastedFiles={handleDragAndDropFileUpload}
          editorStyle={{
            border: !hideOutLine
              ? "1px solid var(--page-create-ticket-description-border-color)"
              : "",
            minHeight: editorHeight,
            padding: "10px",
            backgroundColor: "var(--page-create-ticket-description-bg-color)",
            borderRadius: "0px 0px 1px 1px",
            overflow:
              editorState && editorState.getCurrentContent().hasText()
                ? "auto"
                : "hidden",
            height: editorHeight,
          }}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              uploadCallback: uploadImageCallBack,
              alt: { present: true, mandatory: false },
              previewImage: true,
              inputAccept: "image/*",
            },
            options: [
              "image",
              "emoji",
              "embedded",
              "colorPicker",
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "link",
              "remove",
              "history",
            ],
          }}
          customStyleMap={{
            HIGHLIGHT: {
              backgroundColor:
                "var(--page-create-ticket-description-text-bg-color)",
            },
          }}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: mentionList,
          }}
          onFocus={() => {
            if (!disabled) {
              setEditable(true);
            }
          }}
          readOnly={readOnly || disabled}
          placeholder={placeholder}
        />
        <div
          style={{
            height: "5px",
            background: "lightgray",
            cursor: "ns-resize",
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
          }}
          onMouseDown={startResize}
        ></div>
      </div>
      {error && (
        <div className="text-danger">
          {errorMessage || "Comment is required."}
        </div>
      )}
      {showEditButton && (
        <div className="section-title inline-edit-section">
          <div
            className={`test-suite-page-edit ${
              editable ? "d-block float-end" : "d-none"
            }`}
          >
            <i
              className="fas fa-check me-2"
              onClick={() => {
                onBlur && onBlur();
                setEditable(false);
              }}
            ></i>
            <i
              className="fas fa-times"
              onClick={() => {
                setEditable(false);
              }}
            ></i>
          </div>
        </div>
      )}
    </>
  );
}

export default DraftEditor;
