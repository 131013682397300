import React, { useState } from 'react'
import PageTitile from '../../components/PageTitle';
import ReduxTable, { ReduxColumn } from '../../components/reduxTable'
import { endpoints } from '../../api/endPoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Order } from '../../helpers/Order';
import DateTime from '../../lib/DateTime';
import Currency from '../../lib/Currency';
import AvatarCard from "../../components/AvatarCard";
import BreadCrumb from '../../components/Breadcrumb';
import Lightbox from 'react-image-lightbox';


const OrderUpiPaymentReportList = (props) => {
    const [photoIndex, setPhotoIndex] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [photos, setPhotos] = useState([])



    const breadcrumbList = [
        { label: "Home", link: "/locationDashboard" },
        {
            label: "Reports",
            link: "/report",
        },
        {
            label: "Order Upi Payment Report",
            link: "",
        },
    ];

    const handleImageModelOpen = (photos, index) => {
        setIsOpen(true);
        setPhotos(photos)
        setPhotoIndex(index)
        document.body.style.overflow = 'hidden';
    }

    const closeLightbox = () => {
        setIsOpen(false);
        document.body.style.overflow = 'auto';
    };

    const renderPaytmImage = (row) => {
        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <img
                    src={row?.paytm_image_urls[0]}
                    alt="img"
                    width={50}
                    height={50}
                    onClick={() => handleImageModelOpen(row?.paytm_image_urls, 0)}
                />

                {row?.paytm_image_urls?.length > 1 && (
                    <span style={{
                        position: 'absolute',
                        top: '-9px',
                        right: '-9px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        padding: '2px 6px',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }}>
                        {row?.paytm_image_urls?.length}
                    </span>
                )}
            </div>
        )
    }

    return (
        <div>
            {isOpen && <Lightbox
                mainSrc={photos[photoIndex]}
                onCloseRequest={closeLightbox}
                nextSrc={photos[(photoIndex + 1) % photos.length]}
                prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % photos.length)
                }
            />}
            <BreadCrumb list={breadcrumbList} />
            <PageTitile label="Order Upi Payment Report" />
            <ReduxTable
                id="orderUpiPaymentReport"
                showHeader
                newTableHeading
                searchPlaceholder="Search"
                apiURL={`${endpoints().OrderUpiPaymentReport}`}
                params={{
                }}
                paramsToUrl={true}
                history={props.history}
                message="You can start by clicking on Add New"
                icon={<FontAwesomeIcon icon={faCartShopping} />}
                sortByDropdown
                showUserFilter
                showDateFilter
                showStoreFilter
                showShiftFilter
            >
                <ReduxColumn
                    field="order_number"
                    sortBy="order_number"
                    className="text-center"
                    renderField={(row) => (
                        <Link to={row?.order_type == Order.TYPE_DELIVERY ? `/deliveryOrder/${row.id}` : `/order/${row.id}`}
                            className='link-opacity-75'
                        >
                            {row.order_number}
                        </Link>
                    )}
                >
                    Order#
                </ReduxColumn>
                <ReduxColumn
                    field="order_date"
                    sortBy="order_date"
                    className="text-center"
                    renderField={(row) => (
                        <span>{DateTime.UTCtoLocalTime(row.order_date)}</span>
                    )}
                >
                    Order Date
                </ReduxColumn>
                <ReduxColumn
                    field="location_name"
                    sortBy="location_name"
                    className="text-center"
                >
                    Location
                </ReduxColumn>
                <ReduxColumn
                    field="sales_executive_name"
                    sortBy="sales_executive_name"
                    className="text-center"
                    renderField={(row) => (
                        <>
                            <AvatarCard
                                firstName={row?.first_name}
                                lastName={row?.last_name}
                                url={row?.media_url}
                            />
                        </>
                    )}
                >
                    SalesExecutive
                </ReduxColumn>
                <ReduxColumn
                    field="shift_name"
                    sortBy="shift_name"
                    className="text-center"
                >
                    Shift
                </ReduxColumn>
                <ReduxColumn
                    field="upi_amount"
                    sortBy="upi_amount"
                    className="text-center"
                    renderField={(row) => (
                        <span>{Currency.Format(row?.upi_amount)}</span>
                    )}
                >
                    Upi Amount
                </ReduxColumn>
                <ReduxColumn
                    field="paytm_image_urls"
                    sortBy="paytm_image_urls"
                    className="d-flex justify-content-center"
                    renderField={(row) => (
                        row && row?.paytm_image_urls.length > 0 && renderPaytmImage(row)
                    )}
                >
                    Upi Amount
                </ReduxColumn>
            </ReduxTable>
        </div>
    )
}

export default OrderUpiPaymentReportList
