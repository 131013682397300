import { fetchList } from "../actions/table";
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { HttpStatus } from "../helpers/HttpStatus";
import { SUCCESS_RESPONSE, isBadRequest } from "../lib/Http";
import Url from "../lib/Url";

class LocationAllocationService{

    static create (data, callback){
        apiClient
        .post(`${endpoints().LocationAllocationAPI}/create`, data)
        .then((response) => {
            let successMessage;
            if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
                return callback(successMessage)
            }
        })
        .catch((error) => {
            if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                    errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
                console.error(errorMessage);
            }
            return error;
        });
    }

    static delete (id,callback){
        apiClient
        .delete(`${endpoints().LocationAllocationAPI}/delete/${id}`)
        .then((response) => {
          let successMessage
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(successMessage)
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    }


    static updateStatus = (id, data, callback) => {
      try {
        return (dispatch) => {
          apiClient
            .put(`${endpoints().LocationAllocationAPI}/status/${id}`, data)
            .then((response) => {
              let successMessage;
              if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
                return callback(successMessage);
              }
            })
            .then(() => {
              dispatch(
                fetchList(
                  "locationAllocation",
                   `${endpoints().LocationAllocationAPI}/search`,
                  1,
                  25,
                  {
                    ...Url.GetAllParams()
                  }
                )
              );
            })
            .catch((error) => {
              if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                  errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(error.response.data.message);
                console.error(errorMessage);
              }
            });
        };
      } catch (err) {
        console.log(err);
      }
    };


    static async get(id, callback) {
      try {
        if (id) {
          apiClient
            .get(`${endpoints().LocationAllocationAPI}/${id}`)
            .then((response) => {
              const data = response && response.data;
              return callback(data);
            })
            .catch((error) => {
              if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                  errorMessage = JSON.parse(errorRequest.response).message;
                }
                console.error(errorMessage);
              }
            });
        }
      } catch (err) {
        console.log(err);
      }
    }


}
export default LocationAllocationService;