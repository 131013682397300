import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { EditorState, convertFromRaw } from "draft-js";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import Toast from "../../../components/Toast";
import DeleteModal from "../../../components/DeleteModal";

// Lib
import DateTime from "../../../lib/DateTime";
import Url from "../../../lib/Url";

// API
import { endpoints } from "../../../api/endPoints";

// Helpers
import Vendor from "../../../helpers/Vendor";
import Account from "../../../helpers/Account";
import ObjectName from "../../../helpers/ObjectName";

// Services
import AccountService from "../../../services/AccountService";

// Actions
import { fetchList } from "../../../actions/table";

const VendorListPage = (props) => {
  let pathName = props?.history?.location?.pathname
  const { id, tab, type, status, sortByOption, history, searchItem, showStatus, onBulkSelect, accountType } = props;
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [rowValue, setRowValue] = useState(null)
  let dispatch = useDispatch()
  let params = {};

  const statusOptions = [
    {
      value: Account.ACTIVE,
      label: Account.STATUS_ACTIVE,
    },
    {
      value: Account.INACTIVE,
      label: Account.STATUS_INACTIVE,
    },
  ];

  let param = {
    status: Url.GetParam("status") ? Url.GetParam("status") : "",
    pagination: true,
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    search: Url.GetParam("search"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    accountType: Url.GetParam("accountType")
  };

  if (pathName == "/customers") {
    params.accountCategory = Account.CATEGORY_CUSTOMER
    param.accountCategory = Account.CATEGORY_CUSTOMER

  }
  if (pathName == "/vendor") {
    params.accountCategory = Account.CATEGORY_VENDOR
    param.accountCategory = Account.CATEGORY_VENDOR

  }

  const deleteFunction = async () => {
    const data = await AccountService.deleteVendor(rowValue?.id);
    Toast.success(data.message);
    if (data) {
      dispatch(
        fetchList(
          "allVendor",
          pathName == "/accounts"
            ? `${endpoints().accountAPI}/search`
            : pathName == "/customers"
              ? `${endpoints().accountAPI}/search`
              : `${endpoints().accountAPI}/vendorSearch`,
          param.page ? param.page : 1,
          param.pageSize ? param.pageSize : 25,
          {
            ...param,
          }
        )
      );
    }
  }

  return (
    <>
      <DeleteModal
        isOpen={isDeleteOpen}
        label={rowValue?.vendorName}
        toggle={() => {
          setIsDeleteOpen(false)
          setRowValue(null)
        }}
        title="Delete Acount"
        deleteFunction={deleteFunction}
      />
      <ReduxTable
        id={id}
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        icon={<FontAwesomeIcon icon={faTruck} />}
        message="You can start by clicking on Add New"
        apiURL={
          pathName === "/accounts"
            ? `${endpoints().accountAPI}/search`
            : pathName === "/customers"
              ? `${endpoints().accountAPI}/search`
              : `${endpoints().accountAPI}/vendorSearch`
        }
        sortByOptions={sortByOption}
        onRowClick
        showStatusFilter
        customStatusOption={statusOptions}
        showAccountTypeFilter={pathName == "/vendor" ? false : true}
        params={{
          tab: tab ? tab : "",
          status: status ? status : Url.GetParam("status"),
          objectName: ObjectName.ACCOUNT,
          ...params,
          accountType: accountType ? accountType : Url.GetParam("accountType")
        }}
        history={history}
        bulkSelect={pathName == "/accounts"}
        onBulkSelect={onBulkSelect}
        selectedCheckBox={props.selectedCheckBox}
        paramsToUrl={true}
      >
        <ReduxColumn
          field="vendorName"
          type="link"
          width="310px"
          minWidth="310px"
          maxWidth="310px"
          isClickable="true"
          sortBy="vendorName"
          renderField={(row) => (
            <Link to={`${pathName}/${row.id}`}>{row.vendorName}</Link>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="type"
          width="310px"
          minWidth="310px"
          maxWidth="310px"
          sortBy="type"
        >
          Type
        </ReduxColumn>
        {showStatus && (<ReduxColumn
          field="status"
          sortBy="status"
          width="120px"
          minWidth="120px"
          maxWidth="120px"
          renderField={(row) => (
            <div
              className={`status-input text-center rounded text-white text-uppercase align-middle  mx-auto ${row.status && row.status === Vendor.STATUS_ACTIVE ? "bg-success "
                : row.status === Vendor.STATUS_INACTIVE
                  ? "bg-dark bg-opacity-50"
                  : ""
                }`}
            >
              <p>{row.status}</p>
            </div>
          )}
        >
          Status
        </ReduxColumn>)}
        {props.pathName == "/vendor" && <ReduxColumn
          minWidth="100px"
          maxWidth="100px"
          sortBy="LastPurchasedAt"
          className="text-center"
          renderField={(row) => (
            <span>{row?.LastPurchasedAt ? DateTime.getDate(row.LastPurchasedAt) : ""}</span>
          )}>
          Last Purchased At
        </ReduxColumn>}
        {props.pathName == "/vendor" && <ReduxColumn
          minWidth="100px"
          maxWidth="100px"
          className="text-center"
          field="lastPurchaseCostPrice"
        >
          Last Purchase Cost Price
        </ReduxColumn>}
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      props.setRows(row);
                      row?.notes && props.setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(row?.notes))));
                      props.onModalClose();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      setRowValue(row)
                      setIsDeleteOpen(true)
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default VendorListPage;
