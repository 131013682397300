import React from "react";

// Components
import DateSelector from "../../../components/Date";
import Select from "../../../components/Select";
import SelectStore from "../../../components/SelectStore";
import HorizontalSpace from "../../../components/HorizontalSpace";
import SaveButton from "../../../components/SaveButton";
import CancelButton from "../../../components/CancelButton";
import Currency from "../../../components/Currency";
import UserSelect from "../../../components/UserSelect";
import AccountSelect from "../../../components/AccountSelect";
import ShiftSelect from "../../../components/ShiftSelect";

// Helpers
import Url from "../../../helpers/Url";

const GeneralTab = (props) => {
  const {
    paymentType,
    selectedDate,
    orderDateFieldOnchange,
    storeFieldOnchange,
    handleShiftChange,
    userList,
    onChangePaymentValue,
    history,
    orderId,
    customerList,
    pathName,
    onChangeSalesCustomer,
    editable,
    showUserDetailsPageLink,
    showCustomerDetailsPageLink,
    customerId,
    shiftId,
    setShiftOption,
    locationId,
    setLocationList,
    showSaveButton,
    onChangeOwner,
    ownerId,
    showCustomerSelection,
    orderTypes,
    onChangeTypeValue,
    deliveryDateFieldOnchange,
    selectedDeliveryDate
  } = props;

  return (
    <div className="card card-body mb-3">
      <div className="row">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pe-1">
              <DateSelector
                name="date"
                maxDate={new Date()}
                label="Date"
                selected={selectedDate}
                onChange={orderDateFieldOnchange}
                position="unset"
                disabled={editable}
              />
            </div>
            <div className="col-sm-6 pe-1">
              <DateSelector
                name="date"
                label="Time"
                showTimeSelectOnly
                selected={selectedDate}
                showTimeSelect={true}
                onChange={orderDateFieldOnchange}
                format="h:mm aa"
                disabled={editable}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <ShiftSelect
            placeholder=" Select shift"
            name="shift"
            label="Shift"
            handleShiftChange={(e) => handleShiftChange(e)}
            isDisabled={editable}
            defaultValue={shiftId}
            shiftOption={setShiftOption}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <SelectStore
            label="Location"
            required
            handleStoreChange={storeFieldOnchange}
            isDisabled={editable}
            defaultValue={locationId}
            StoreList={setLocationList}
          />
        </div>
        <div className="col-sm-6">
          <Select
            name="payment_type"
            placeholder="Select Payment"
            label="Payment Type"
            options={paymentType}
            onInputChange={(e) => onChangePaymentValue(e)}
            isDisabled={editable}
          />
        </div>
      </div>

      <div className="row">
            <div className="col">
              <Currency
                name="cash_amount"
                label="Cash Amount"
                onInputChange={props.handleCashAmount}
                disabled={editable}
              />
            </div>
            <div className="col">
              <Currency
                name="upi_amount"
                label="Upi Amount"
                onInputChange={props.handleUpiAmount}
                disabled={editable}
              />
            </div>
      </div>
      <div className="row">
        <div className={showCustomerSelection ? "col": "col-sm-6"}>
          <UserSelect
            name="owner"
            label="Owner"
            placeholder="Owner"
            required
            isDisabled={editable}
            handleUserChange={(e) => onChangeOwner(e)}
            showUserDetailsPageLink={showUserDetailsPageLink}
            userId={ownerId}
            customUserOption={userList}

          />
        </div>
        <div className={showCustomerSelection ? "col": "d-none"}>
            <AccountSelect
              name="customer_account"
              label="Customer"
              placeholder="Select Customer"
              customOption={customerList}
              isDisabled={editable}
              handleVendorChange={(e) => onChangeSalesCustomer(e)}
              showAccountDetailsPageLink={showCustomerDetailsPageLink}
              accountId={customerId}
            />
          </div>
      </div>
      <div className="row">
        <div className={"col-sm-6"}>
        <Select
            name="type"
            placeholder="Select Type"
            label="Type"
            options={orderTypes}
            onInputChange={(e) => onChangeTypeValue(e)}
            isDisabled={editable}
          />
        </div>
        </div>


        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pe-1">
              <DateSelector
                name="delivery_date"
                maxDate={new Date()}
                label="Delivery Date"
                selected={selectedDeliveryDate}
                onChange={deliveryDateFieldOnchange}
                position="unset"
                disabled={editable}
              />
            </div>
            <div className="col-sm-6 pe-1">
              <DateSelector
                name="delivery_date"
                label="Delivery Time"
                showTimeSelectOnly
                selected={selectedDeliveryDate}
                showTimeSelect={true}
                onChange={deliveryDateFieldOnchange}
                format="h:mm aa"
                disabled={editable}
              />
            </div>
          </div>
        </div>

      {showSaveButton && (
        <HorizontalSpace>
          <SaveButton label="Save" />
          <CancelButton
            onClick={() => {
              if (pathName == "/location/deliveryOrders/details") {
                history.push(Url.DELIVERY_ORDER_LIST);
              } else if (pathName == "/deliveryOrder") {
                history.push(Url.DELIVERY_ORDER_LIST);
              } else {
                history.push(Url.ORDER_LIST);
              }
            }}
          />
        </HorizontalSpace>
      )}
    </div>
  );
};

export default GeneralTab;
