import React, { Fragment, useEffect, useState } from "react";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import { endpoints } from "../../../api/endPoints";
import ProductCard from "../../product/components/productCard";
import Url from "../../../lib/Url";
import Cookies, { setCookie } from "../../../lib/Helper";
import LocationProduct from "../../../helpers/LocationProduct";
import Cookie from "../../../helpers/Cookie";
import { Store } from "../../../helpers/Store";
import { Link } from "react-router-dom";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";
import DeleteModal from "../../../components/DeleteModal";
import { useDispatch } from "react-redux";
import StoreProductService from "../../../services/StoreProductService";
import Quantity from "../../../components/Quantity";
import Button from "../../../components/Button";
import AddModal from "../../../components/Modal";
import { TagTypeName } from "../../../helpers/Tag";

const LocationProductList = (props) => {
  let { history, filterProps = {} } = props;
  const [arrays, setArray] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);

  const [arrayList, setArrayList] = useState([]);
  const [storeData, setStoreData] = useState("");

  const [storeDeleteModal, setStoreDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const closeModel = () => {
    setModelOpen(false);
  };
  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }
  const enable_system_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.SYSTEM_QUANTITY)
      ? true
      : false;
  const enable_return_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.RETURN_QUANTITY)
      ? true
      : false;
  const enable_transfer_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.TRANSFER_QUANTITY)
      ? true
      : false;

  const enable_order_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.ORDER_QUANTITY)
      ? true
      : false;
  const enable_min_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.MIN_QUANTITY)
      ? true
      : false;
  const enable_max_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.MAX_QUANTITY)
      ? true
      : false;

  const enable_min_order_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.MIN_ORDER_QUANTITY)
      ? true
      : false;
  const enable_max_order_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.MAX_ORDER_QUANTITY)
      ? true
      : false;
  const enable_required_quantity =
    arrays && getKeyByValue(arrays, LocationProduct.REQUIRED_QUANTITY)
      ? true
      : false;

  // Use Effect
  useEffect(() => {
    const checkedList = Cookies.get(Cookie.LOCATION_PRODUCT_LIST_COLUMNS);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    status: Store.STATUS_ACTIVE,
    tab: Url.GetParam("tab"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
    brand: Url.GetParam("brand"),
    category: Url.GetParam("category"),
    location: Url.GetParam("location"),
    product: Url.GetParam("product"),
  };
  if (props && props.productId) {
    params.productId = props && props.productId;
  }
  const FieldLabel = [
    {
      value: LocationProduct.TRANSFER_QUANTITY,
      label: LocationProduct.TRANSFER_QUANTITY,
    },
    {
      value: LocationProduct.SYSTEM_QUANTITY,
      label: LocationProduct.SYSTEM_QUANTITY,
    },
    {
      value: LocationProduct.RETURN_QUANTITY,
      label: LocationProduct.RETURN_QUANTITY,
    },
    {
      value: LocationProduct.ORDER_QUANTITY,
      label: LocationProduct.ORDER_QUANTITY,
    },
    {
      value: LocationProduct.MIN_QUANTITY,
      label: LocationProduct.MIN_QUANTITY,
    },
    {
      value: LocationProduct.MAX_QUANTITY,
      label: LocationProduct.MAX_QUANTITY,
    },
    {
      value: LocationProduct.MIN_ORDER_QUANTITY,
      label: LocationProduct.MIN_ORDER_QUANTITY,
    },
    {
      value: LocationProduct.MAX_ORDER_QUANTITY,
      label: LocationProduct.MAX_ORDER_QUANTITY,
    },
    {
      value: LocationProduct.REQUIRED_QUANTITY,
      label: LocationProduct.REQUIRED_QUANTITY,
    },
  ];
  const LocationSortByOption = [
    {
      value: "location_name:ASC",
      label: "Location",
    },
    {
      value: "quantity:ASC",
      label: "Quantity",
    },

    {
      value: "last_order_date:ASC",
      label: "Order Date",
    },
    {
      value: "last_stock_entry_date:ASC",
      label: "Stock Entry Date",
    },
    {
      value: "sort_order:ASC",
      label: "Sort Order",
    },
  ];

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.LOCATION_PRODUCT_LIST_COLUMNS, arrayList);
    setArray(array);
    setArrayList(array);
  };

  // Store Delete
  const storeDelete = (id) => {
    dispatch(
      StoreProductService.deleteReplenishStoreProduct(
        id,
        params,
        "storeProduct"
      )
    );
  };

  const editToggle = () => {
    setIsOpen(!isOpen);
  };

  const editModelBody = (
    <>
      <Quantity name="quantity" label="Quantity" />
      <Quantity name="min_quantity" label="Min Quantity" />
      <Quantity name="max_quantity" label="Max Quantity" />
    </>
  );
  const editModelFooter = (
    <Button type="submit" label="Update" />
  );

  const editInitialValue = {
    quantity: {
      label:
        storeData?.quantity == 0
          ? storeData?.quantity
          : storeData?.quantity
            ? storeData?.quantity
            : "",
      value:
        storeData?.quantity == 0
          ? storeData?.quantity
          : storeData?.quantity
            ? storeData?.quantity
            : "",
    },
    min_quantity: {
      label:
        storeData?.min_quantity == 0
          ? storeData?.min_quantity
          : storeData?.min_quantity
            ? storeData?.min_quantity
            : "",
      value:
        storeData?.min_quantity == 0
          ? storeData?.min_quantity
          : storeData?.min_quantity
            ? storeData?.min_quantity
            : "",
    },
    max_quantity: {
      label:
        storeData?.max_quantity == 0
          ? storeData?.max_quantity
          : storeData?.max_quantity
            ? storeData?.max_quantity
            : "",
      value:
        storeData?.max_quantity == 0
          ? storeData?.max_quantity
          : storeData?.max_quantity
            ? storeData?.max_quantity
            : "",
    },
  };

  const EditStore = async (values) => {

    const data = new FormData();
    data.append("quantity", values && values.quantity && values.quantity.value);
    data.append(
      "min_quantity",
      values && values.min_quantity && values.min_quantity.value
    );
    data.append(
      "max_quantity",
      values && values.max_quantity && values.max_quantity.value
    );
    data.append("product_id", storeData?.productId);
    data.append("store_id", storeData?.store_id);
    dispatch(
      await StoreProductService.update(
        storeData?.store_product_id,
        data,
        params,
        closeModel
      )
    );
  };

  return (
    <Fragment>
      {storeDeleteModal && (
        <DeleteModal
          isOpen={storeDeleteModal}
          toggle={() => {
            setStoreDeleteModal(false);
          }}
          title="Delete Location"
          id={storeData.store_product_id}
          label={storeData.locationName}
          deleteFunction={storeDelete}
        />
      )}
      <AddModal
        isOpen={modelOpen}
        toggle={editToggle}
        toggleModalClose={closeModel}
        modalTitle="Edit Location"
        modalBody={editModelBody}
        modalFooter={editModelFooter}
        initialValues={editInitialValue}
        onSubmit={EditStore}
        hideDefaultButtons
        enableReinitialize
      />
      <ReduxTable
        id="storeProduct"
        name="storeProduct"
        searchPlaceholder="Search"
        newTableHeading
        noRecordFoundHeight={"7vh"}
        DropdownWithCheckbox
        FieldLabel={FieldLabel}
        customCheckBoxId="store_product_id"
        arrayList={arrayList}
        handleColumnChange={handleColumnChange}
        bulkSelect
        tagFilterType={{
          type: TagTypeName.PRODUCT,
        }}
        sortByOptions={LocationSortByOption}
        defaultSortOption={[
          {
            value: "sort_order:ASC",
            label: "Sort Order",
          },
        ]}
        onBulkSelect={props.handleBulkSelect}
        selectedCheckBox={props.selectedCheckBox}
        params={{
          productId: props && props.productId,
          sort: "order_quantity",
          sortDir: "DESC",
          status: Store.STATUS_ACTIVE,
          tab: Url.GetParam("tab"),
        }}
        apiURL={`${endpoints().storeProductAPI}/search`}
        paramsToUrl={true}
        history={history}
        {...filterProps}
      >
        <ReduxColumn
          field="location"
          type="link"
          isClickable="true"
          sortBy="location_name"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
          renderField={(row) => (
            <Link to={`/location/${row.store_id}`}>{row.location}</Link>
          )}
        >
          Location
        </ReduxColumn>
        {!props.productId && (
          <ReduxColumn
            field="productName"
            isClickable="true"
            sortBy="product_name"
            width="310px"
            minWidth="310px"
            maxWidth="310px"
            renderField={(row) => (
              <>
                <ProductCard
                  productImageIcon
                  square
                  productName={row?.productName}
                  url={row.image}
                  brandName={row.brand_name}
                  salePrice={row.sale_price}
                  size={row.size}
                  unit={row.unit}
                  id={row.id}
                  brand_id={row.brand_id}
                  status={row.status}
                />
              </>
            )}
          >
            Product Name
          </ReduxColumn>
        )}
        <ReduxColumn
          field="quantity"
          sortBy="quantity"
          width="170px"
          minWidth="170px"
          maxWidth="170px"
          className="ellipsis text-center"
          renderField={(row) => (
            <div className="text-dark">
              {row.quantity}
              <small>
                <div className="text-secondary">
                  {" "}
                  {row.last_stock_entry_date ? (
                    row.last_stock_entry_date
                  ) : (
                    <span className="text-danger">No Stock Info</span>
                  )}
                </div>
              </small>
            </div>
          )}
        >
          Quantity
        </ReduxColumn>

        {enable_system_quantity && enable_system_quantity == true && (
          <ReduxColumn
            disableOnClick
            className="ellipsis text-center"
            field="system_quantity"
          >
            System Quantity
          </ReduxColumn>
        )}
        {enable_transfer_quantity && enable_transfer_quantity == true && (
          <ReduxColumn
            disableOnClick
            className="ellipsis text-center"
            renderField={(row) => (
              <div className="text-dark">
                {row.transfer_quantity}
                <small>
                  <div className="text-secondary">
                    {row.transfer_quantity > 0 && row.lastTransferDate}
                  </div>
                </small>
              </div>
            )}
          >
            Transfer Quantity
          </ReduxColumn>
        )}
        {enable_order_quantity && enable_order_quantity == true && (
          <ReduxColumn
            field="order_quantity"
            sortBy="order_quantity"
            className="ellipsis text-center"
            renderField={(row) => (
              <div className="text-dark">
                {row.order_quantity}
                <small>
                  <div className="text-secondary">
                    {" "}
                    {row.last_order_date ? (
                      row.last_order_date
                    ) : (
                      <span className="text-danger">No Order Info</span>
                    )}
                  </div>
                </small>
              </div>
            )}
          >
            Order Quantity
          </ReduxColumn>
        )}
        {enable_return_quantity && enable_return_quantity == true && (
          <ReduxColumn
            disableOnClick
            field="return_quantity"
            className="ellipsis text-center"
            renderField={(row) => (
              <div className="text-dark">
                {row.return_quantity}
                <small>
                  <div className="text-secondary">
                    {row.return_quantity > 0 && row.lastReturnedDate}
                  </div>
                </small>
              </div>
            )}
          >
            Return Quantity
          </ReduxColumn>
        )}
        {enable_min_quantity && enable_min_quantity == true && (
          <ReduxColumn
            field="min_quantity"
            sortBy="min_quantity"
            className="text-center"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Min Quantity
          </ReduxColumn>
        )}
        {enable_max_quantity && enable_max_quantity == true && (
          <ReduxColumn
            field="max_quantity"
            sortBy="max_quantity"
            className="text-center"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Max Quantity
          </ReduxColumn>
        )}
        {enable_min_order_quantity && enable_min_order_quantity == true && (
          <ReduxColumn
            field="max_order_quantity"
            disableOnClick
            className="text-center"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Min Order Quantity
          </ReduxColumn>
        )}
        {enable_max_order_quantity && enable_max_order_quantity == true && (
          <ReduxColumn
            field="min_order_quantity"
            disableOnClick
            className="text-center"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Max Order Quantity
          </ReduxColumn>
        )}
        {enable_required_quantity && enable_required_quantity == true && (
          <ReduxColumn
            disableOnClick
            className="ellipsis text-center"
            field="requiredQuantity"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Required Quantity
          </ReduxColumn>
        )}
        <ReduxColumn
          field="transferred_quantity"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Distribution Quantity
        </ReduxColumn>

        <ReduxColumn
          minWidth="180px"
          field="replenishedQuantity"
          sortBy="replenish_quantity"
          className="text-center"
        >
          Replenished Quantity
        </ReduxColumn>
        <ReduxColumn
          field="average_order_quantity"
          className="ellipsis text-center"
          sortBy="average_order_quantity"
        >
          Average Order Quantity
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          field="status"
          isClickable="true"
          renderField={(row) => (
            <div className="action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    setModelOpen(true);
                    setStoreData(row);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setStoreDeleteModal(true);
                    setStoreData(row);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Actions
        </ReduxColumn>
      </ReduxTable>
    </Fragment>
  );
};

export default LocationProductList;
