import {
  faBuildingColumns,
  faChartLine,
  faClipboard,
  faClipboardList,
  faContactCard,
  faMapLocationDot,
  faPersonCircleQuestion,
  faPersonShelter,
  faReceipt,
  faSheetPlastic,
  faTableCellsLarge,
  faTasks,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Permission from "../helpers/Permission";
import Url from "../helpers/Url";
import DateTime from "../lib/DateTime";
import { hasPermission } from "../services/UserRolePermissionService";
import { TODAY_VALUE } from "../helpers/Date";

export function getPeopleNavList(setting, permissionList) {

  const showFine = hasPermission(Permission.FINE_VIEW);
  let showBonus = hasPermission(Permission.BONUS_VIEW);
  let showAttendance = hasPermission(Permission.ATTENDANCE_VIEW)
  let showAttendanceReport = hasPermission(Permission.ATTENDANCE_SUMMARY_REPORT_VIEW)
  let showVisitor = hasPermission(Permission.VISITOR_VIEW)
  let showUser = hasPermission(Permission.USER_VIEW)
  let showSalary = hasPermission(Permission.SALARY_VIEW);
  let showGatePass = hasPermission(Permission.GATE_PASS_VIEW)
  let showLocationAllocation = hasPermission(Permission.LOCATION_ALLOCATION_VIEW)
  let showActivity = hasPermission(Permission.ACTIVITY_VIEW)
  let arrayList = [];

  arrayList = arrayList.concat({
    name: "Dashboard",
    url: "/people/dashboard",
    icon: faTableCellsLarge,
    horizontalLine: true
  });

  if (showAttendance) {
    arrayList = arrayList.concat({
      name: "Attendance",
      url: `/attendance?date=${TODAY_VALUE}`,
      addPageurl: "/attendance",
      detailsPageurl: "/attendance/",
      icon: faClipboardList,
    });
  }

  if (showFine) {
    arrayList = arrayList.concat({
      name: "Fines",
      url: Url.FINE_LIST,
      detailsPageurl: "/fine/",
      addPageurl: "/fine",
      icon: faTasks,
    });
  }

  if (showBonus) {
    arrayList = arrayList.concat({
      name: "Bonus",
      url: Url.BONUS_LIST,
      detailsPageurl: "/bonus/",
      addPageurl: "/bonus",
      icon: faTasks,
    });
  }

  if (showSalary) {
    arrayList = arrayList.concat({
      name: "Salary",
      url: "/salary",
      detailsPageurl: "/salary/detail/",
      editPageurl: "/salary/list",
      addPageurl: "/salary/add",
      icon: faReceipt,
      horizontalLine: true
    });
  }

  if (showLocationAllocation) {
    arrayList = arrayList.concat({
      name: "Location Allocation",
      url: Url.LOCATION_ALLOCATION,
      detailsPageurl: "/locationAllocation/:id",
      editPageurl: "/locationAllocation",
      icon: faMapLocationDot,
      horizontalLine: true
    });
  }

  if (showActivity) {
    arrayList = arrayList.concat({
      name: "Activities",
      url: "/activity",
      detailsPageurl: "/activity/detail",
      icon: faChartLine,
    });
  }

  if (showGatePass) {
    arrayList = arrayList.concat({
      name: "Gate Pass",
      url: Url.GATE_PASS_LIST,
      editPageurl: "/gatePass",
      icon: faPersonShelter
    })
  }

  if (showVisitor) {
    arrayList = arrayList.concat({
      name: "Visitors",
      url: Url.VISITOR_LIST,
      editPageurl: "/visitor",
      icon: faPersonCircleQuestion,
      horizontalLine: true
    })
  };

  arrayList = arrayList.concat({
    name: "Contacts",
    url: "/contacts",
    detailsPageurl: "/contact/",
    icon: faContactCard,
  });

  arrayList = arrayList.concat({
    name: "Devices",
    url: "/devices",
    detailsPageurl: "/device/",
    icon: faUser,
  });

  if (showUser) {
    arrayList = arrayList.concat({
      name: "Users",
      url: "/users",
      detailsPageurl: "/user/",
      icon: faUser,
      horizontalLine: true
    });
  }

  arrayList = arrayList.concat({
    name: "Trainings",
    url: "/training",
    detailsPageurl: "/training/",
    icon: faBuildingColumns,
  });

  arrayList = arrayList.concat({
    name: "Timesheets",
    url: "/timesheet",
    detailsPageurl: "/timesheet/",
    icon: faSheetPlastic,
    horizontalLine: true
  });

  if (showAttendanceReport) {
    arrayList = arrayList.concat({
      name: "Reports",
      url: "/Reports",
      detailsPageurl: "/attendanceSummaryReport",
      attendanceReportLocationWise: "/attendanceReportLocationWise",
      addPageurl: "/attendanceReport",
      editPageurl: "/fineReport",
      icon: faClipboard,
    });
  }

  return arrayList;
}
