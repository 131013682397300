import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Text from "../../../components/Text";
import Form from "../../../components/Form.js";
import SaveButton from "../../../components/SaveButton.js";
import Select from "../../../components/Select.js";
import CancelButton from "../../../components/CancelButton.js";

// Helpers
import { orderTypeGroupOptions } from "../../../helpers/OrderTypeGroup.js";


// Services
import orderTypeService from "../../../services/OrderTypeService.js";

// Lib
import String from "../../../lib/String.js";
import Url from "../../../lib/Url.js";
import Number from "../../../lib/Number.js";

// Actions
import { fetchList } from "../../../actions/table.js";

// API
import { endpoints } from "../../../api/endPoints.js";

const OrderTypeForm = (props) => {
  const { details, closeToggle, getDetails } = props;
  const dispatch = useDispatch();
  const [group, setGroup] = useState("");
  const [orderTypeName, setOrderTypeName] = useState();

  const orderTypeNameChange = (e) => {
    let value = e.target.value;
    setOrderTypeName(value);
  };
  const groupChange = (e) => {
    let value = e.group && e.group.value ? e.group.value : "";
    setGroup(value);
  };

  const updateData = async (values) => {
    try {
      const id = details && details?.id;
      const data = new FormData();

      data.append("name", values && String.Get(values?.name));
      data.append("group", values?.group && values.group.value);

      let params = {
        ...Url.GetAllParams(),
      };
      if (Number.isNotNull(id)) {
        await orderTypeService.update(id, data, (res) => {
          if (res && closeToggle) {
            dispatch(
              fetchList(
                "OrderType",
                `${endpoints().orderTypeAPI}/search`,
                1,
                25,
                params
              )
            );
            closeToggle && closeToggle();
          }
          getDetails && getDetails();
        });
      } else {
        dispatch(await orderTypeService.create(data, params, closeToggle));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // initialValues
  const initialValues = {
    name: orderTypeName ? orderTypeName : details?.name || "",
    group: orderTypeGroupOptions.find((data) => data.value == (group || details?.group)),
  };

  return (
    <>
      {/* Form */}
      <div className="col">
        <Form
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            updateData(values);
          }}
        >
          <div className="row field-wrapper">
            <div className={props.form == "Add" ? "col-lg-12 col-sm-6" : "col-lg-6 col-sm-6"}>
              <Text
                name="name"
                label="Name"
                placeholder="Enter Name"
                onChange={orderTypeNameChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className={props.form == "Add" ? "col-lg-12 col-sm-6" : "col-lg-6 col-sm-6"}>
              <Select
                name="group"
                label="Group"
                options={orderTypeGroupOptions}
                handleOnChangeSubmit={groupChange}
                required
              />
            </div>
          </div>
          <br />
          <div
            className="position-absolute bottom-0 start-0 end-0 bg-white z-3"
            style={{
              borderTop: props.form === "Add" ? "1px solid #dee2e6" : "",
            }}
          >
            <div
              className={
                props.form == "Add" ? "d-flex justify-content-center" : "mx-3"
              }
            >
              <div className="mt-2 mb-1">
                <SaveButton />

                {props.form == "Add" && (
                  <CancelButton
                    onClick={() => {
                      if (props.form == "Add") {
                        closeToggle();
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default OrderTypeForm;
