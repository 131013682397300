import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import Url  from "../../lib/Url";
import Cookies, { setCookie } from "../../lib/Helper";
import Cookie from "../../helpers/Cookie";
import * as ticketcolumns from "../../helpers/Ticket";
import TicketList from "../../components/TicketList";

const DashboardTicket = (props) => {
  const [array, setArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);

  const FieldLabel = [
    {
      value: ticketcolumns?.FieldLabel?.CREATEDAT,
      label: ticketcolumns?.FieldLabel?.CREATEDAT,
    },
    {
      value: ticketcolumns?.FieldLabel?.REPORTER,
      label: ticketcolumns?.FieldLabel?.REPORTER,
    },
    {
      value: ticketcolumns?.FieldLabel?.PROJECT,
      label: ticketcolumns?.FieldLabel?.PROJECT,
    },
    {
      value: ticketcolumns?.FieldLabel?.SPRINT,
      label: ticketcolumns?.FieldLabel?.SPRINT,
    },
  ];

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.TICKET, arrayList);
    setArray(array);
    setArrayList(array);
  };

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.TICKET);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;

    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  return (
    <>
      <PageTitle label="Tickets" />
      <div className="mt-4">
        <TicketList
          history={props.history}
          array={array}
          showDateFilter
          FieldLabel={FieldLabel}
          handleColumnChange={handleColumnChange}
          showSprintFilter
          showStatusFilter
          showUserFilter
          showReviewerFilter
          showReporterFilter
          newTableHeading
          isMultiSelect
          showStatusGroupFilter
          startDateFilter={Url.GetParam("startDate")}
          endDateFilter={Url.GetParam("endDate")}
          projectId={
            Cookies.get(Cookie.PROJECT_ID)
              ? Cookies.get(Cookie.PROJECT_ID)
              : Url.GetParam("projectId") !== undefined
              ? Url.GetParam("projectId")
              : ""
          }
          showProjectFilter
          DropdownWithCheckbox
          showTicketTypeFilter
          showTicketComponentFilter
          hideActionColumn
        />
      </div>
    </>
  );
};

export default DashboardTicket;
