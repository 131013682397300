import React from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import ReduxTable, { ReduxColumn } from "./reduxTable";
import "../views/user/portalUser.scss";
import { useDispatch } from "react-redux";

// API
import { endpoints } from "../api/endPoints";

// Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Components
import MoreDropdown from "./authentication/moreDropdown";
import UserCard from "./UserCard";

// Helpers
import { User } from "../helpers/User";
import * as tabConstant from "../helpers/User";

// Lib
import DateTime from "../lib/DateTime";
import Currency from "../lib/Currency";
import ArrayList from "../lib/ArrayList";

// Services
import UserService from "../services/UserService";

export const Tabs = {
  ACTIVE: "Active",
  ALL: "All",
  INACTIVE: "InActive",
};

const UserList = (props) => {
  const {
    tableId,
    setRowValue,
    rowValue,
    setDetail,
    detail,
    _toggle,
    isQuickButton,
    handleColumnChange,
    FieldLabel,
    arrayList,
    arrays,
    selectedCheckBox,
    bulkSelect,
    showRoleFilter,
    DropdownWithCheckbox,
  } = props;
  const dispatch = useDispatch();

  const enable_primary_location =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.PRIMARY_LOCATION)
      ? true
      : false;
  const enable_primary_shift =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.PRIMARY_SHIFT)
      ? true
      : false;
  const enable_current_location =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.CURRENT_LOCATION)
      ? true
      : false;
  const enable_current_shift =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.CURRENT_SHIFT)
      ? true
      : false;
  const enable_last_loggedin_at =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.LAST_LOGGEDIN_AT)
      ? true
      : false;
  const enable_date_of_joining =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.DATE_OF_JOINING)
      ? true
      : false;
  const enable_date_of_leaving =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.DATE_OF_LEAVING)
      ? true
      : false;
  const enable_salary =
    arrays && ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.SALARY)
      ? true
      : false;
  const enable_leave_balance =
    arrays &&
      ArrayList.getKeyByValue(arrays, tabConstant.FieldLabel.LEAVE_BALANCE)
      ? true
      : false;

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  return (
    <>
      <ReduxTable
        id={props.tableId}
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().userAPI}/search`}
        newTableHeading
        params={props.params}
        icon={<FontAwesomeIcon icon={faUser} />}
        message="You can start by clicking add."
        sortByOptions={sortByOption}
        history={props.history}
        roleList={props?.userRoleList}
        FieldLabel={FieldLabel}
        handleColumnChange={handleColumnChange}
        selectedCheckBox={selectedCheckBox}
        DropdownWithCheckbox={DropdownWithCheckbox === false ? false : true}
        arrayList={arrayList}
        showRoleFilter={showRoleFilter === false ? false : true}
        paramsToUrl={true}
        bulkSelect={bulkSelect === false ? false : true}  
        onBulkSelect={props.handleBulkSelect}
        showStatusParam={false}
      >
        <ReduxColumn
          field="first_name"
          type="link"
          isClickable="true"
          sortBy="name"
          width="350px"
          maxWidth="250px"
          minWidth="250px"
          renderField={(row) => (
            <>
              <Link to={`/user/${row.id}`} className="link-opacity-75">
                <UserCard
                  customSize={parseInt(50, 10)}
                  firstName={row.first_name}
                  url={row.avatarUrl}
                  mobileNumber={row.mobileNumber1}
                  email={row.email}
                  lastName={row.last_name}
                  status={
                    row.status == User.STATUS_INACTIVE_VALUE
                      ? `(${User.STATUS_INACTIVE_TEXT})`
                      : ""
                  }
                />
              </Link>
            </>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="role_name"
          width="110px"
          maxWidth="110px"
          minWidth="110px"
          sortBy="role_name"
        >
          Role
        </ReduxColumn>
        {enable_primary_location && enable_primary_location == true && (
          <ReduxColumn
            field="primary_location"
            width="130px"
            maxWidth="130px"
            minWidth="130px"
            sortBy="primary_location_id"
          >
            Primary Location
          </ReduxColumn>
        )}
        {enable_primary_shift && enable_primary_shift == true && (
          <ReduxColumn
            field="primary_shift"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            sortBy="primary_shift_id"
          >
            Primary Shift
          </ReduxColumn>
        )}
        {enable_current_location && enable_current_location == true && (
          <ReduxColumn
            field="current_location"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            sortBy="current_location_id"
          >
            Current Location
          </ReduxColumn>
        )}
        {enable_current_shift && enable_current_shift == true && (
          <ReduxColumn
            field="current_shift"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            sortBy="current_shift_id"
          >
            Current Shift
          </ReduxColumn>
        )}
        {enable_last_loggedin_at && enable_last_loggedin_at == true && (
          <ReduxColumn
            field="last_loggedin_at"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            sortBy="last_loggedin_at"
            className="text-center"
            renderField={(row) => (
              <span>{row?.last_loggedin_at ? row?.last_loggedin_at : ""}</span>
            )}
          >
            Last Loggedin At
          </ReduxColumn>
        )}
        <ReduxColumn
          field="last_check_in"
          className="text-center"
          width="110px"
          maxWidth="110px"
          minWidth="110px"
          sortBy="last_check_in"
          renderField={(row) => (
            <span>{row?.last_check_in !== "" ? row.last_check_in : ""}</span>
          )}
        >
          Last Check In
        </ReduxColumn>
        {enable_date_of_joining && enable_date_of_joining == true && (
          <ReduxColumn
            field="date_of_joining"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            sortBy="date_of_joining"
            className="text-center"
            renderField={(row) => (
              <span>
                {row?.date_of_joining
                  ? DateTime.getDate(row?.date_of_joining)
                  : ""}
              </span>
            )}
          >
            Date Of Joining
          </ReduxColumn>
        )}
        {enable_date_of_leaving && enable_date_of_leaving == true && (
          <ReduxColumn
            field="date_of_leaving"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            sortBy="date_of_leaving"
            renderField={(row) => (
              <span>
                {row?.date_of_leaving
                  ? DateTime.getDate(row?.date_of_leaving)
                  : ""}
              </span>
            )}
          >
            Date Of Leaving
          </ReduxColumn>
        )}
        {enable_salary && enable_salary == true && (
          <ReduxColumn
            field="salary"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            className="text-center"
            sortBy="salary"
            renderField={(row) => <span>{Currency.Format(row.salary)}</span>}
          >
            Salary
          </ReduxColumn>
        )}
        {enable_leave_balance && enable_leave_balance == true && (
          <ReduxColumn
            field="leave_balance"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            className="text-center"
            sortBy="leave_balance"
            renderField={(row) => (
              <span>{row?.leave_balance ? row?.leave_balance : ""}</span>
            )}
          >
            Leave Balance
          </ReduxColumn>
        )}
        <ReduxColumn
          width="70px"
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                {isQuickButton && (
                  <DropdownItem
                    onClick={() => {
                      setRowValue(row);
                      _toggle();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                )}

                {row.status !== User.STATUS_ACTIVE_VALUE ? (
                  <DropdownItem
                    onClick={async () => {
                      const data = { status: User.STATUS_ACTIVE_TEXT };
                      dispatch(
                        await UserService.statusUpdate(row.id, data, {
                          pagination: true,
                          tableId: tableId,
                          ...props.params,
                        })
                      );
                    }}
                  >
                    Make as Active
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    onClick={async () => {
                      const data = { status: User.STATUS_INACTIVE_TEXT };
                      dispatch(
                        await UserService.statusUpdate(row.id, data, {
                          tableId: tableId,
                          pagination: true,
                          ...props.params,
                        })
                      );
                    }}
                  >
                    Make as InActive
                  </DropdownItem>
                )}
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default UserList;
