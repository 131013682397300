// API Client
import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import Toast from "../components/Toast";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";
import { activityDeleteError } from "../actions/userActivityType";
import { requestDeleteActivity } from "../actions/activity";
import { fetchList } from "../actions/table";
import Url from "../lib/Url";

class SalaryService {
  static async create(data, toggle, setIsSubmit) {
    return async (dispatch) => {
      await apiClient
        .post(`${endpoints().salaryAPI}`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
          toggle(true)
        })
        .then(() => {
          dispatch(
            fetchList(
              "salaryIndex",
              `${endpoints().salaryAPI}`,
              1,
              25,
              Url.GetAllParams()
            )
          );
        }).catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              setIsSubmit(true)
            }
            Toast.error(errorMessage);
          }
        });

    }

  }

  static update(id, data, callBack) {
    try {
      if (id && data) {
        apiClient
          .put(`${endpoints().salaryAPI}/${id}`, data)
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
              return callBack(response?.data)
            }
          })
          .catch((err) => {
            if (isBadRequest(err)) {
              let errorMessage;
              const errorRequest = err.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  static delete(id, callback) {
    if (id) {
      apiClient
        .delete(`${endpoints().salaryAPI}/delete/${id}`)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback && callback(successMessage)
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    }
  }

  static bulkDelete = async (selectedId, callback) => {
    try {
      const response = await apiClient.delete(`${endpoints().salaryAPI}/bulkDelete`, {
        data: selectedId,
      });
      if (response.status === SUCCESS_RESPONSE) {
        Toast.success(response?.data?.message);
        if (callback) {
          return callback(response.data.message);
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(errorMessage);
      }
    }
  };


  static async get(salary_id) {
    try {
      const response = await apiClient.get(
        `${endpoints().salaryAPI}/${salary_id}`
      );

      const data = response.data;

      return data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getfilterData(params,callback) {
    let dataValue = {};
    try {
      await apiClient
        .post(`${endpoints().salaryAPI}/filterRoute`, params)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(response)
        }
        });
      return dataValue;
    } catch (err) {
      console.log(err);
    }
  }

  static statusUpdate(data,callback) {
    try {
      if (data) {
        apiClient
          .put(`${endpoints().salaryAPI}/status`, data)
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
              if(callback){
                return callback(response.data)
              }
            }
          })
          .catch((err) => {
            if (isBadRequest(err)) {
              let errorMessage;
              const errorRequest = err.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  static bulkUpdate(data, callback) {

      apiClient
        .put(`${endpoints().salaryAPI}/bulk/update`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback && callback(successMessage)
          }
        })
        .catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
  }

  static bulkGet(data) {
    return async (dispatch) => {

      try {
        const response = await apiClient.put(`${endpoints().salaryAPI}/bulk/exportList`, data);

        if (response && response.data) {
          return response.data;
        }

      } catch (error) {
        console.log(error);
      }
    };
  }

}

export default SalaryService;
