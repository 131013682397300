import React, { useRef } from 'react';
import Modal from 'react-modal';
import html2canvas from 'html2canvas';
import '../../../scss/_custom.scss';

// Components
import CancelButton from "../../../components/CancelButton";
import AddButton from "../../../components/AddButton";

// Lib
import String from "../../../lib/String";
import ArrayList from "../../../lib/ArrayList";
import DateTime from '../../../lib/DateTime';

const ShareModal = ({ visible, onClose, locationList, filteredShifts, date, isRecord, detail, userList }) => {
    const viewShotRef = useRef(null);
    const handleShare = async () => {
        try {
            const scale = 2;
            const canvas = await html2canvas(viewShotRef.current, { scale });
            const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
            const message = encodeURIComponent("Check out this image:");
            const whatsappUrl = `whatsapp://send?text=${message}&image=${encodeURIComponent(dataUrl)}`;
            window.open(whatsappUrl, '_blank');
        } catch (error) {
            console.error("Error sharing image:", error);
        }
    };



    const renderUserDetail = (location, shift) => {
        let isDetail =
            ArrayList.isArray(detail) &&
            detail.find(
                (data) =>
                    data.location_id === location.id && data.shift_id === shift.id
            );
        let userValue =
            ArrayList.isArray(userList) &&
            userList.find((data) => data?.id === isDetail?.user_id);
        return isRecord(location, shift) ? String.concatName(userValue?.first_name, userValue?.last_name) : "";
    };

    const isLastCell = (currentIndex, list) => currentIndex === list.length - 1;

    return (
        <Modal isOpen={visible} onRequestClose={onClose} contentLabel="Share Modal">
            <div className="share-modal-container">
                <div className="share-button-container">
                    <CancelButton onClick={onClose} />
                    <AddButton label="Share" onClick={handleShare} className="mt-1 ms-2" />
                </div>
                <div className="share-scroll-view" ref={viewShotRef}>
                    <div className="share-modal-content">
                        <div className="share-table">
                            <div className="share-table-header-row">
                                <span className="share-label share-header-cell" style={{ fontSize: 22, backgroundColor: "yellow", borderBottomWidth: 1 }}>
                                    {`Store Allocation - ${DateTime.getDate(date)}`}
                                </span>
                            </div>
                            <div className="share-table-header">
                                <span className="share-label share-header-cell" style={{ fontSize: 18, backgroundColor: "yellow" }}>Store</span>
                                {ArrayList.isArray(filteredShifts) && filteredShifts.map((shift, index) => (
                                    <span key={index} className="share-label share-header-cell" style={{ fontSize: 18, backgroundColor: "yellow", borderRightWidth: isLastCell(index, filteredShifts) ? 0.2 : 1, borderRightColor: "black" }}>
                                        {shift?.name}
                                    </span>
                                ))}
                            </div>
                            {ArrayList.isArray(locationList) && locationList.map((location, locationIndex) => (
                                <div key={locationIndex} className="share-table-row" style={{ borderBottomWidth: isLastCell(locationIndex, locationList) ? 0 : 1, borderRightColor: "black", lineHeight: 2.8 }}>
                                    <span className="share-label share-body-cell" style={{ fontSize: 15, fontWeight: "bold", textAlign: "left", padding: 2, backgroundColor: "#f4f4f6" }}>
                                        {location.label}
                                    </span>
                                    {ArrayList.isArray(filteredShifts) && filteredShifts.map((shift, shiftIndex) => (
                                        <span key={shiftIndex} className="share-label share-body-cell" style={{ fontSize: 15, fontWeight: "bold", padding: 2, borderRightWidth: isLastCell(shiftIndex, filteredShifts) ? 0 : 1, borderRightColor: "black" }}>
                                            {renderUserDetail(location, shift)}
                                        </span>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </Modal>
    );
};

export default ShareModal;
