import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import classNames from "classnames";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import { endpoints } from "../../api/endPoints";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import Text from "../../components/Text";
import Select from "../../components/Select";
import NumberComponent from "../../components/Number";
import SaveButton from "../../components/SaveButton";
import DetailPage from "./detailPage";
import MoreDropdown from "../../components/authentication/moreDropdown";
import DeleteModal from "../../components/DeleteModal";
import SettingTab from "./components/SettingTab";
import Link from "../../components/Link";

// Services
import AttendanceTypeService from "../../services/AttendanceTypeService";

// Helpers
import {
  Status,
  statusOptions,
  typeOptions,
} from "../../helpers/AttendanceType";
import { Tabs } from "../../helpers/Setting";

// Actions
import { fetchList } from "../../actions/table";
import Url from "../../lib/Url";

import HourComponent from "../../components/HourComponent";
import DateTime from "../../lib/DateTime";
import Number from "../../lib/Number";
import SingleCheckbox from "../../components/SingleCheckbox";

const AttendanceType = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") : Tabs.TYPES);
  const dispatch = useDispatch();
  const selectedId = props.match && props.match.params && props.match.params.id;
  let hourOption = DateTime.getHours(12);

  const Toggle = () => {
    setIsOpen(!isOpen);
  };

  const ToggleClose = () => {
    setIsOpen(!isOpen);
    setRowValue(null);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    status: Url.GetParam("status"),
    search: Url.GetParam("search"),
  };

  const handleAttendanceType = async (values) => {
    let data = new FormData();

    data.append("name", values && values?.name ? values?.name : "");
    data.append("type", values && values?.type ? values?.type?.value : "");
    data.append(
      "status",
      values && values?.status ? values?.status?.value : ""
    );
    data.append(
      "days_count",
      values && values?.days_count ? values?.days_count : ""
    );
    data.append(
      "maximum_leave_allowed",
      values && values?.maximum_leave_allowed
        ? values?.maximum_leave_allowed
        : ""
    );
    data.append(
      "type_name",
      values && values?.type_name ? values?.type_name?.label : ""
    );
    data.append(
      "cutoff_time",
      values && values?.cutoff_time ? values?.cutoff_time?.value : ""
    );

    data.append(
      "allow_late_checkin",
      values?.allow_late_checkin ? values?.allow_late_checkin : false
    );

    if (rowValue) {
      dispatch(
        await AttendanceTypeService.update(rowValue.id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "attendanceType",
                `${endpoints().attendanceTypeAPI}/search`,
                params.page ? params.page : 1,
                params.pageSize ? params.pageSize : 25,
                params
              )
            );
            ToggleClose();
          }
        })
      );
    } else {
      dispatch(
        await AttendanceTypeService.create(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "attendanceType",
                `${endpoints().attendanceTypeAPI}/search`,
                params.page ? params.page : 1,
                params.pageSize ? params.pageSize : 25,
                params
              )
            );
            ToggleClose();
          }
        })
      );
    }
  };

  const FormBody = (
    <>
      <Text name="name" label="Name" />
      <Select name="type" label="Category" options={typeOptions} required />
      <NumberComponent name="days_count" label="Days Count" required />
      <Select name="status" label="Status" required options={statusOptions} />
      <NumberComponent
        name="maximum_leave_allowed"
        label="Maximum Leave Allowed"
        placeholder="Enter Leave .."
      />
      <HourComponent name="cutoff_time" label="Cut Off Time" range={12} />
      <div className="d-inline-block">
        <div>
          <SingleCheckbox
            name="allow_late_checkin"
            label={"Allow Late Check-In"}
          />
        </div>
      </div>
    </>
  );
  const FormFooter = (
    <>
      {" "}
      <SaveButton type="submit" label={rowValue ? "Update" : "Add"} />
    </>
  );

  const deleteToggle = () => {
    setDeleteIsOpen(!deleteIsOpen);
    setRowValue(null);
  };

  const deleteFunction = async () => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      status: Url.GetParam("status"),
      search: Url.GetParam("search"),
    };
    dispatch(
      await AttendanceTypeService.delete(rowValue.id, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "attendanceType",
              `${endpoints().attendanceTypeAPI}/search`,
              1,
              25,
              {
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                page: Url.GetParam("page"),
                pageSize: Url.GetParam("pageSize"),
                status: Url.GetParam("status"),
                search: Url.GetParam("search"),
              }
            )
          );
          deleteToggle();
        }
      })
    );
  };
  let initialValue = {
    name: rowValue ? rowValue?.name : "",
    type: rowValue ? rowValue?.type : "",
    days_count: rowValue ? rowValue?.days_count : "",
    status: rowValue ? rowValue?.status : "",
    cutoff_time:
      hourOption &&
      hourOption.find(
        (data) => data.value === Number.Get(rowValue?.cutoff_time) || ""
      ),
    maximum_leave_allowed: rowValue ? rowValue?.maximum_leave_allowed : "",
    allow_late_checkin: rowValue?.allow_late_checkin
      ? rowValue?.allow_late_checkin
      : "",
  };
  return (
    <>
      <DeleteModal
        isOpen={deleteIsOpen}
        toggle={deleteToggle}
        title="Delete"
        label={rowValue?.id}
        deleteFunction={deleteFunction}
      />
      <Drawer
        DrawerBody={FormBody}
        DrawerFooter={FormFooter}
        modelTitle={rowValue ? "Edit Type" : "Add Type"}
        onSubmit={(values) => {
          handleAttendanceType(values);
        }}
        initialValues={initialValue}
        handleOpenModal={Toggle}
        handleCloseModal={ToggleClose}
        handleDrawerClose={ToggleClose}
        isModalOpen={isOpen}
        enableReinitialize
      />

      {!selectedId ? (
        <>
          <PageTitle
            label="Attendance"
            buttonHandler={() => {
              Toggle();
            }}
            buttonLabel="Add Type"
          />
          <Nav tabs className="admin-tabs mb-3">
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tabs.TYPES,
                })}
                onClick={() => {
                  handleTabChange(Tabs.TYPES);
                }}
              >
                {Tabs.TYPES}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tabs.SETTING,
                })}
                onClick={() => {
                  handleTabChange(Tabs.SETTING);
                }}
              >
                {Tabs.SETTING}
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === Tabs.TYPES && (
            <TabPane>
              <ReduxTable
                id="attendanceType"
                showHeader
                searchPlaceholder="Search"
                paramsToUrl={true}
                history={props.history}
                apiURL={`${endpoints().attendanceTypeAPI}/search`}
                newTableHeading
                sortByOptions={sortByOption}
              >
                <ReduxColumn
                  field="name"
                  sortBy="name"
                  renderField={(row) => (
                    <Link
                      text={row.name}
                      className="link-opacity-75 text-decoration-none"
                      onClick={() => {
                        setRowValue(row);
                        Toggle();
                      }}
                    />
                  )}
                >
                  Name
                </ReduxColumn>

                <ReduxColumn
                  field="type"
                  sortBy="type"
                  className="text-center"
                  renderField={(row) => <span>{row?.type?.label}</span>}
                >
                  Type
                </ReduxColumn>
                <ReduxColumn
                  field="days_count"
                  sortBy="days_count"
                  className="text-center"
                >
                  Days Count
                </ReduxColumn>
                <ReduxColumn
                  field="status"
                  sortBy="status"
                  width={"120px"}
                  minWidth="120px"
                  maxWidth="120px"
                  className="column-status"
                  renderField={(row) => (
                    <div
                      className={`status-input text-center rounded text-white text-uppercase ${
                        row.status?.value &&
                        row.status?.value === Status.STATUS_ACTIVE
                          ? "bg-success"
                          : row.status?.value === Status.STATUS_INACTIVE
                          ? "bg-dark bg-opacity-50"
                          : ""
                      }`}
                    >
                      <p>{row.status?.label}</p>
                    </div>
                  )}
                >
                  Status
                </ReduxColumn>
                <ReduxColumn
                  field="Action"
                  disableOnClick
                  width="70px"
                  renderField={(row) => (
                    <>
                      <div className="text-center action-group-dropdown">
                        <MoreDropdown>
                          <DropdownItem
                            onClick={() => {
                              setRowValue(row);
                              Toggle();
                            }}
                          >
                            Quick View
                          </DropdownItem>
                          <DropdownItem
                            className="text-danger"
                            onClick={() => {
                              setRowValue(row);
                              setDeleteIsOpen(true);
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </MoreDropdown>
                      </div>
                    </>
                  )}
                >
                  Action
                </ReduxColumn>
              </ReduxTable>
            </TabPane>
          )}
          {activeTab == Tabs.SETTING && <SettingTab />}
        </>
      ) : (
        <DetailPage {...props} />
      )}
    </>
  );
};

export default AttendanceType;
