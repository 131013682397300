import React from "react";
import DateSelector from "../../../components/Date";
import Select from "../../../components/Select";
import SelectStore from "../../../components/SelectStore";
import HorizontalSpace from "../../../components/HorizontalSpace";
import SaveButton from "../../../components/SaveButton";
import CancelButton from "../../../components/CancelButton";
import Url from "../../../helpers/Url";
import Currency from "../../../components/Currency";
import UserSelect from "../../../components/UserSelect";
import AccountSelect from "../../../components/AccountSelect";
import ShiftSelect from "../../../components/ShiftSelect";

const GeneralTab = (props) => {
  const {
    paymentType,
    selectedDate,
    orderDateFieldOnchange,
    storeFieldOnchange,
    handleShiftChange,
    salesExecutiveOption,
    onChangeSalesExecutive,
    onChangePaymentValue,
    history,
    orderId,
    customerList,
    pathName,
    onChangeSalesCustomer,
    editable,
    onChangeAssignee,
    showUserDetailsPageLink,
    showCustomerDetailsPageLink,
    customerId,
    userId,
    shiftId,
    setShiftOption,
    locationId,
    setLocationList,
    showSaveButton
  } = props;

  return (
    <div className="card card-body mb-3">
      <div className="row">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pe-1">
              <DateSelector
                name="date"
                maxDate={new Date()}
                label="Date"
                selected={selectedDate}
                onChange={orderDateFieldOnchange}
                position="unset"
                disabled={editable}
              />
            </div>
            <div className="col-sm-6 pe-1">
              <DateSelector
                name="date"
                label="Time"
                showTimeSelectOnly
                selected={selectedDate}
                showTimeSelect={true}
                onChange={orderDateFieldOnchange}
                format="h:mm aa"
                disabled={editable}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <ShiftSelect
            placeholder=" Select shift"
            name="shift"
            label="Shift"
            handleShiftChange={(e) => handleShiftChange(e)}
            required
            isDisabled={editable}
            defaultValue={shiftId}
            shiftOption={setShiftOption}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <SelectStore
            label="Location"
            required
            handleStoreChange={storeFieldOnchange}
            isDisabled={editable}
            defaultValue={locationId}
            StoreList={setLocationList}
          />
        </div>
        <div className="col-sm-6">
          <Select
            name="payment_type"
            placeholder="Select Payment"
            label="Payment Type"
            options={paymentType}
            onInputChange={(e) => onChangePaymentValue(e)}
            required
            isDisabled={editable}
          />
        </div>
      </div>

      <div className="row">
        {pathName == "/deliveryOrder" ? (
          <div className="col-sm-6">
            <UserSelect
              name="assignee"
              label="Assignee"
              placeholder="Assignee"
              required
              isDisabled={editable}
              handleUserChange={onChangeAssignee}
              showUserDetailsPageLink={showUserDetailsPageLink}
              userId={userId}
            />
          </div>
        ) : (
          <div className="col-sm-6">
            <UserSelect
              name="sales_executive_user_id"
              label="Sales Executive"
              placeholder="Sales Executive"
              required
              isDisabled={editable}
              customUserOption={salesExecutiveOption}
              handleUserChange={(e) => onChangeSalesExecutive(e)}
              showUserDetailsPageLink={showUserDetailsPageLink}
              userId={userId}
            />
          </div>
        )}

        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6">
              <Currency
                name="cash_amount"
                label="Cash Amount"
                onInputChange={props.handleCashAmount}
                disabled={editable}
              />
            </div>
            <div className="col-sm-6">
              <Currency
                name="upi_amount"
                label="Upi Amount"
                onInputChange={props.handleUpiAmount}
                disabled={editable}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <AccountSelect
            name="customer_account"
            label="Customer"
            placeholder="Select Customer"
            customOption={customerList}
            isDisabled={editable}
            handleVendorChange={(e) => onChangeSalesCustomer(e)}
            showAccountDetailsPageLink={showCustomerDetailsPageLink}
            accountId={customerId}
          />
        </div>
      </div>

      {showSaveButton && (
        <HorizontalSpace>
          <SaveButton label="Save" />
          <CancelButton
            onClick={() => {
              if (pathName == "/location/deliveryOrders/details") {
                history.push(Url.DELIVERY_ORDER_LIST);
              } else if (pathName == "/deliveryOrder") {
                history.push(Url.DELIVERY_ORDER_LIST);
              } else {
                history.push("/orders");
              }
            }}
          />
        </HorizontalSpace>
      )}
    </div>
  );
};

export default GeneralTab;
