import React from 'react';
import { Link } from "react-router-dom";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endpoints } from "../../../api/endPoints";
import AvatarCard from "../../../components/AvatarCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";

const LeaveList = (props) => {
  const { params } = props

  
  return (
    <>
      <ReduxTable
        id={"leaveList"}
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().LocationAllocationUserAPI}/leaveList`}
        newTableHeading
        icon={<FontAwesomeIcon icon={faStar} />}
        message="You can start by clicking add."
        sortByDropdown
        params={params}
        paramsToUrl={true}
        history={props.history}
      >
      <ReduxColumn
          field="userName"
          width="110px"
          sortBy="userName"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <div className="d-flex text-break">
                  <AvatarCard
                    id="avatar"
                    firstName={row.first_name}
                    lastName={row.last_name}
                    url={row.media_url}
                  />
            </div>
          )}
        >
          Leave User List
        </ReduxColumn>
      </ReduxTable>
    </>
  )
}

export default LeaveList