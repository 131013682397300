import React, { useEffect, useState } from 'react'
import Form from '../../components/Form';
import Text from '../../components/Text';
import Email from '../../components/Email';
import Phone from '../../components/Phone';
import SaveButton from '../../components/SaveButton';
import PageTitle from '../../components/PageTitle';
import ContactService from '../../services/ContactService';
import { useDispatch } from 'react-redux';
import DeleteModal from '../../components/DeleteModal';
import BreadCrumb from '../../components/Breadcrumb';

const contactDetailPage = (props) => {
    let { history } = props;
    const [detail, setDetail] = useState({});
    const [isContactDeleteModel, setDeleteContactModal] = useState(false);

    let dispatch = useDispatch();
    useEffect(() => {
        getContactDetail()
    }, []);

    let getContactDetail = async () => {
        let { data } = await ContactService.get(props?.match?.params?.id);
        setDetail(data)
    }


    const initialValue = {
        first_name: detail?.first_name ? detail?.first_name : "",
        last_name: detail?.last_name ? detail?.last_name : "",
        email: detail?.email ? detail?.email : "",
        mobile: detail?.mobile ? detail?.mobile : "",
        work_phone: detail?.work_phone ? detail?.work_phone : "",
        designation: detail?.designation ? detail?.designation : "",
    };



    const handleContact = async (values) => {
        const updateData = new FormData();
        updateData.append("first_name", values.first_name ? values.first_name : "");
        updateData.append("last_name", values.last_name ? values.last_name : "");
        updateData.append("email", values.email ? values.email : "");
        updateData.append("mobile", values.mobile ? values.mobile : "");
        updateData.append(
            "designation",
            values.designation ? values.designation : ""
        );
        updateData.append("work_phone", values.work_phone ? values.work_phone : "");

        updateData.append("id", props?.match?.params?.id);

        dispatch(
            await ContactService.update(props?.match?.params?.id, updateData, (res) => {
                getContactDetail()
            })
        );
    }

    const closeDeleteModal = () => {
        setDeleteContactModal(false);
    };

    const handleContactDelete = async () => {
        dispatch(
            await ContactService.del(props?.match?.params?.id, () => {
                history.goBack()
            })
        );
    };

    const breadcrumbList = [
        { label: "Home", link: "people/dashboard" },
        { label: "Contacts", link: "/contacts" },
        { label: "Contact Detail", link: "" },
      ];

    return (
        <div>
            <BreadCrumb list={breadcrumbList}/>
            <DeleteModal
                isOpen={isContactDeleteModel}
                title={props?.match?.params?.id}
                label={props?.match?.params?.id}
                toggle={closeDeleteModal}
                deleteFunction={handleContactDelete}
            />
            <PageTitle label="Contact Detail Page" deletebuttonHandler={() => { setDeleteContactModal(true) }} DeleteButtonLabel="Delete" />
            <div className='card card-body'>
                <Form
                    initialValues={initialValue}
                    enableReinitialize
                    onSubmit={handleContact}
                >
                    <Text
                        label="First Name"
                        name="first_name"
                        className="fw-bold-text"
                        placeholder="Enter First Name"
                        required
                        error=""
                    />
                    <Text
                        label="Last Name"
                        name="last_name"
                        className="fw-bold-text"
                        placeholder="Enter Last Name"
                        error=""
                    />
                    <Email
                        label="Email Address"
                        name="email"
                        placeholder="Enter Email Address"
                        notify="error"
                    />
                    <Phone
                        label="Mobile Number"
                        name="mobile"
                        placeholder="Enter Mobile Number"
                        error=""
                    />
                    <Phone
                        label="Work Phone"
                        name="work_phone"
                        placeholder="Enter Work Number"
                        error=""
                    />
                    <Text
                        label="Designation"
                        name="designation"
                        placeholder="Enter Designation"
                    />
                    <SaveButton />
                </Form>

            </div>
        </div>
    )
}

export default contactDetailPage;
