import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import AttendanceTypeService from "../../services/AttendanceTypeService";
import { statusOptions } from "../../helpers/AttendanceType";
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";
import Numbers from "../../lib/Number";
import AttendanceTypeForm from "./components/AttendanceTypeForm";

const DetailPage = (props) => {
  const [detail, setDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  let hourOption = DateTime.getHours(12);
  let daysArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

  useEffect(() => {
    getDetails();
  }, []);

  const Toggle = () => {
    setIsOpen(!isOpen)
  }

  const getDetails = async () => {
    let data = await AttendanceTypeService.get(props.match.params.id);
    setDetail(data);
  };

  const checkBoxInitialValues = (values) => {
    let obj = {}
    let checkBoxs = ["allow_late_checkin", "is_additional_day", "is_additional_shift", "is_additional_leave", "is_leave", "is_working_day", "is_absent"];
    for (let i = 0; i < checkBoxs.length; i++) {
      const key = checkBoxs[i];
      if (Numbers.isNotNull(values) && Numbers.isNotNull(values[key])) {
        obj[key] = values[key];
      } else {
        obj[key] = ""
      }
    }
    return obj
  }

  const daysCheckBoxInitialValues = (values) => {
    let obj = {}
    let splitKey = values?.allowed_days?.split(",") || []
    for (let i = 0; i < daysArray.length; i++) {
      const key = daysArray[i];
      if (Numbers.isNotNull(values) && Numbers.isNotNull(values?.allowed_days) && splitKey.includes(key)) {
        obj[key] = true;
      } else {
        obj[key] = false
      }
    }
    return obj
  }


  let initialValue = {
    name: detail ? detail?.name : "",
    days_count: detail ? detail?.days_count : "",
    status: statusOptions.find((data) => data?.value == detail?.status),
    cutoff_time:
      hourOption &&
      hourOption.find(
        (data) => data.value === Numbers.Get(detail?.cutoff_time) || ""
      ),
    maximum_leave_allowed: detail ? detail?.maximum_leave_allowed : "",
    ...checkBoxInitialValues(detail),
    ...daysCheckBoxInitialValues(detail),
  };


  const handleSubmit = async (values) => {
    let data = new FormData();
    data.append("name", values && values?.name ? values?.name : "");
    data.append(
      "status",
      values && values?.status ? values?.status?.value : ""
    );
    data.append(
      "days_count",
      values && values?.days_count ? values?.days_count : ""
    );
    data.append(
      "maximum_leave_allowed",
      values && values?.maximum_leave_allowed
        ? values?.maximum_leave_allowed
        : ""
    );
    data.append(
      "cutoff_time",
      values && values?.cutoff_time ? values?.cutoff_time?.value : ""
    );
    let checkBoxs = ["allow_late_checkin", "is_additional_day", "is_additional_shift", "is_additional_leave", "is_leave", "is_working_day", "is_absent"];

    for (let i = 0; i < checkBoxs.length; i++) {
      const key = checkBoxs[i];
      data.append(key, values[key] ? values[key] : false);
    }

    let days = [];
    let keys = Object.keys(values);
    daysArray.forEach((key) => {
      if (keys?.includes(key) && values[key]) {
        days.push(key);
      }
    });

    data.append("days", ArrayList.isArray(days) ? days.join(",") : "");
    dispatch(await AttendanceTypeService.update(props.match.params.id, data, () => { }));
  }

  const deleteFunction = async () => {
    dispatch(await AttendanceTypeService.delete(props.match.params.id, (res) => {
      if (res) {
        Toggle()
        props.history.push("/setting/Attendance")
      }
    }));
  }

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Attendance Types", link: "/setting/Attendance" },
    { label: "Attendance Types Detail", link: "" },
  ];



  return (
    <>
      <DeleteModal
        isOpen={isOpen}
        toggle={Toggle}
        title="Delete AttendanceType"
        label={detail?.name}
        deleteFunction={deleteFunction}
      />
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="Detail Page" deletebuttonHandler={() => {
        Toggle()
      }}
        DeleteButtonLabel="Delete" />
      <div className="card card-body">
        <Form
          initialValues={initialValue}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          <AttendanceTypeForm
            daysArray={daysArray}
            showSaveButton
          />
        </Form>
      </div>
    </>
  );
};

export default DetailPage;
