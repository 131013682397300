import React, { useEffect, useState } from "react";
import CancelButton from "../../../components/CancelButton";
import Form from "../../../components/Form";
import PageTitle from "../../../components/PageTitle";
import SaveButton from "../../../components/SaveButton";
import Text from "../../../components/Text";
import DefaultContent from "../../../components/content/defaultContent";
import { Setting } from "../../../helpers/Setting";
import { getKeyValueByObject } from "../../../lib/Helper";
import {getSetings, saveSetting } from "../../../services/SettingService";
import ObjectName from "../../../helpers/ObjectName";
import SingleCheckbox from "../../../components/SingleCheckbox";

const Settings = (props) => {
    const [settings, setSettings] = useState({});

  

    useEffect(() => {
        getSettings();
    }, []);
    const getSettings = async () => {
        //get company id from cookie
        let params = {
          object_id: props.app_id,
          object_name: ObjectName.APP,
        };
        //get company setting
        const settingData = await getSetings(params);
    
        //set setting in state
        setSettings(settingData);
      };
    const initialValues = {
        message_background_fetch_interval:

            getKeyValueByObject(settings, Setting.MESSAGE_BACKGROUND_FETCH_INTERVAL) || "",

            unmute_phone_sound : getKeyValueByObject(settings, Setting.UNMUTE_PHONE_SOUND) == 1  ? true : false || "",
    };

    const submit = async (values) => {
        const data = new FormData();
        data.append(Setting.MESSAGE_BACKGROUND_FETCH_INTERVAL, values?.message_background_fetch_interval);
        data.append("objectId",props.app_id)
        data.append("objectName",ObjectName.APP)
        saveSetting(data, null, () => {
            getSettings();
          });
    };

    const handleCheckBoxValue = (values) => {
        const data = new FormData();
    
        if (values && values.unmute_phone_sound !== undefined) {
            data.append(Setting.UNMUTE_PHONE_SOUND,values?.unmute_phone_sound == true ? 1 : 0)
            data.append("objectId",props.app_id)
            data.append("objectName",ObjectName.APP)
        }
    
        // Save settings
        saveSetting(data, null, () => {
            getSettings();
        });
    }
    return (
        <>

            <DefaultContent>
                <Form
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        submit(values);
                    }}
                >
                    <div className="row field-wrapper">
                        <div className="col-lg-12 col-sm-12">
                            <Text
                                name="message_background_fetch_interval"
                                label="Background Fetch Interval"
                                placeholder="Enter Background Fetch Interval"
                                error=""
                            />
                           
                          <SingleCheckbox
                           name={Setting.UNMUTE_PHONE_SOUND}
                           label={"Unmute Phone Sound"}
                           handleOnChangeSubmit={(value,name) =>
                            handleCheckBoxValue(value,name)
                        }
                        />
                        </div>
                        </div>

                            <SaveButton />
                            <CancelButton onClick={() => props.toggle && props.toggle("General")} />
                           
                </Form>
            </DefaultContent>
        </>
    );
};

export default Settings;
