import React, { useEffect, useState } from "react";

// Components
import DateSelector from "../../components/Date";
import Select from "../../components/Select";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import SelectStore from "../../components/SelectStore";
import SingleCheckbox from "../../components/SingleCheckbox";
import Spinner from "../../components/Spinner";
import UserCard from "../../components/UserCard";
import UserSelect from "../../components/UserSelect";
import NumberSelect from "../../components/NumberSelect";
import AttendanceTypeSelect from "../../components/AttendanceTypeSelect";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Helpers
import {
  Attendance,
  lateHoursStatusOptions,
  statusOptions,
} from "../../helpers/Attendance";

// Services
import ShiftService from "../../services/ShiftService";
import { getStoresList } from "../../services/StoreListService";

export const tab = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  PUBLISHED: "Published",
  DRAFT: "Draft",
  ALL: "All",
};

export const AttendanceDetailTab = {
  GENERAL: "General",
  HISTORY: "History",
};

const AttendanceForm = (props) => {
  const [storeList, setStoreList] = useState([]);
  const [shiftList, setShiftList] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const {
    handleTypeChange,
    selectTypeValue,
    handleUserChange,
    handleShiftChange,
    handleStoreChange,
    handleStatusChange,
    handleInTimeChange,
    onDateChange,
    handleOutTimeChange,
    handleLateHoursChange,
    onNotesChange,
    handleDaysCountChange,
    numberList,
    editable,
    userId,
    handleIpAddress,
    setUsersList,
    setLocationList,
    locationId,
    row
  } = props;

  // UseEffect
  useEffect(() => {
    getUsersList();
    getStore();
    getShift();
  }, []);

  const getUserName = (media_url, firstName, lastName) => {
    return (
      <div className="d-flex">
        <UserCard firstName={firstName} lastName={lastName} url={media_url} />
      </div>
    );
  };

  // Getting Users list for user dropdown
  const getUsersList = async () => {
    setIsLoading(true);
    const response = await apiClient.get(`${endpoints().userAPI}/list`);
    const userData = response?.data?.data;
    const data = [];
    userData &&
      userData.length > 0 &&
      userData.forEach((list) => {
        data.push({
          label: getUserName(list?.media_url, list.first_name, list.last_name),
          value: list.first_name,
          id: list.id,
        });
      });
    setIsLoading(false);
    setUsersList(data);
  };

  //  Get Store List
  const getStore = async () => {
    setIsLoading(true);
    const list = await getStoresList();
    setIsLoading(false);
    setStoreList(list);
  };

  if (isLoading) {
    return <Spinner />;
  }

  // Get Shift List
  const getShift = async () => {
    const list = await ShiftService.getShiftLists();
    setShiftList(list);
  };

  return (
    <div>
      <UserSelect
        label="User"
        required
        handleUserChange={handleUserChange}
        isDisabled={editable}
        showUserDetailsPageLink={userId ? true : false}
        userId={userId}
        userList={setUsersList}
      />

      <AttendanceTypeSelect
        name="type"
        handleChange={handleTypeChange}
        required
        accountTypeOption={(x) => props?.accountTypeOption(x)}
        isDisabled={editable}
      />
      <div className="row">
        {selectTypeValue !== Attendance.TYPE_ABSENT &&
          selectTypeValue !== Attendance.TYPE_LEAVE &&
          selectTypeValue !== Attendance.TYPE_ADDITIONAL_LEAVE && (
            <div className="col-12 col-sm-6">
              <SelectStore
                label="Location"
                required
                handleStoreChange={handleStoreChange}
                isDisabled={editable}
                StoreList={setLocationList}
                defaultValue={locationId}
              />
            </div>
          )}
        <div className="col-12 col-sm-6">
          <Select
            name="shift"
            label="Shift"
            placeholder="Select Shift"
            options={shiftList}
            required={selectTypeValue === Attendance.TYPE_LEAVE ? false:true}
            onInputChange={handleShiftChange}
            isDisabled={editable}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <DateSelector
            label="Date"
            name="date"
            format="dd-MMM-yyyy"
            onChange={onDateChange}
            disabled={editable}
          />
        </div>
        {row &&
          <>
            <div className="col">
              <DateSelector
                label="In Time"
                name="login"
                showTimeSelect
                showTimeSelectOnly
                format="h:mm aa"
                isClearable
                onChange={handleInTimeChange}
                disabled={editable}
              />
            </div>
            <div className="col">
              <DateSelector
                label="Out Time"
                name="logout"
                showTimeSelect
                showTimeSelectOnly
                format="h:mm aa"
                isClearable
                onChange={handleOutTimeChange}
                disabled={editable}
              />
            </div>
          </>}
      </div>
      {row && <div className="row">
        <div className="col">
          <Select
            label="Status"
            name="status"
            options={statusOptions}
            onInputChange={handleStatusChange}
            isDisabled={editable}
          />
        </div>
        <div className="col">
          <Text label="Late Hours" name="late_hours" disabled={editable} />
        </div>
        <div className="col">
          <Text
            name="additional_hours"
            label="Additional Hours"
            disabled={editable}
          />
        </div>
      </div>}
      {row && <>
        <Select
          label="Late Hours Status"
          name="late_hours_status"
          options={lateHoursStatusOptions}
          onInputChange={handleLateHoursChange}
          isDisabled={editable}
        />
        <Text
          label="IP Address"
          name="ip_address"
          onChange={handleIpAddress}
          disabled={editable}
        />
      </>}
      <TextArea
        label="Notes"
        name="notes"
        onChange={onNotesChange}
        disabled={editable}
      />
      {((selectTypeValue === Attendance.TYPE_LEAVE ||
        selectTypeValue === Attendance.TYPE_ADDITIONAL_LEAVE) && row) && (
          <NumberSelect
            name="days_count"
            label="Days Count"
            placeholder="Enter Days Count"
            onChange={handleDaysCountChange}
            list={numberList}
            isDisabled={editable}
          />
        )}
      {row && <div className="d-inline-block">
        <div>
          <SingleCheckbox
            name="allow_early_checkout"
            label={"Allow Early CheckOut"}
            enabled={editable}
          />
        </div>
        <div>
          <SingleCheckbox
            name="allow_goal_missing"
            label={"Allow Goal Missing"}
            enabled={editable}
          />
        </div>
        <div>
          <SingleCheckbox
            name="approve_late_check_in"
            label={"Approve Late CheckIn"}
            enabled={editable}
          />
        </div>
      </div>}
    </div>
  );
};

export default AttendanceForm;