import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";
import { Tabs } from "../../helpers/Setting";
import TagDetail from "../../components/TagList";
import AddButton from "../../components/AddButton";

const RatingSetting = (props) => {
  let { history } = props;

  // Initializing state using useState
  const [activeTab, setActiveTab] = useState(Tabs.RATING);
  const [isTypeModelOpen, setIsTypeModelOpen] = useState(false);

  const _toggles = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle label="Rating" />
        <AddButton className="" label="Add Type" onClick={() => _toggles()} />
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.RATING}>
          <TagDetail
            tagType={"RatingType"}
            noTagDetail={true}
            history={history}
            showPageTitle={true}
            showEditPopup
            isModel={isTypeModelOpen}
            __toggle={_toggles}
            props={props}
            label="Rating"
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default RatingSetting;
