export const TagTypeName = {
  ACCOUNT_ENTRY_CATEGORY: "Account Entry Category",
  PRODUCT: "Product",
  VISITOR_TYPE: "Visitor Type",
  MODULE: "Module",
  FINE_TYPE: "FineType",
  BONUS_TYPE: "BonusType",
};
export const TagType = {
  ACCOUNT_ENTRY_BENEFICIARY: "Account Entry Beneficiary",
};
export const Tag = {
  VISITOR_CATEGORY: "Visitor Category",
  STATUS_ACTIVE: "Active",
  FINETYPE: "FineType",
  FILE_CATEGORY: "File Category"
}
export const TagTypeNames = {
  Designation: "Designation",
};

export const tagOptions = [
  {
    value: "FineType",
    label: "FineType",
  },
  {
    value: "Manufacture",
    label: "Manufacture",
  },
  {
    value: "Product",
    label: "Product",
  },
  {
    value: "Visitor Type",
    label: "Visitor Type",
  },
  {
    value: "Training Category",
    label: "Training Category",
  },
  {
    value: "Account Entry Category",
    label: "Account Entry Category",
  },
  {
    value: "Account Entry Beneficiary",
    label: "Account Entry Beneficiary",
  },
  {
    value: "Designation",
    label: "Designation",
  },
  {
    value: "GST Status",
    label: "GST Status",
  },
  {
    value: "Custom Field",
    label: "Custom Field",
  },
  {
    value: "Priority",
    label: "Priority",
  },
  {
    value: "Severity",
    label: "Severity",
  },
  {
    value: "Module",
    label: "Module",
  },
  {
    value: "Currency Denomination",
    label: "Currency Denomination",
  },
  {
    value: "BonusType",
    label: "BonusType",
  },
  {
    value: "File Category",
    label: "File Category",
  },
  {
    value: "RatingType",
    label: "RatingType",
  }
]
