import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Components
import AccountSelect from "../../../components/AccountSelect";
import Currency from "../../../components/Currency";
import DateSelector from "../../../components/Date";
import Drawer from "../../../components/Drawer";
import MediaCarousel from "../../../components/MediaCarousel";
import PaymentAccountSelect from "../../../components/PaymentAccountSelect";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";
import Spinner from "../../../components/Spinner";
import StatusText from "../../../components/StatusText";
import Text from "../../../components/Text";
import TextArea from "../../../components/TextArea";
import UserSelect from "../../../components/UserSelect";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";

// Helpers
import Media from "../../../helpers/Media";
import ObjectName from "../../../helpers/ObjectName";

// Lib
import currency from "../../../lib/Currency";
import DateTime from "../../../lib/DateTime";
import Url from "../../../lib/Url";

// Services
import MediaService from "../../../services/MediaService";
import PaymentService from "../../../services/PaymentService";
import StatusService from "../../../services/StatusService";
import { AccountService } from "../../../services/AccountService";

// Actions
import { fetchList } from "../../../actions/table";

// API
import { endpoints } from "../../../api/endPoints";

const buttonLabel = true;

const PaymentList = (props) => {
  const {
    params,
    history,
    options,
    paymentTab,
    isOpen,
    toggles,
    billData,
    showAccountFilter,
    showPaymentAccountFilter,
    showStatusFilter,
    showUserFilter,
    assigneePlaceholder,
    handleOpenModal,
    handleCloseModal,
    setRowValue,
    rowValue = null,
    setDetail,
    detail,
    setIsSubmitting,
    isSubmitting,
    notesValue,
    setOpenDeleteModal,
    purchaseAcountId,
    purchaseOwnerId,
    apiUrl,
    pendingPayment,
    isAccountFieldDisable = false,
    totalAmount,
    showTotal
  } = props;

  const [amount, setAmount] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [accountValue, setAccountValue] = useState("");
  const [PaymentAccount, setPaymentAccount] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionChange, setDescriptionChange] = useState();
  const [notesChange, setNotesChange] = useState();
  const [imageurl, setImageUrl] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [dueDate, setDueDate] = useState();
  const [date, setDate] = useState();
  const [accountList, setAccountList] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [paymentAccountName, setpaymentAccountName] = useState([]);
  const [userList, setUserList] = useState([]);
  const [status, setStatus] = useState("");
  const [paymentAccountValue, setPaymentAccountValue] = useState("");
  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  useEffect(() => {
    getStatusList();
  }, []);

  useEffect(() => {
    getDetails();
  }, [rowValue]);

  useEffect(() => {
    if (selectedFile) {
      getUrl();
    }
  }, [isLoading, selectedFile]);

  const getDetails = async () => {
    const data = await PaymentService.get(rowValue && rowValue?.id);
    if (data && setDetail) {
      setDetail(data);
    }
  };

  const getUrl = () => {
    let url = [];
    for (let i = 0; i < selectedFile.length; i++) {
      const file = selectedFile[i];
      const imageUrl = URL.createObjectURL(file && file[0]);
      url.push({ url: imageUrl, image_id: file.id });
    }
    setImageUrl(url);
  };

  const handleAmountChange = (e) => {
    let value = parseFloat(e.values?.amount ? e.values?.amount : 0) || 0;
    setAmount(value);
  };

  const handlePaymenyAccount = (e) => {
    let value = e;
    setPaymentAccount(value);
    if (value == null) {
      setAccountId("");
    }
  };

  const handleAccountChange = (e) => {
    setAccountValue(e);
    setAccountId(e.paymentAccount);
    setPaymentAccount("");
    setPaymentAccountValue("");
  };

  const handleNotesChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNotesChange(value);
  };

  let account_id = accountValue
    ? accountValue
    : rowValue
      ? rowValue?.accountId
      : billData
        ? billData?.account?.id
        : purchaseAcountId
          ? purchaseAcountId
          : params?.account
            ? params?.account
            : "";


  const getStatusList = async () => {
    const getStatus = [];
    const status = await StatusService.search(ObjectName.PAYMENT);
    for (let i = 0; i < status.length; i++) {
      getStatus.push({
        label: status[i].name,
        value: status[i].id,
        default_owner: status[i]?.default_owner,
      });
    }
    setStatusList(getStatus);
  };

  const _toggle = () => {
    setDueDate("");
    setRowValue("");
    setSelectedFile("");
    setImageUrl("");
    setAmount("");
    setImageUrl("");
    setNotesChange("");
    setAccountId("");
    setInvoiceNumber("");
    setAccountValue("");
    setStatus("");
    handleCloseModal();
    setPaymentAccountValue("");
    setDate("");
  };

  let ownerId =
    rowValue && rowValue.owner_id
      ? rowValue.owner_id
      : purchaseOwnerId
        ? purchaseOwnerId
        : status && status?.default_owner
          ? status?.default_owner
          : null;

  let notesDefaultValue = `${DateTime.getDate(
    billData?.bill_date
  )}/${billData?.invoiceNumber
    }`;

  const initialValues = {
    due_date: dueDate ? dueDate : rowValue?.due_date ? rowValue?.due_date : "",
    date: date
      ? date
      : rowValue?.date
        ? rowValue?.date
        : DateTime.getTodayDateByUserTimeZone(new Date()),
    status: status
      ? status
      : rowValue
        ? {
          label: rowValue?.status,
          value: rowValue?.statusId,
        }
        : statusList
          ? {
            label: statusList[0]?.label,
            value: statusList[0]?.value,
          }
          : "",
    amount:
      amount === 0
        ? ""
        : amount
          ? amount
          : rowValue?.amount
            ? rowValue?.amount
            : billData?.pendingPaymentAmount,
    account: accountValue
      ? accountValue
      : rowValue
        ? {
          label: rowValue?.account,
          value: rowValue?.accountId,
        }
        : accountList && accountList.find((value) => value.id == account_id),
    payment_account:
      accountId && accountId
        ? paymentAccountName.find((data) => data.value === accountId)
        : PaymentAccount
          ? PaymentAccount
          : paymentAccountValue
            ? paymentAccountName.find((data) => data.value === paymentAccountValue)
            : billData?.paymentAccount
              ? paymentAccountName.find(
                (data) => data.value === billData?.paymentAccount
              )
              : "",
    description: descriptionChange
      ? descriptionChange
      : detail?.description
        ? detail?.description
        : "",
    notes: billData?.bill_date
      ? notesDefaultValue

      : notesChange
        ? notesChange
        : notesValue
          ? notesValue
          : "",
    invoice_number: invoiceNumber
      ? invoiceNumber

      : rowValue?.invoice_number
        ? rowValue?.invoice_number
        : billData?.invoiceNumber
          ? billData?.invoiceNumber
          : "",

    owner_id: userList && userList.find((data) => data.id == ownerId),
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(false);
      const data = new FormData();
      let param = {
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        search: Url.GetParam("search"),
        startDate: Url.GetParam("startDate"),
        endDate: Url.GetParam("endDate"),
        status: Url.GetParam("status"),
        page: Url.GetParam("page"),
        pageSize: Url.GetParam("pageSize"),
        account: Url.GetParam("account"),
        paymentAccount: Url.GetParam("paymentAccount"),
        user: Url.GetParam("user"),
        showTotal: true
      };
      if (params && params?.bill_id) {
        param.bill_id = params && params?.bill_id;
      }
      if (params && params?.purchaseId) {
        param.purchaseId = params && params?.purchaseId;
      }
      if (params && params.account) {
        param.account = params && params?.account;
      }

      if (rowValue && rowValue?.id) {
        data.append("date", new Date(values.date));
        data.append("amount", values && values.amount);
        data.append("status", values && values.status.value);

        data.append("account", values && values.account.value);
        data.append(
          "payment_account",
          values?.payment_account?.value ? values?.payment_account?.value : ""
        );
        data.append(
          "description",
          values.description ? values.description : ""
        );
        data.append("notes", values.notes ? values.notes : "");
        data.append("due_date", values.due_date ? values?.due_date : "");
        data.append(
          "invoice_number",
          values?.invoice_number ? values?.invoice_number : ""
        );
        data.append("owner_id", values?.owner_id ? values?.owner_id?.id : "");
        data.append("bill_id", rowValue?.bill_id ? rowValue?.bill_id : "");
        await PaymentService.update(
          rowValue?.id,
          data,
          setIsSubmitting,
          (res) => {
            if (res) {
              let valueProps = {
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                search: Url.GetParam("search"),
                page: Url.GetParam("page"),
                pageSize: Url.GetParam("pageSize"),
                purchaseId: param && param.purchaseId ? param.purchaseId : "",
                account: Url.GetParam("account"),
                paymentAccount: Url.GetParam("paymentAccount"),
                user: Url.GetParam("user"),
                startDate: Url.GetParam("startDate"),
                endDate: Url.GetParam("endDate"),
                status: Url.GetParam("status"),
                showTotal: true
              };
              if (billData && billData?.id) {
                valueProps.bill_id = billData?.id;
              }
              if (params && params.account) {
                valueProps.account = params && params?.account;
              }

              if (pendingPayment) {
                valueProps.pendingPayment = true;
              }

              if (props && props.getBillDetail) {
                props.getBillDetail()
              }
              let apiURL = pendingPayment
                ? `${endpoints().paymentAPI}/pending`
                : `${endpoints().paymentAPI}/search`;

              dispatch(
                fetchList(
                  "payment",
                  apiURL,
                  valueProps.page ? valueProps.page : 1,
                  valueProps.pageSize ? valueProps.pageSize : 25,
                  valueProps
                )
              );
              setPaymentAccount("");
              setPaymentAccountValue("");
              setAccountValue("");
              setSelectedFile("");
              setDueDate("");
              setDate("");
              setRowValue("");
              setAmount("");
              setInvoiceNumber("");
              handleCloseModal();
              setIsSubmitting(true);
              setStatus("");
              setNotesChange("");
              setPaymentAccountValue("");
            }
          }
        );
      } else {
        data.append("date", new Date(values.date));
        data.append("amount", values && values.amount);
        data.append("status", values && values.status.value);

        data.append("account", values && values.account.value);
        data.append(
          "payment_account",
          values.payment_account.value ? values.payment_account.value : ""
        );

        data.append("notes", values.notes ? values.notes : "");
        if (params?.bill_id) {
          data.append("bill_id", params && params?.bill_id);
        }
        data.append("due_date", values?.due_date ? values?.due_date : "");
        data.append(
          "invoice_number",
          values?.invoice_number ? values?.invoice_number : ""
        );
        data.append(
          "owner_id",
          status?.default_owner
            ? status?.default_owner
            : values?.owner_id
              ? values?.owner_id?.id
              : ""
        );

        dispatch(
          await PaymentService.create(data, param, toggles, (response) => {
            if (response) {
              setIsSubmitting(true);
              uploadFile(response?.data.id);
              setPaymentAccount("");
              setAccountValue("");
              setSelectedFile("");
              setInvoiceNumber("");
              setDueDate("");
              setDate("");
              setRowValue("");
              setAmount("");
              handleCloseModal();
              _toggle();

              if (props && props.getBillDetail) {
                props.getBillDetail()
              }
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDueDateChange = (e) => {
    setDueDate(e);
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const uploadFile = async (objectId, showToastMessage = false) => {
    try {
      if (selectedFile && selectedFile.length > 0 && objectId) {
        for (let i = 0; i < selectedFile.length; i++) {
          const File = selectedFile[i];
          const mediaFile = File ? File[0] : "";
          const media = File[0]?.name;

          const data = new FormData();

          if (mediaFile) {
            data.append([Media.MEDIA_FILE], mediaFile ? mediaFile : "");
          }
          if (media !== undefined) {
            data.append([Media.MEDIA_NAME], media ? media : "");
          }
          data.append("object", ObjectName.PAYMENT);

          data.append("object_id", objectId);

          data.append([Media.MEDIA_VISIBILITY], Media.VISIBILITY_PUBLIC);

          await MediaService.saveImage(data, showToastMessage);
          setImageUrl("");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStatusChange = (values) => {
    setStatus(values?.values?.status ? values?.values?.status : "");
  };

  const onDropImage = (images) => {
    handleImageValue({
      ...images,
      id: selectedFile.length + 1,
    });
  };

  const handleImageValue = (images) => {
    setSelectedFile((prevFileList) => [...prevFileList, { ...images }]);
  };

  const handleImageRemove = (deletedvalue) => {
    setIsLoading(true);
    const updatedImageUrlArray = selectedFile.filter(
      (item) => item.id !== deletedvalue.image_id
    );
    setSelectedFile(updatedImageUrlArray);
    setIsLoading(false);
  };

  const onInvoicNumberChange = (e) => {
    let value = e.target.value;
    setInvoiceNumber(value);
  };

  const addPaymentForm = (
    <>
      <DateSelector
        label="Date"
        name="date"
        format="dd-MMM-yyyy"
        onChange={handleDateChange}
        required
      />

      <AccountSelect
        name="account"
        label="Account"
        placeholder="Select Account"
        handleVendorChange={handleAccountChange}
        required
        isDisabled={
          isAccountFieldDisable || billData?.account?.id ? true : false
        }
        showAccountDetailsPageLink={
          rowValue?.id && rowValue?.accountId ? true : false
        }
        accountId={rowValue?.id ? rowValue?.accountId : null}
        defaultValue={rowValue?.id ? rowValue?.accountId : null}
        vendorList={setAccountList}
      />

      <PaymentAccountSelect
        handleChange={handlePaymenyAccount}
        setpaymentAccountName={setpaymentAccountName}
        isDisabled={billData?.paymentAccount ? true : false}
        name={"payment_account"}
        label={"Payment Account"}
        required
      />
      <Text
        label="Invoice Number"
        name="invoice_number"
        onChange={onInvoicNumberChange}
        required
      />

      <Currency
        label="Amount"
        name="amount"
        onInputChange={handleAmountChange}
        required
      />
      <Select
        name="status"
        label="Status"
        placeholder="Select Status"
        onInputChange={handleStatusChange}
        options={statusList}
        required
      />
      <div className="row">
        <div className="col-12 col-sm-6">
          <UserSelect
            name="owner_id"
            label="Owner"
            userList={setUserList}
            selectedUserId={ownerId}
            showLoggedInUser={!rowValue?.id && props.showLoggedInUser}
            showUserDetailsPageLink={rowValue?.id ? true : false}
            userId={rowValue?.id ? rowValue?.owner_id : null}
          />
        </div>
        <div className="col-12 col-sm-6">
          <DateSelector
            label="Due Date"
            name="due_date"
            isClearable
            onChange={handleDueDateChange}
          />
        </div>
      </div>
      <TextArea
        name="notes"
        label="Notes"
        placeholder="Enter Notes..."
        error=""
        fontBolded
        onChange={handleNotesChange}
      />

      {!rowValue && (
        <div className="col-12">
          <MediaCarousel
            showCarasoul
            Attachments
            onDropImage={onDropImage}
            imageUrl={imageurl}
            handleImageRemove={handleImageRemove}
          />
        </div>
      )}

      {rowValue && rowValue?.id && (
        <MediaCarousel
          showCarasoul
          objectName={ObjectName.PAYMENT}
          objectId={rowValue && rowValue.id}
          history={history}
          attachmentsList={true}
          Attachments={"Attachments"}
        />
      )}
    </>
  );

  const paymentFooter = (
    <SaveButton type="submit" loading={isSubmitting == false} />
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Drawer
        modelTitle={rowValue?.id ? "Edit Payment " : "Add Payment"}
        DrawerBody={addPaymentForm}
        DrawerFooter={paymentFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        handleOpenModal={handleOpenModal}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isOpen}
        buttonLabel={buttonLabel}
        enableReinitialize
      />

      <ReduxTable
        id="payment"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={apiUrl ? apiUrl : `${endpoints().paymentAPI}/search`}
        history={history}
        params={params ? params : ""}
        sortByOptions={sortByOption}
        showDateFilter
        showStatusFilter={showStatusFilter}
        disableHeader={paymentTab ? true : false}
        paramsToUrl={true}
        showAccountFilter={showAccountFilter}
        showPaymentAccountFilter={showPaymentAccountFilter}
        showUserFilter={showUserFilter}
        assigneePlaceholder={assigneePlaceholder}
        showTotal={showTotal}
        showBackgroundColor
      >
        <ReduxColumn
          field="payment_number"
          sortBy="id"
          className="text-center"
          renderField={(row) => (
            <Link to={`/payment/detail/${row.payment_number}`} className="link-opacity-75 text-decoration-none">
              {row.payment_number}
            </Link>
          )}
        >
          Payment#
        </ReduxColumn>
        <ReduxColumn
          field="date"
          sortBy="date"
          className="text-center"
          width="120px"
          minWidth="120px"
          maxWidth="120px"
          renderField={(row) => <span>{DateTime.getDate(row.date)}</span>}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="invoice_number"
          sortBy="invoice_number"
          className="text-center"
        >
          Invoice#
        </ReduxColumn>
        <ReduxColumn field="account" sortBy="name" className="text-start">
          Account
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          sortBy="amount"
          className="text-end"
          renderField={(row) => <span>{currency.Format(row.amount)}</span>}
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          className="text-center"
          renderField={(row) => (
            <StatusText backgroundColor={row.statusColor} status={row.status} />
          )}
        >
          Status
        </ReduxColumn>

        {/* )} */}
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue && setRowValue(row);
                      setPaymentAccountValue(row?.paymentAccountId);
                      toggles();
                      setIsLoading(true);
                      getDetails();
                      setIsLoading(false);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className=" text-danger cursor-pointer"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setRowValue(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Actions
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default PaymentList;
