import React, { useEffect, useState } from "react";
import { endpoints } from "../../api/endPoints";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import AvatarCard from "../../components/AvatarCard";
import Button from "../../components/Button";
import AddModal from "../../components/Modal";
import CandidateService from "../../services/CandidateService.js";
import { useDispatch } from "react-redux";
import CandidateForm from "./components/form";
import { Link } from "react-router-dom";
import ObjectName from "../../helpers/ObjectName";
import { CandidateStatus } from "../../helpers/Candidate";
import StatusService from "../../services/StatusService";
import Drawer from "../../components/Drawer.js";
import Url from "../../lib/Url.js";
import StatusText from "../../components/StatusText.js";

const Candidate = (props) => {
  const [isOpen, setIsOpen] = useState(0);
  const [status, setStatus] = useState();
  const { history } = props;

  const dispatch = useDispatch();
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "first_name:ASC",
      label: "Name",
    },
  ];

  useEffect(() => {
    getStatus();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (values) => {
    values.maritalStatus = values?.maritalStatus?.value;
    values.gender = values?.gender?.value;
    values.skills = JSON.stringify(values.skills);
    values.status = status;
    values.position = values?.position.label;
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
    };
    dispatch(CandidateService.add(values, params, toggle));
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    skills: "",
    interviewDate: "",
    phone: "",
    maritalStatus: "",
    age: "",
    position: "",
    qualification: "",
    currentAddress: "",
    currentArea: "",
    currentCountry: "",
    currentCity: "",
    currentState: "",
    currentPincode: "",
    permanentAddress: "",
    permanentArea: "",
    permanentCountry: "",
    permanentCity: "",
    permanentState: "",
    permanentPincode: "",
    department: "",
    yearOfPassing: "",
    over_all_experience: "",
    projectTitle: "",
    projectPeriod: "",
    projectDescription: "",
    courseName: "",
    coursePeriod: "",
    courseInstitution: "",
    currentSalary: "",
    expected_salary: "",
    message: "",
    percentage: "",
    positionType: "",
    dob: "",
    relevantExperience: "",
    expectedCostPerHour: "",
    jobReferenceType: "",
    jobReferenceName: "",
    willingToWorkInShift: "",
    stayingWith: "",
    tenthPercentage: "",
    tenthYearOfPassing: "",
    twelvethPercentage: "",
    twelvethYearOfPassing: "",
    degreeArrear: "",
    didCourse: "",
    didProject: "",
    linkedinProfileName: "",
    facebookProfileName: "",
    jobTitle: "",
    joinedMonth: "",
    joinedYear: "",
    companyName: "",
    knownLanguages: "",
    employmentEligibility: "",
    didVaccineStatus: "",
  };

  const candidateForm = <CandidateForm />;

  const getStatus = async () => {
    const status = await StatusService.search(
      ObjectName.CANDIDATE,
      CandidateStatus.STATUS_NEW
    );
    for (let i = 0; i < status.length; i++) {
      setStatus(status[i]?.id);
    }
  };

  const candidateFooter = (
    <div className="container-fluid">
      <div className="col-sm-12 text-center">
        <Button
          type="submit"
          label="Add"
          showButtonLabelName
        />
      </div>
    </div>
  );

  return (
    <>
      <PageTitle
        label="Candidates"
        buttonHandler={() => {
          toggle();
        }}
        buttonLabel="Add New"
      />

      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        initialValues={initialValues}
        enableReinitialize
        DrawerBody={candidateForm}
        DrawerFooter={candidateFooter}
        modelTitle={"Add Candidate"}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      />

      <div className="mt-3">
        <ReduxTable
          id="Candidate"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().candidate}/list`}
          newTableHeading
          history={history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            field="userName"
            sortBy="first_name"
            width="190px"
            minWidth="190px"
            maxWidth="190px"
            renderField={(row) => (
              <>
                <Link to={`/jobs/candidate/${row.candidateId}`} className="link-opacity-75 text-decoration-none">
                  <div className="d-flex text-break">
                    <AvatarCard
                      id="avatar"
                      firstName={row.firstName}
                      lastName={row.lastName}
                      url={row.candidate_url}
                    />
                  </div>
                </Link>
              </>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            field="email"
            sortBy="email"
            width="190px"
            minWidth="190px"
            maxWidth="190px "
          >
            Email
          </ReduxColumn>
          <ReduxColumn
            field="gender"
            sortBy="gender"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Gender
          </ReduxColumn>
          <ReduxColumn
            field="interviewDate"
            sortBy="interview_date"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Interview Date
          </ReduxColumn>
          <ReduxColumn
            field="maritalStatus"
            sortBy="marital_status"
            width="140px"
            minWidth="140px"
            maxWidth="140px"
          >
            Marital Status
          </ReduxColumn>
          <ReduxColumn
            field="position"
            sortBy="position"
            width="170px"
            minWidth="170px"
            maxWidth="170px"
          >
            Position
          </ReduxColumn>
          <ReduxColumn
            field="phone"
            sortBy="phone"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
          >
            Phone
          </ReduxColumn>
          <ReduxColumn
            field="statusText"
            sortBy="statusText"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
            renderField={(row) => (
              <StatusText
                backgroundColor={row.statusColor}
                status={row.statusText}
              />
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="qualification"
            sortBy="qualification"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
          >
            Qualification
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default Candidate;
