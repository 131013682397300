import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Components
import AvatarCard from "../../components/AvatarCard";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import UserSelect from "../../components/UserSelect";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Avatar from "../order/components/avatar";

// Helpers
import { Status } from "../../helpers/Product";
import { Tab } from "../../helpers/ProductTag";
import {
  dateOption,
  monthOption,
  typeOptions,
} from "../../helpers/recurringTask";

// Lib
import Url from "../../lib/Url";

// Services
import ShiftSelect from "../../components/ShiftSelect";
import ArrayList from "../../lib/ArrayList";
import ActivityTypeService from "../../services/ActivityTypeService";
import RecurringActiviteService from "../../services/RecurringActivityService";
import ShiftService from "../../services/ShiftService";
import UserRoleService from "../../services/UserRoleService";
import userService from "../../services/UserService";
import StoreSelector from "../location/components/storeSelector";

function Checkbox({ day, checked, onChange }) {
  return (
    <label>
      <input
        className="me-2 ms-2"
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(day, e.target.checked)}
      />
      {day}
    </label>
  );
}

const RecurringActivity = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(null);
  const [month, setMonth] = useState(null);
  const [assignee, setAssignee] = useState("");

  const [userList, setUserList] = useState([]);
  const [date, setDate] = useState(null);
  const [dateOptions, setDataOption] = useState([]);
  const [deleteTag, setDeleteTag] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [week, setWeek] = useState([]);
  const [projectValue, setProjectValue] = useState("");
  const [row, setRow] = useState(null);
  const [isSubmit, setIsSubmit] = useState(true);
  const [selectedDays, setSelectedDays] = useState([]);
  const [activityType, setActivityType] = useState(null);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [roleValue, setRoleValue] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [shiftValue, setShiftValue] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getDateOptions();
    getActivityTypeList();
    getUserRoleList();
  }, []);

  useEffect(() => {
    if (row && row?.day) {
      setSelectedDays(row && row?.day);
    }
  }, [row]);

  const CloseToggle = () => {
    setIsOpen(!isOpen);
    setMonth("");
    setAssignee("");
    setDate("");
    setType("");
    setProjectValue("");
    setWeek([]);
    setSelectedDays([]);
    setRow(null);
    setIsSubmit(true);
    setRoleValue(null);
    setActivityType("");
    setLocationId(null)
    setShiftValue(null)
  };


  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "name:ASC",
      label: "Name",
    },
  ];

  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  const getActivityTypeList = async () => {
    let response = await ActivityTypeService.search();
    let data = response && response?.data && response?.data?.data;
    let list = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const value = data[i];
        list.push({
          label: value?.name,
          value: value?.id,
        });
      }
    }
    setActivityTypeList(list);
  };


  const params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    page: Url.GetParam("page"),
    search: Url.GetParam("search"),
    user: Url.GetParam("user"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
  };

  const addTask = (data, id) => {
    setIsSubmit(false);
    if (data.taskType) {
      data.taskType = data.taskType.value;
    }
    data.day =
      selectedDays && selectedDays.length > 0
        ? JSON.stringify(selectedDays)
        : [];
    if (data.month || month) {
      data.month = month ? month : data?.month?.value;
    }
    if (date || data.date) {
      data.date = date ? date : data?.date?.value;
    }
    if (data.assignee) {
      data.assignee = data.assignee.id;
    }
    if (data.activityType) {
      data.activityType = data?.activityType?.value;
    }

    if (locationId) {
      data.location_id = locationId ? locationId : ""
    }

    if (shiftValue) {
      data.shift_id = shiftValue ? shiftValue : ""
    }

    data.role_id = data?.role_id?.value ? data?.role_id?.value : "";
    if (row?.id) {
      dispatch(
        RecurringActiviteService.update(row?.id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "recurringActivity",
                `${endpoints().RecurringActivityAPI}/search`,
                1,
                25,
                params
              )
            );
            CloseToggle();
            setIsSubmit(true);
          }
        })
      );
    } else {
      dispatch(
        RecurringActiviteService.create(data, params, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "recurringActivity",
                `${endpoints().RecurringActivityAPI}/search`,
                1,
                25,
                params
              )
            );
            CloseToggle();
          }
        })
      );
      setIsSubmit(true);
    }
  };

  const handleDelete = async (id) => {
    dispatch(RecurringActiviteService.delete(id, params));
  };

  const getDateOptions = async () => {
    let dateOptions = dateOption();
    setDataOption(dateOptions);
  };


  const getUserRoleList = async () => {
    const roleData = await UserRoleService.list();
    setUserRole(roleData);
  };

  // initialValues
  const initialValues = {
    role_id: roleValue
      ? roleValue
      : row?.role_id
      ? userRole.find((data) => data?.value == row?.role_id)
      : "",
    date: date
      ? dateOptions.find((data) => data?.value == date)
      : row?.date
      ? dateOptions.find((data) => data?.value == row?.date)
      : "",
    month: month
      ? monthOption.find((data) => data.value == month)
      : monthOption.find((data) => data.value == row?.month?.value),
    taskType: type
      ? typeOptions.find((data) => data.label == type)
      : typeOptions.find((data) => data.label == row?.type),
    assignee:
      (userList && userList.find((data) => data.id == assignee)) ||
      (userList && userList.find((data) => data.id == row?.assignee_id)),
    week: week,
    activityType: activityType
      ? activityTypeList &&
        activityTypeList.find((data) => data?.value == activityType)
      : activityTypeList.find((data) => data?.value == row?.activiteType),
      location: locationId && ArrayList.isArray(storeList) ? ArrayList.isArray(storeList) && storeList.find((data) => data?.id == locationId) : row?.location_id && ArrayList.isArray(storeList) ? ArrayList.isArray(storeList) && storeList.find((data) => data?.id == row?.location_id) : "",
      shift: shiftValue && ArrayList.isArray(shiftList) ? shiftList.find((data) => data?.id == shiftValue) : row?.shift_id && ArrayList.isArray(shiftList) ? shiftList.find((data) => data?.id == row?.shift_id) : ""
  };

  const handleTypeChange = async (e) => {
    setType(e.label);
  };

  const handleUserChange = async (e) => {
    setAssignee(e.id);
  };

  const handleMonthChange = async (e) => {
    let value = e && e?.values && e?.values?.month && e?.values?.month?.value;
    setMonth(value);
  };

  // Handle date
  const handleDate = async (e) => {
    let value = e && e?.values && e?.values?.date && e?.values?.date?.value;
    setDate(value);
  };

  const handleActivityTypeChange = async (e) => {
    setActivityType(e && e?.value);
  };

  const handleCheckboxChange = (day, checked) => {
    if (checked) {
      setSelectedDays([...selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleHandleChange = (e) => {
    setRoleValue(e);
  };

  const onLocationChange = ({ values: { location } }) => {
    setLocationId(location?.id ? location?.id : "")
  }

  const handleShiftChange = (values) => {
    setShiftValue(values?.id ? values?.id : "")
  }

  // Add RecurringTickeaskm
  const addTaskForm = (
    <>
      <UserSelect
        label="Assignee"
        name="assignee"
        placeholder={"Select Assignee"}
        handleUserChange={handleUserChange}
        showUserDetailsPageLink={row?.id ? true : false}
        userId={row?.id ? row?.assignee_id : null}
        required
        userList={setUserList}
      />
      <Select
        name="role_id"
        label="Role"
        placeholder="Select Role"
        options={userRole}
        handleChange={handleHandleChange}
        required
      />
      <Select
        name="activityType"
        label="Activity Type"
        placeholder="Select Activity Type"
        options={activityTypeList}
        handleChange={handleActivityTypeChange}
        required
      />
      <Select
        name="taskType"
        label="Recurring Type"
        placeholder="Select Task"
        options={typeOptions}
        handleChange={handleTypeChange}
        required
      />

      {type == "Weekly" || (row?.type == "Weekly" && !type) ? (
        <div className="d-flex justify-content-between">
          <Checkbox
            day="Monday"
            checked={selectedDays.includes("Monday")}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            day="Tuesday"
            checked={selectedDays.includes("Tuesday")}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            day="Wednesday"
            checked={selectedDays.includes("Wednesday")}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            day="Thursday"
            checked={selectedDays.includes("Thursday")}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            day="Friday"
            checked={selectedDays.includes("Friday")}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            day="Saturday"
            checked={selectedDays.includes("Saturday")}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            day="Sunday"
            checked={selectedDays.includes("Sunday")}
            onChange={handleCheckboxChange}
          />
        </div>
      ) : (
        ""
      )}

      {type == "Annually" || (row?.type == "Annually" && !type) ? (
        <>
          <Select
            name="month"
            label="Month"
            options={monthOption}
            onInputChange={handleMonthChange}
          />
        </>
      ) : (
        ""
      )}
      {type == "Monthly" ||
      type == "Annually" ||
      (row?.type == "Monthly" && !type) ||
      (row?.type == "Annually" && !type) ? (
        <>
          <Select
            name="date"
            label="Date"
            options={dateOptions}
            onInputChange={handleDate}
          />
        </>
      ) : (
        ""
      )}
      <ShiftSelect handleShiftChange={handleShiftChange} label="Shift" defaultValue={row?.shift_id} shiftOption={setShiftList} />
      <StoreSelector setStoreList={setStoreList} isSingleSelect onInputChange={onLocationChange} />
    </>
  );

  // RecurringTicket askr
  const modelFooter = (
    <>
      <SaveButton
        type="submit"
        loading={isSubmit == false}
        label={row?.id ? "Save" : "Add"}
      />
    </>
  );

  return (
    <>
      <PageTitle
        label="Recurring Activity"
        buttonLabel="Add New"
        buttonHandler={() => {
          setRow("");
          _toggle();
        }}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title="Delete Recurring Activity"
        deleteFunction={() => {
          handleDelete(deleteTag.id);
        }}
        label={deleteTag.item}
        id={deleteTag.id}
      />
      <Drawer
        modelTitle={
          row?.id ? "Edit Recurring Activity" : "Add Recurring Activity"
        }
        DrawerBody={addTaskForm}
        DrawerFooter={modelFooter}
        onSubmit={(values) => {
          addTask(values);
        }}
        initialValues={initialValues}
        handleOpenModal={_toggle}
        handleCloseModal={CloseToggle}
        handleDrawerClose={CloseToggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
      <div className="mt-4">
        <ReduxTable
          searchPlaceholder="Search"
          showUserFilter
          newTableHeading
          id="recurringActivity"
          apiURL={`${endpoints().RecurringActivityAPI}/search`}
          paramsToUrl={true}
          history={props.history}
          showCustomDateFilter
          message="You can start by clicking on Add New"
          icon={<FontAwesomeIcon icon={faTasks} />}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            className="text-center text-decoration-none"
            field="id"
            sortBy="id"
            isClickable="true"
            width="100px"
            minWidth="100px"
            type="link"
            maxWidth="150px"
            renderField={(row) => (
              <Link to={`/recurringActivity/details/${row.id}`} className="text-decoration-none">
                {row.item}
              </Link>
            )}
          >
            Recurring Activity#
          </ReduxColumn>
          <ReduxColumn
            field="activityTypeName"
            sortBy="activityTypeName"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
          >
            Activity Type
          </ReduxColumn>
          <ReduxColumn
            className="text-center display-flex"
            field="assignee_name"
            sortBy="name"
            width="210px"
            maxWidth="210px"
            minWidth="210px"
            renderField={(row) => (
              <AvatarCard
                id="avatar"
                firstName={row.firstName}
                lastName={row.lastName}
                url={row.media_url}
              />
            )}
          >
            Assignee
          </ReduxColumn>
          <ReduxColumn
            field="role_name"
            sortBy="role_name"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
          >
            Role
          </ReduxColumn>
          <ReduxColumn
            field="type"
            sortBy="type"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
          >
            Type
          </ReduxColumn>
          <ReduxColumn
            field="date"
            sortBy="date"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
          >
            Date
          </ReduxColumn>
          <ReduxColumn
            field="day"
            sortBy="day"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
            renderField={(row) =>
              row.day &&
              row.day.map((role, index) => (
                <span key={index}>
                  {index > 0 && <br />}
                  {role}
                </span>
              ))
            }
          >
            Day
          </ReduxColumn>
          <ReduxColumn
            field="month"
            sortBy="month"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
            renderField={(row) => <span>{row?.month?.label}</span>}
          >
            Month
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width={"130px"}
            minWidth="130px"
            maxWidth="130px"
            className="column-status"
            renderField={(row) => (
              <div
                className={`status-input text-center rounded text-white text-uppercase ${
                  row.status && row.status === Status.ACTIVE
                    ? "bg-success"
                    : row.status === Status.INACTIVE
                    ? "bg-dark bg-opacity-50"
                    : ""
                }`}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="createdAt"
            sortBy="createdAt"
            className="text-center"
            width="150px"
            minWidth="110px"
            maxWidth="150px"
            renderField={(row) => (
              <span>
                {row.createdAt}
              </span>
            )}
          >
            Created At
          </ReduxColumn>
          <ReduxColumn
            minWidth={"100px"}
            width={"100px"}
            maxWidth={"100px"}
            field="status"
            disableOnClick
            className="action-column"
            renderField={(row) => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRow(row);
                      _toggle();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  {row.status !== Tab.STATUS_ACTIVE_TEXT ? (
                    <DropdownItem
                      onClick={async () => {
                        const status = Status.ACTIVE_VALUE;
                        dispatch(
                          RecurringActiviteService.updateStatus(
                            row.id,
                            { status },
                            params
                          )
                        );
                      }}
                    >
                      Make as Active
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={async () => {
                        const status = Status.INACTIVE_VALUE;
                        dispatch(
                          RecurringActiviteService.updateStatus(
                            row.id,
                            { status },
                            params
                          )
                        );
                      }}
                    >
                      Make as InActive
                    </DropdownItem>
                  )}

                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteTag(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default RecurringActivity;
