import React from "react";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import { endpoints } from "../../api/endPoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../../components/PageTitle";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/Breadcrumb";
import DateTime from "../../lib/DateTime";
import Currency from "../../lib/Currency";
import AvatarCard from "../../components/AvatarCard";
import { Order } from "../../helpers/Order";
import Url from "../../lib/Url";
import ObjectName from "../../helpers/ObjectName";

const OrderReport = (props) => {

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Order Report",
      link: "",
    },
  ];

  const customTypeOptions = [
    {
      label: "Location Wise",
      value: "Location Wise",
    },
    {
      label: "User Wise",
      value: "User Wise",
    }
  ]

  const orderTypeOption = [
    {
      label: "Store",
      value: 1,
    },
    {
      label: "Delivery",
      value: 2,
    },
  ]

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="Order Report" className="mb-3" />
      <ReduxTable
        id="orderReport"
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().orderReportAPI}`}
        newTableHeading
        icon={<FontAwesomeIcon icon={faUser} />}
        message="You can start by clicking add."
        history={props.history}
        paramsToUrl={true}
        showDateFilter
        showStoreFilter
        sortByDropdown
        showUserFilter
        showShiftFilter
        showTypeFilter
        customTypeOption={customTypeOptions}
        showPaymentTypeFilter
        showStatusGroupFilter
        showStatusFilter
        params={{
          objectName: ObjectName.ORDER
        }}
        orderTypeOption={orderTypeOption}
        showOrderTypeFilter
      >
        <ReduxColumn
          className="text-center"
          field="date"
          sortBy="date"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) =>
            <>
              {DateTime.getDate(row.date)}
            </>
          }>
          Date
        </ReduxColumn>
        {Url.GetParam("type") !== "User Wise" && !Url.GetParam("paymentType") && <ReduxColumn
          field="location"
          sortBy="location"
          className="text-center"
          width="140px"
          minWidth="140px"
          maxWidth="140px"
        >
          Location
        </ReduxColumn>}
        {Url.GetParam("type") !== "Location Wise" && !Url.GetParam("paymentType") &&
          <ReduxColumn
            field="salesExecutive"
            className="ellipsis"
            sortBy="salesExecutive"
            width="310px"
            minWidth="310px"
            maxWidth="310px"
            renderField={(row) => (
              <>
                <AvatarCard
                  firstName={row?.firstName}
                  lastName={row?.lastname}
                  url={row?.image_url}
                />
              </>
            )}
          >
            Sales Executive
          </ReduxColumn>}
        {Url.GetParam("type") !== "Location Wise" && Url.GetParam("type") !== "User Wise" && !Url.GetParam("paymentType") &&
          <ReduxColumn
            field="shift"
            sortBy="shift"
            className="text-center"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Shift
          </ReduxColumn>}
        {Url.GetParam("type") !== "Location Wise" && Url.GetParam("type") !== "User Wise" &&
          <ReduxColumn
            field="paymentType"
            sortBy="payment_type"
            className="text-center"
            width="140px"
            minWidth="140px"
            maxWidth="140px"
          >
            Payment Type
          </ReduxColumn>
        }
        <ReduxColumn
          field="cash_amount"
          sortBy="cash_amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span className="float-end">
              {Currency.Format(row.cash_amount)}
            </span>
          )}
        >
          Cash Amount
        </ReduxColumn>
        <ReduxColumn
          field="upi_amount"
          sortBy="upi_amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span className="float-end">
              {Currency.Format(row.upi_amount)}
            </span>
          )}
        >
          Upi Amount
        </ReduxColumn>
        <ReduxColumn
          field="total_amount"
          sortBy="total_amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span className="float-end">
              {Currency.Format(row.total_amount)}
            </span>
          )}
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="order_count"
          sortBy="order_count"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Count
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default OrderReport;
