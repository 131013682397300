import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Config
import { apiClient } from "../../apiClient";

// API
import { endpoints } from "../../api/endPoints";

// Components
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/SaveButton";
import AddButton from "../../components/AddButton";
import Form from "../../components/Form";
import ProductSelectModal from "../../components/ProductSelectModal";
import Toast from "../../components/Toast";
import GeneralTab from "./components/generalTab";
import ProductTab from "./components/productTab";
import Status from "../../components/Status";
import ActivityList from "../../components/ActivityList";
import Action from "../../components/Action";
import Avatar from "../../components/Avatar";
import Spinner from "../../components/Spinner";
import MediaCarousel from "../../components/MediaCarousel";

// Actions
import { fetchList } from "../../actions/table";

// Lib
import DateTime from "../../lib/DateTime";
import Cookies, { clearCookie, setCookie } from "../../lib/Helper";
import { isBadRequest } from "../../lib/Http";
import Url from "../../lib/Url";
import Number from "../../lib/Number";
import ArrayList from "../../lib/ArrayList";

// Services
import { AccountService } from "../../services/AccountService";
import OrderProductService from "../../services/OrderProductService";
import OrderService from "../../services/OrderService";
import CompanyUserService from "../../services/UserService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Helpers
import Permission from "../../helpers/Permission";
import Account from "../../helpers/Account";
import Cookie from "../../helpers/Cookie";
import ObjectName from "../../helpers/ObjectName";
import URL from "../../helpers/Url";
import { Order } from "../../helpers/Order";

// Style
import "./style.scss";
import { OrderType } from "../../helpers/OrderTypeGroup";
import orderTypeService from "../../services/OrderTypeService";
import AddModal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import CancelButton from "../../components/CancelButton";
import SaveButton from "../../components/SaveButton";

const AddProducts = (props) => {
  const { history, toggle } = props;
  const [location, setStore] = useState([]);
  const [orderDate, setOrderDate] = useState(new Date());
  const [selectedAssignee, setSelectedAssignee] = useState();
  const [shiftValue, SetShiftValue] = useState(null);
  const [outofStockProducts, setOutOfStockProducts] = useState();
  const [orderCreatedBy, setOrderCreatedBy] = useState();
  const [shiftList, setShiftList] = useState([]);
  const [paymentValue, setPaymentValue] = useState(null);
  const [storeValue, setStoreValue] = useState(null);
  const [modelOpen, setAddProductModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [owner, setSelectedOwner] = useState(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderType, setOrderType] = useState(null);
  const [isReasonOpen, setIsReasonOpen] = useState(false);
  const [reasonChange, setReasonChange] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);


  const Tab = {
    SUMMARY: "Summary",
    PRODUCT: "Product",
    HISTORY: "History",
    ATTACHMENTS: "Attachments",
  };

  const [activeTab, setActiveTab] = useState(Tab.SUMMARY);
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [selectProduct, setSelectedProduct] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [mediaLists, setMediaList] = useState([]);

  let pathName = props?.history?.location?.pathname.replace(/\/\d+$/, "");
  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  const [date, setDate] = useState();
  const [MultiSelectProduct, setMultiSelectProduct] = useState([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [customerValue, setCustomerValue] = useState("");
  const [OrderDetail, setOrderDetail] = useState(null);
  const [cashAmountValue, setCashAmountValue] = useState(null);
  const [upiAmountValue, setUpiAmountValue] = useState(null);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);

  let storeId = Cookies.get(Cookie.ORDER_SELECTED_STORE);
  let order_id = Number.isNotNull(props?.match?.params?.id)
    ? props?.match?.params?.id
    : Cookies.get(Cookie.ORDER_ID);

  const Dispatch = useDispatch();

  let showEditButton = hasPermission(Permission.ORDER_EDIT);

  const orderRef = useRef({
    orderId: null,
  });

  const orderId = orderRef.current.orderId;

  useEffect(() => {
    getUserDetail();
    getCustomerList();
    getUsersList();
    getOrderTypes()
    if (!props?.match?.params?.id) {
      setEditable(false);
    }
  }, []);

  useEffect(() => {
    orderRef.current.orderId = order_id;
    if (order_id) {
      getOrderDetails(order_id);
    }
    setDate(new Date());
  }, []);

  const paymentType = [
    {
      label: "Cash",
      value: 1,
    },
    {
      label: "Upi",
      value: 2,
    },
    {
      label: "Mixed",
      value: 3,
    },
  ];


  const getOrderDetails = async (orderId) => {
    try {
      //validate order Id exist or not
      if (orderId) {
        const response = await OrderService.get(orderId);

        setCustomerValue("");

        //validate response data exist or not
        if (response && response.data && response.data.data) {
          //get order details
          let orderDetails = response.data.data;
          setCustomer(orderDetails && orderDetails?.customer_account);

          setOrderNumber(orderDetails && orderDetails?.order_number);
          setCashAmountValue(orderDetails && orderDetails?.cash_amount);
          setUpiAmountValue(orderDetails && orderDetails?.upi_amount);

          setOrderDetail(orderDetails);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCustomerList = async () => {
    let params = {
      accountCategory: Account.CATEGORY_CUSTOMER,
    };
    let response = await AccountService.list(params);
    let data = response && response?.data;
    let list = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const { name, id, mobile_number } = data[i];
        list.push({
          label: (
            <div>
              <div>{name}</div>
              <div>{mobile_number}</div>
            </div>
          ),
          value: name + " " + mobile_number,
          id: id,
        });
      }
    }
    setCustomerList(list);
  };



  //create order action
  const createOrder = async (orderData) => {
    let response = await OrderService.create(orderData);
    return response;
  };

  //update order action
  const updateOrder = async (orderId, orderData, callback) => {
    OrderService.update(orderId, orderData, (response, outofStockProducts) => {
      return callback(response, outofStockProducts);
    });
  };



  const getUserName = (media_url, firstName, lastName) => {
    return (
      <div className="d-flex">
        <Avatar
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={media_url}
        />
        <div className="edit-profile-name m-2">
          {firstName} {lastName}
        </div>
      </div>
    );
  };

  const getOrderTypes = async () => {
    let response = await orderTypeService.list({ id: Url.GetParam("type") });
    let data = response && response?.data
    setOrderTypes(data)
  }

  const getUsersList = async () => {
    const userRole = await CompanyUserService.list();
    const data = [];
    userRole?.data &&
      userRole.data.length > 0 &&
      userRole.data.forEach((list) => {
        data.push({
          label: getUserName(list.media_url, list.first_name, list.last_name),
          value: list.first_name,
          id: list.id,
        });
      });
    setUsersList(data);
  };

  const storeFieldOnchange = (e) => {
    const value = e?.id ? e?.id : "";
    setStoreValue(value);
  };

  const orderDateFieldOnchange = (e) => {
    setOrderDate(e);
  };

  const deliveryDateFieldOnchange = (e) => {
    setDeliveryDate(e);
  };



  // Bread crumb list
  const breadcrumbList = [
    {
      label: "Home",
      link: "/locationDashboard",
    },
    {
      label:
        "Orders",
      link:
        URL.ORDER_LIST,
    },
    {
      label:
        pathName == "/order" && props?.match?.params?.id
          ? "Order Detail"
          : "New Order",
    },
  ];

  // get the login user details
  const getUserDetail = async () => {
    try {
      let response = await apiClient.get(`${endpoints().userAPI}/`);
      const createdBy = response.data.id;
      setOrderCreatedBy(createdBy);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const completeOrder = async () => {
    let order_id = Number.isNotNull(props?.match?.params?.id)
      ? props?.match?.params?.id
      : Cookies.get(Cookie.ORDER_ID);

    if (
      mediaLists &&
      mediaLists.length == 0 &&
      OrderDetail?.payment_type == Order.PAYMENT_UPI_VALUE
    ) {
      return Toast.error("Please Add Attachment");
    }

    OrderService.completeOrder(
      order_id,
      {
        payment_type: OrderDetail?.payment_type,
      },
      (response, outofStockProducts) => {
        if (outofStockProducts) {
          setOutOfStockProducts(outofStockProducts);
        } else {

          history.push(URL.ORDER_LIST);
          clearCookie(Cookie.ORDER_ID);
        }
      }
    );
  };



  const onChangeOwner = (e) => {
    const value = e.id ? e.id : "";
    setSelectedOwner(value);
  };

  const onChangeAssignee = (e) => {
    const value = e ? e : "";
    setSelectedAssignee(value);
  };

  const handleShiftChange = (values) => {
    const value = values?.value
      ? values?.value
      : "";
    SetShiftValue(value);
  };



  const onChangePaymentValue = (x) => {
    const value = x?.values?.payment_type?.value;
    setPaymentValue(value);
  };

  const onChangeSalesCustomer = (e) => {
    const value = e && e.id ? e.id : "";
    setCustomerValue(value);
  };

  const handleCashAmount = (e) => {
    const value = e?.values?.cash_amount;
    setCashAmountValue(value);
  };

  const handleUpiAmount = (e) => {
    const value = e?.values?.upi_amount;
    setUpiAmountValue(value);
  };


  const onChangeTypeValue = (e) => {
    let value = e?.values?.type;
    setOrderType(value)
  }

  let customerData = customerList.find((data) => {
    const valueToCheck =
      customerValue !== "" && customerValue !== "undefined"
        ? customerValue
        : customer;
    return data.id === valueToCheck;
  });



  const initialValues = {
    location: storeValue
      ? location.find((data) => data.id == storeValue)
      : location.find((data) => data.id == OrderDetail?.location?.id),
    shift: shiftValue
      ? shiftList.find((data) => data.id == shiftValue)
      : shiftList.find((data) => data.id == OrderDetail?.shift),

    owner: owner
      ? usersList.find((data) => data.id == owner)
      : OrderDetail
      && usersList.find(
        (data) => data.id == OrderDetail?.owner
      )
    ,
    payment_type: paymentValue
      ? paymentType.find((data) => data.value == paymentValue)
      : OrderDetail
        ? paymentType.find((data) => data.value == OrderDetail?.payment_type)
        : paymentType.find(
          (data) => data.value == Cookies.get(Cookie.ORDER_SELECTED_PAYMENT)
        ),
    date: OrderDetail?.date
      ? DateTime.getTimeZoneDateTime(OrderDetail?.date)
      : orderDate
        ? DateTime.getTimeZoneDateTime(orderDate)
        : DateTime.getTimeZoneDateTime(new Date()),
    customer_account: customerData,
    cash_amount: cashAmountValue ? cashAmountValue : OrderDetail?.cash_amount,
    upi_amount: upiAmountValue ? upiAmountValue : OrderDetail?.upi_amount,
    type: orderType ? orderType : OrderDetail
      ? orderTypes.find((data) => data?.value == OrderDetail?.type) : orderTypes.find((data) => data?.value == Url.GetParam("type")),
    delivery_date: OrderDetail?.delivery_date
      ? DateTime.getTimeZoneDateTime(OrderDetail?.delivery_date)
      : deliveryDate
        ? DateTime.getTimeZoneDateTime(deliveryDate)
        : "",
  };

  const handleSubmit = async () => {
    const createData = new FormData();
    createData.append("productIds", MultiSelectProduct);
    createData.append("orderId", order_id);
    createData.append("storeId", storeId);
    createData.append("order_number", orderNumber);

    if (order_id) {
      let response = await OrderProductService.create(createData);
      setIsLoading(true);
      if (response) {
        Toast.success(response.data.message);
        setAddProductModal(false);
        setMultiSelectProduct("");
      }
      Dispatch(
        fetchList("order", `${endpoints().orderProductAPI}/search`, 1, 25, {
          orderId: order_id,
          isWeb: order_id ? true : false,
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
        })
      );
      setIsLoading(false);
    } else {
      Toast.error("Order id Required");
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const addProductToggle = () => {
    setAddProductModal(false);
  };

  const multiselect = (values) => {
    setMultiSelectProduct((prevValues) => {
      const updatedValues = new Set([...prevValues, ...values]);
      return Array.from(updatedValues);
    });
  };

  const onSubmit = async (values) => {
    if (order_id || orderId) {
      let orderUpdateData = new Object();

      if (values.location) {
        orderUpdateData.storeId = values?.location?.id
          ? values?.location?.id
          : "";
      }
      if (values.payment_type) {
        orderUpdateData.payment_type = values?.payment_type?.value
          ? values?.payment_type?.value
          : "";
      }

      if (values.shift) {
        orderUpdateData.shift = values?.shift?.value;
      }
      if (values.date) {
        orderUpdateData.date = values.date ? values.date : "";
      }

      if (values.delivery_date) {
        orderUpdateData.delivery_date = values.delivery_date ? values.delivery_date : "";
      }

      if (values?.cash_amount) {
        orderUpdateData.cash = values?.cash_amount
          ? values?.cash_amount
          : "";
      }

      if (values?.upi_amount) {
        orderUpdateData.upi = values?.upi_amount
          ? values?.upi_amount
          : "";
      }

      if (values.owner) {
        orderUpdateData.owner = values
          ?.owner?.id
          ? values?.owner?.id
          : "";
      }
      if (values?.customer_account) {
        orderUpdateData.customer_account =
          values && values?.customer_account
            ? values?.customer_account?.id
            : "";
      }
      orderUpdateData.type = values?.type?.value;

      let response = await updateOrder(orderId, orderUpdateData, () => { });

      if (response && response.data) {
        Toast.success(response.data.message);
      }
    } else {
      let orderCreateData = new Object();

      if (values.location) {
        orderCreateData.storeId = values?.location?.id
          ? values?.location?.id
          : "";
      }
      if (values.payment_type) {
        orderCreateData.payment_type = values?.payment_type?.value
          ? values?.payment_type?.value
          : "";
      }
      if (values.date) {
        orderCreateData.date = values.date ? values.date : "";
      }

      if (values.delivery_date) {
        orderCreateData.delivery_date = values.delivery_date ? values.delivery_date : "";
      }

      if (values.shift) {
        orderCreateData.shift = values?.shift?.value;
      }


      if (values.owner) {
        orderCreateData.owner = values
          ?.owner?.id
          ? values?.owner?.id
          : "";
      }

      if (values?.cash_amount) {
        orderCreateData.cash_amount = values?.cash_amount
          ? values?.cash_amount
          : "";
      }

      if (values?.upi_amount) {
        orderCreateData.upi_amount = values?.upi_amount
          ? values?.upi_amount
          : "";
      }

      if (values?.customer_account) {
        orderCreateData.customer_account = values && values?.customer_account ? values?.customer_account?.id : "";
      }
      orderCreateData.type = values?.type?.value;
      let response = await createOrder(orderCreateData);

      setIsLoading(true);
      if (response && response.data) {
        const orderId = response.data.orderId;
        Toast.success(response.data.message);
        if (orderId) {
          getOrderDetails(orderId);
          setCookie(Cookie.ORDER_ID, orderId);
          orderRef.current.orderId = orderId;
        }
      }
      setIsLoading(false);
    }
  };

  const onStatusChange = (value) => {
    if (value) {

      if (
        mediaLists &&
        mediaLists.length == 0 &&
        OrderDetail?.payment_type == Order.PAYMENT_UPI_VALUE
      ) {
        return Toast.error("Please Upload Payment Screenshot");
      }
      OrderService.updateStatus(orderId, { status: value }, null, (res) => {
        if (res) {
          clearCookie(Cookie.ORDER_ID);
          getOrderDetails(order_id);
          setEditable(true);
        }
      });
    }
  };

  const _handleTabChange = (tab) => {
    history.push(`?tab=${tab}`);
  };

  const bulkProductSelect = (selectedProduct) => {
    setSelectedProduct(selectedProduct);
  };

  const reasonOnChange = (e) => {
    setReasonChange(e?.target?.value)
  }

  const handleOrderCancel = () => {
    const data = new FormData();
    if (reasonChange) {
      data.append("notes", reasonChange);
    }

    OrderService.cancel(
      orderId,
      (err, res) => {
        if (err) {
          setIsReasonOpen(true);
        }
        if (res) {
          history.push(URL.ORDER_LIST);
        }
      },
      data
    );
  };

  const handleSendNotification = () => {
    const data = {
      orderId: orderId,
    };
    OrderService.sendNotification(
      orderId,
      (err, res) => {
        if (err) {
          console.log(err)
        }
      },
      data
    );
  }

  const reasonToggle = (id) => {
    setIsReasonOpen(!isReasonOpen);
    setReasonChange("")
  };

  const dropDownLinks = [
    {
      label: "Bulk Cancel",
      value: "Bulk Cancel",
    },
    {
      label: "Cancel Order",
      value: "Cancel Order",
    },
    {
      label: "Send Notification",
      value: "Send Notification",
    },
  ];

  if (showEditButton && editable && props?.match?.params?.id) {
    dropDownLinks.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleChange = async (selectedAction) => {
    if (selectedAction == "edit") {
      setEditable(false);
    }

    if (selectedAction == "Cancel Order") {
      handleOrderCancel()
    }

    if (selectedAction == "Send Notification") {
      handleSendNotification()
    }

    if (selectedAction == "Bulk Cancel") {
      setIsRender(true);

      let params = {
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        pageSize: Url.GetParam("pageSize"),
        page: Url.GetParam("page"),
        orderId: orderId
      }

      if (selectProduct) {
        let body = { orderProductList: selectProduct, orderId: orderId };
        Dispatch(
          await OrderProductService.bulkCancel(
            body,
            { orderId: orderId },
            (res) => {
              if (res) {
                Dispatch(
                  fetchList(
                    "order",
                    `${endpoints().orderProductAPI}/get`,
                    params.page ? params.page : 1,
                    params.pageSize ? params.pageSize : 25,
                    params
                  )
                );
                setIsRender(false);
                setSelectedProduct("");
                setSelectedCheckBox(false);
                setSelectedCheckBox(true);
              }
            }
          )
        );
      }
    }
  };

  const cancelledOrderBody = (
    <>
      <TextArea name="notes" label="Reason" placeholder="Enter Reason" onChange={reasonOnChange} />
    </>
  );

  const cancelledOrderFooter = (
    <div>
      <CancelButton
        onClick={() => {
          reasonToggle();
        }}
      />
      <SaveButton />
    </div>
  );

  const reasonInitialValues = {
    notes: reasonChange ? reasonChange : ""
  }


  let showCustomerSection = ArrayList.isArray(orderTypes) && orderTypes.find((data) => data?.id == Url.GetParam("type")) || false;

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <div>
        <div className="d-flex justify-content-between">
          <PageTitle
            label={
              pathName == "/order" && props?.match?.params?.id
                ? `Order #${orderNumber}`
                : "New Orders"
            }
          />
          <AddModal
            modalTitle="Reason"
            modalBody={cancelledOrderBody}
            modalFooter={cancelledOrderFooter}
            isOpen={isReasonOpen}
            toggle={reasonToggle}
            toggleModalClose={reasonToggle}
            initialValues={reasonInitialValues}
            hideDefaultButtons
            onSubmit={handleOrderCancel}
          />
          <div className="d-flex justify-content-around">
            <>
              {props?.match?.params?.id && (
                <Status
                  objectName={ObjectName.ORDER_TYPE}
                  handleChange={onStatusChange}
                  buttonLabel={OrderDetail?.statusDetail?.name}
                  currentStatusId={OrderDetail?.status}
                  disabled={editable}
                />
              )}

              <AddButton
                label={"Add Product"}
                className="mx-2 p-2"
                onClick={() => {
                  setAddProductModal(true);
                }}
              />
              {props?.match?.params?.id ? (
                ""
              ) : (
                <div style={{ maxHeight: "20px" }}>
                  <Button
                    onClick={completeOrder}
                    label="Complete Order"
                    disabled={editable}
                  />
                </div>
              )}
              <div className="ms-2">
                <Action
                  buttonLabel={""}
                  dropdownLinks={dropDownLinks}
                  handleChange={handleChange}
                />
              </div>
            </>
          </div>
        </div>
      </div>
      <div>
        <Nav tabs className="admin-tabs">
          {/* Detail Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => {
                tabToggle(Tab.SUMMARY);
                _handleTabChange(Tab.SUMMARY);
              }}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>
          <NavItem>
            {history && (
              <NavLink
                className={classNames({
                  active: activeTab === Tab.HISTORY,
                })}
                onClick={() => {
                  tabToggle(Tab.HISTORY);
                  _handleTabChange(Tab.HISTORY);
                }}
              >
                {Tab.HISTORY}
              </NavLink>
            )}
          </NavItem>
        </Nav>
      </div>

      <Form
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <TabContent activeTab={activeTab}>
          <TabPane tabId={Tab.SUMMARY} className="w-100">
            <div className="row">
              <div className="col-sm-7 col-md-7 col-lg-7 mb-0 pb-0">
                <GeneralTab
                  paymentType={paymentType}
                  selectedDate={orderDate}
                  orderDateFieldOnchange={orderDateFieldOnchange}
                  storeFieldOnchange={storeFieldOnchange}
                  storeOption={location}
                  orderId={orderId}
                  handleShiftChange={handleShiftChange}
                  onChangeAssignee={onChangeAssignee}
                  onChangePaymentValue={onChangePaymentValue}
                  history={history}
                  customerList={customerList}
                  pathName={pathName}
                  onChangeSalesCustomer={onChangeSalesCustomer}
                  handleCashAmount={handleCashAmount}
                  handleUpiAmount={handleUpiAmount}
                  editable={editable}
                  showUserDetailsPageLink={
                    OrderDetail?.owner ? true : false
                  }
                  showCustomerDetailsPageLink={
                    OrderDetail?.customer_account ? true : false
                  }
                  customerId={OrderDetail?.customer_account}
                  shiftId={OrderDetail?.shift}
                  setShiftOption={setShiftList}
                  locationId={OrderDetail?.location?.id}
                  setLocationList={setStore}
                  showSaveButton={orderId ? !editable : true}
                  onChangeOwner={onChangeOwner}
                  ownerId={owner ? owner : OrderDetail?.owner}
                  userList={usersList}
                  showCustomerSelection={OrderDetail?.orderTypeDetail && OrderDetail?.orderTypeDetail?.show_customer_selection == OrderType.ENABLE_CUSTOMER_SELECTION ? true : OrderDetail?.orderTypeDetail && OrderDetail?.orderTypeDetail?.show_customer_selection == OrderType.DISABLE_CUSTOMER_SELECTION ? false : showCustomerSection && showCustomerSection?.show_customer_selection}
                  orderTypes={orderTypes}
                  onChangeTypeValue={onChangeTypeValue}
                  deliveryDateFieldOnchange={deliveryDateFieldOnchange}
                  selectedDeliveryDate={deliveryDate}
                />
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5 pb-0 mb-0">
                <MediaCarousel
                  showCarasoul
                  objectName={ObjectName.ORDER}
                  objectId={order_id}
                  Attachments={"Attachments"}
                  editable={editable}
                  showNoRecordFound
                  setMediaList={setMediaList}
                  height={editable === true ? "200px" : "150px"}
                  className="carousel"
                />
              </div>
            </div>
            <ProductTab
              id="order"
              history={history}
              params={{ orderId: order_id, showTotalAmount: true }}
              apiURL={`${endpoints().orderProductAPI}/get`}
              orderId={order_id}
              showStatusColumn
              showBulkSelect={true}
              bulkSelect={bulkProductSelect}
              isRender={isRender}
              editable={editable}
              setEditable={setEditable}
              selectedCheckBox={selectedCheckBox}
            />
          </TabPane>
          {activeTab == Tab.HISTORY && order_id && (
            <TabPane tabId={Tab.HISTORY} className="w-100">
              <ActivityList
                id={order_id}
                objectId={order_id}
                object_name={ObjectName.ORDER}
                history={history}
              />
            </TabPane>
          )}
        </TabContent>
      </Form>
      <ProductSelectModal
        modalOpen={modelOpen}
        toggle={toggleOpen}
        toggleModalClose={addProductToggle}
        handleSubmit={handleSubmit}
        BulkSelect={multiselect}
        params={{ store_id: storeId }}
      />
    </div>
  );
};

export default AddProducts;
