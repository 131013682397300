export const Setting = {
  ALLOW_REPLENISHMENT: "allow_replenishment",
  ALLOW_DUPLICATE_INVOICE_NUMBER:"allow_duplicate_invoice_number",
  USER_ROLE_ALLOW_MULTIPLE_CHECKIN : "user_role_allow_multiple_checkin",
  ATTENDANCE_MISSION_REPORT_EMAIL: "attendance_missing_report_email",
  ATTENDANCE_MISSION_REPORT_EMAIL: "attendance_missing_report_email",
  ACCOUNT_MESSAGE_SLACK_CHANNEL: "account_message_slack_channel",
  ATTENDENCE_NOTIFICATION_SLACK_CHANNEL: "attendence_notification_slack_channel",
  ACTIVE_MOBILE_VERSION: 'active_mobile_versions',
  AUTO_LOGOUT_HOURS: "auto_logout_hours",
  ATTENDANCE_LATE_HOURS_FINE_TYPE: "attendance_late_hours_fine_type",
  ATTENDANCE_EARLY_HOURS_BONUS_TYPE: "attendance_early_hours_bonus_type",
  ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE: "attendance_early_check_out_fine_type",
  BILLS: "Bills",
  BACKGROUND_IMAGE: "background_image",
  BONUS_ADD_FOR_ATTENDANCE_EARLY_CHECKIN:"bonus_add_for_attendance_early_checkin",
  BONUS_ADD_FOR_EXTRA_STOCK_ENTRY:"bonus_add_for_extra_stock_entry",
  BONUS_ADD_FOR_EXTRA_REPLENISHMENT:"bonus_add_for_extra_replenishment",
  BACKGROUND_IMAGE_URL: "background_image",
  COMPANY_WISE :"Company Wise",
  DISTRIBUTION_CENTER: "distribution_center",
  DEVICE_APPROVAL_REQUIRED: 'device_approval_required',
  ENABLE_OTP_LOGIN:"enable_otp_login",
  EXCESS_TRANSFER_TYPE: "excess_transfer_type",
  FORCE_CHECK_IN_AFTER_LOGIN: "force_check_in_after_login",
  FORCE_LOGOUT_AFTER_CHECKOUT: "force_logout_after_checkout",
  FINE_ADD_FOR_ATTENDANCE_LATE_CHECKIN:"fine_add_for_attendance_late_checkin",
  FINE_ADD_FOR_STOCK_ENTRY:"fine_add_for_stock_entry",
  FINE_ADD_FOR_REPLENISHMENT_MISSING:"fine_add_for_replenishment_missing",
  FIND_AN_EXPERT_FORM: "find_an_expert_Form",
  FINE_ADD_FOR_EARLY_ATTENDANCE_CHECK_OUT:"fine_add_for_early_attendance_check_out",
  FROM_EMAIL: "from_email",
  FROM_EMAIL_DISPLAY_NAME: "from_email_display_name",
  INVOICE_LINE_ITEM_AMOUNT_INCLUDES_TAX:"invoice_line_item_amount_includes_tax",
  LOCATION_CHANGE_NOTIFICATION_EMAIL: "location_change_notification_email",
  LOGIN_PAGE_BACKGROUND_IMAGE: "login_page_background_image",
  LOCATION_CASH_AMOUNT_MISSING_FINE_TYPE: "location_cash_amount_missing_fine_type",
  LOCATION_WISE :"Location Wise",
  MISSING_TRANSFER_TYPE: "missing_transfer_type",
  STOCK_ENTRY_MINIMUM_COUNT: "stock_entry_minimum_count",
  MOBILE_APP_VERSION_NUMBER: "mobile_app_version_number",
  MESSAGE_BACKGROUND_FETCH_INTERVAL: "message_background_fetch_interval",
  MACHED_STATUS:"matched_status",
  NOT_MACHED_STATUS:"not_matched_status",
  ORDER_CASH_AMOUNT_MISSING_FINE_TYPE:"order_cash_amount_missing_fine_type",
  ORDER_UPI_AMOUNT_MISSING_FINE_TYPE:"order_upi_amount_missing_fine_type",
  ORDER_CANCEL_GRACE_TIME:"order_cancel_grace_time",
  ONLINE_SALE_COMPANY : "online_sale_company",
  ONLINE_SALE_USER_ROLE : 'online_sale_user_role',
  COLLECT_CUSTOMER_INFO :"collect_customer_info",
  ORDER_CODE :"order_code",
  ORDER_MANUAL_PRICE_UPDATE:"order_manual_price_update",
  PRODUCT_TAG:'product_tag',
  REPLENISHMENT_MISSING_FINE_TYPE: "replenishment_missing_fine_type",
  REPLENISHMENT_EXTRA_BONUS_TYPE: "replenishment_extra_bonus_type",
  REPORTED_TICKETS_MINIMUM_STORY_POINTS:"reported_tickets_minimum_story_points",
  RECEIVED_CASH_AMOUNT_MISSING_FINE_TYPE:"received_cash_amount_missing_fine_type",
  RECEIVED_UPI_AMOUNT_MISSING_FINE_TYPE:"received_upi_amount_missing_fine_type",
  ROLE_ALLOWED_SHIFT:"role_allowed_shift",
  ROLE_ALLOWED_LOCATIONS:"role_allowed_locations",
  REPLENISHMENT_BY: "replenishment_by",
  REQUIRE_VERIFICATION_ON_LOCATION_CHANGE:"require_verification_on_location_change",
  SETTING_VALIDATE_TIMESHEET_ON_ATTENDANCE_CHECKOUT: "validate_timesheet_on_attendance_checkout",
  SETTING_VALIDATE_PENDING_CHECKOUT_ON_ATTENDANCE_CHECKIN: "validate_pending_checkout_on_attendance_checkin",
  SETTING_MINIMUM_STORY_POINTS: "minimum_story_points",
  SETTING_SLACK_ACCESS_TOKEN: "slack_access_token",
  SETTING_VISITOR_NOTIFICATION_CHANNEL: "visitor_notification_channel",
  SETTING_ATTENDANCE_NOTIFICATION_CHANNEL: "attendance_notification_channel",
  SETTING_WHATSAPP_ACCESS_TOKEN: "whatsapp_access_token",
  SETTING_LOGIN_WHATSAPP_NOTIFICATION_NUMBER: "login_notication_whatsapp_mobile_number",
  SETTING_VALIDATE_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT: "validate_sales_settlement_on_attendance_checkout",
  SETTING_VALIDATE_PENDING_TICKET_ON_ATTENDANCE_CHECKOUT: "validate_pending_ticket_on_attendance_checkout",
  SALE_SETTLEMENT_MISSING_TICKET_TYPE:"sales_settlement_missing_ticket_type",
  SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE:"sales_settlement_enquiry_ticket_type",
  SYSTEM_USER: "system_user",
  STOCK_ENTRY_MISSING_FINE_TYPE: "stock_entry_missing_fine_type",
  STOCK_ENTRY_EXTRA_BONUS_TYPE: "stock_entry_extra_bonus_type",
  SETTING_ORDER_NUMBER_GENERATION: "order_number_generation",
  SETTING_LAST_ORDER_NUMBER: "last_order_number",
  SETTING_ALLOWED_DRAFT_ORDERS : "allowed_draft_orders",
  SETTING_ATTENDANCE_LEAVE_NOTIFICATION_CHANNEL: "attendance_leave_notification_channel",
  SETTING_ATTENDANCE_ADDITIONAL_DAY_NOTIFICATION_CHANNEL: "attendance_additional_day_notification_channel",
  SETTINGS_PORTAL_HEADER_COLOR: "portal_header_color",
  SETTINGS_PORTAL_HEADER_TEXT_COLOR: "portal_header_text_color",
  SETTINGS_PORTAL_FOOTER_COLOR: "portal_footer_color",
  SETTINGS_PORTAL_FOOTER_HEADING_COLOR:
    "portal_footer_heading_color",
  SETTINGS_PORTAL_FOOTER_TEXT_COLOR: "portal_footer_text_color",
  SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT:
    "portal_footer_copy_rights_text",
  SETTINGS_PORTAL_DESKTOP_FOOTER_CONTENT:
    "portal_desktop_footer_content",
  SETTINGS_PORTAL_MOBILE_FOOTER_CONTENT:
    "portal_mobile_footer_content",

  SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_COLOR:
    "portal_left_navigation_text_color",
  SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_HOVER_OVER_COLOR:
    "portal_left_navigation_text_hover_over_color",
  SETTINGS_LEGAL_DISCLOSURE: "legal_disclosure",
  TERMS_OF_SERVICE: "termsOfService",
  SETTINGS_TERMS_OF_SERVICE_URL: "terms_of_service_url",
  SETTINGS_LOGIN_PAGE_BACKGROUND_IMAGE:
    "login_page_background_image",
  SETTING_REPLENISH_TRANSFER_TYPE: "replenish_transfer_type",
  SETTINGS_PORTAL_FAV_ICON: "portal_favicon_image",
  SETTINGS_PORTAL_LOGO: "portal_logo_image",
  SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE:
    "portal_left_navigation_background_image",
  SETTING_PORTAL_FAVICON_URL: "portal_favicon_url",
  SETTING_PORTAL_LOGO_MEDIA_URL: "portal_logo_media_url",
  SETTINGS_PORTAL_LOGO_MEDIA_ID: "portal_logo_media_id",
  SETTING_LOGIN_BACKGROUND_IMAGE_URL:
    "portal_login_background_image_url",
  SETTING_PORTAL_NAV_BACKGROUNG_IMAGE_URL:
    "portal_left_navigation_background_image_url",
  SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_COLOR: "portal_left_navigation_background_color",
  SALE_REPORT_MISSING_NOTIFICATION_SLACK_CHANNEL: "sale_report_missing_notification_slack_channel",
  SHOW_STORE_SELECTION_ON_LOGIN: "show_store_selection_on_login",
  TO_EMAIL: "to_email",
  TECHNICAL_SUPPORT_EMAIL: "technical_support_email",
  USER_DEFAULT_TIME_ZONE: "user_default_time_zone",
  USER_LOGIN_TIME:"user_login_time",
  USER_WORKING_DAYS:"user_working_days",
  USER_SHIFT:"user_shift",
  UPDATE_ACCOUNT_PRODUCT: "update_account_product",
 UNMUTE_PHONE_SOUND :"unmute_phone_sound",
  UPDATE_PRODUCT_PRICE_STATUS:"update_product_price_status",
  USER_REPLENISHMENT_GOAL_PRODUCT_COUNT:"user_replenishment_goal_product_count",
  VALIDATE_SHIFT_TIME_ON_SALE_SETTLEMENT_ADD: "validate_shift_time_on_sale_settlement_add",
  VALIDATE_PENDING_FINES_ON_ATTENDANCE_CHECKOUT: "validate_pending_fines_on_attendance_checkout",
  VALIDATE_PENDING_ORDERS_ON_ATTENDANCE_CHECKOUT: "validate_pending_orders_on_attendance_checkout",
  VALIDATE_PENDING_TRANSFER_ON_ATTENDANCE_CHECKOUT: "validate_pending_transfer_on_attendance_checkout",
  VALIDATE_PENDING_PAYMENTS_ON_ATTENDANCE_CHECKOUT: "validate_pending_payments_on_attendance_checkout",
  VALIDATE_PENDING_PURCHASES_ON_ATTENDANCE_CHECKOUT: "validate_pending_purchases_on_attendance_checkout",
  VALIDATE_PENDING_BILLS_ON_ATTENDANCE_CHECKOUT: "validate_pending_bills_on_attendance_checkout",
  STOCK_ENTRY_REQUIRED: "stock_entry_required",
  VALIDATE_SHIFT_TIME_ON_CHECKOUT: "validate_shift_time_on_checkout",
  VALIDATE_MINIMUM_REPLENISH_PRODUCTS_ON_CHECKOUT: "validate_minimum_replenish_products_on_checkout",
  VALIDATE_SHIFT_HOURS_ON_CHECKOUT: "validate_shift_hours_on_checkout",
  VALIDATE_TICKET_MINIMUM_STORY_POINTS_ON_CHECKOUT: "validate_ticket_minimum_story_points_on_checkout",
  VALIDATE_PENDING_ACTIVITIES_ON_ATTENDANCE_CHECKOUT:"validate_pending_activities_on_attendance_checkout",
  VALIDATE_LATE_CHECKIN_ON_CHECKOUT:"validate_late_checkin_on_checkout",
  VALIDATE_REPORTED_TICKETS_MINIMUM_STORY_POINTS_ONCHECKOUT:"validate_reported_tickets_minimum_story_points_oncheckout",
  VALIDATE_PENDING_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT:"validate_pending_sales_settlement_on_attendance_checkout",
  VALIDATE_IP_ADDRESS_ON_CHECKIN:"validate_ip_address_on_checkin",
  VALIDATE_IP_ADDRESS_ON_CHECKOUT:"validate_ip_address_on_checkout",
  VISITOR_TYPE :  "visitor_type",
  VISITOR_SHOW_TITLE : "visitor_show_title",
  DRAFT_ORDER_AMOUNT_MISSING_FINE_TYPE:"draft_order_amount_missing_fine_type",
  ALLOW_ADDITIONAL_LEAVE_ADD: "allow_additional_leave_add",
}


// status Tabs
export const Tabs = {
  STATUS: "Status",
  TYPES: "Types",
  CUSTOMFIELDS:"Custom Fields",
  SETTING:"Settings",
  DEVICE:"Device",
  GENERAL:"General",
  ORDERTYPE:"Types",
  RATING:"Rating"

}