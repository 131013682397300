import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, TabContent, TabPane } from "reactstrap";

// Components
import BreadCrumb from "../../../components/Breadcrumb";
import DeleteModal from "../../../components/DeleteModal";
import Form from "../../../components/Form";
import NumberComponent from "../../../components/Number";
import PageTitle from "../../../components/PageTitle";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Tab from "../../../components/Tab";
import Text from "../../../components/Text";
import UserSelect from "../../../components/UserSelect";
import Spinner from "../../../components/Spinner";
import StoryPointSelect from "../../../components/StoryPointSelect";
import TicketType from "../../../components/TicketType";
import AddButton from "../../../components/AddButton";
import Action from "../../../components/Action";
import StatusTab from "./statusTab";

// Helpers
import { Projects } from "../../../helpers/Project";
import { 
  TICKET_FIELD_ACCEPTANCE_CRITERIA, 
  TICKET_FIELD_ACTUAL_RESULTS, 
  TICKET_FIELD_AFFECTED_VERSION, 
  TICKET_FIELD_APPLICABLE_DEVICES, 
  TICKET_FIELD_ASSIGNEE, 
  TICKET_FIELD_ATTACHMENT_IMAGE, 
  TICKET_FIELD_ATTACHMENT_PAGE_NAME, 
  TICKET_FIELD_ATTACHMENT_PLATFORM, 
  TICKET_FIELD_ATTACHMENT_SUMMARY,
  TICKET_FIELD_BUILD, 
  TICKET_FIELD_COMMENT, 
  TICKET_FIELD_COMPLETED_AT, 
  TICKET_FIELD_COMPONENT, 
  TICKET_FIELD_CREATED_AT, 
  TICKET_FIELD_CUSTOMER_DELIVERY_DATE, 
  TICKET_FIELD_DATE, 
  TICKET_FIELD_DELIVERY_DATE, 
  TICKET_FIELD_DESCRIPTION, 
  TICKET_FIELD_DEVELOPER, 
  TICKET_FIELD_ENVIRONMENTS, 
  TICKET_FIELD_ESTIMATED_HOURS, 
  TICKET_FIELD_DUE_DATE, 
  TICKET_FIELD_DUE_DATE_TIME, 
  TICKET_FIELD_EXPECTED_RESULTS, 
  TICKET_FIELD_FROM_LOCATION, 
  TICKET_FIELD_HISTORY, 
  TICKET_FIELD_INITIAL_DUE_DATE, 
  TICKET_FIELD_JIRA_ASSIGNEE, 
  TICKET_FIELD_JIRA_CREATED_AT, 
  TICKET_FIELD_JIRA_TICKET_ID, 
  TICKET_FIELD_LABEL, 
  TICKET_FIELD_PARENT_TICKET_ID, 
  TICKET_FIELD_POA, 
  TICKET_FIELD_PRIORITY, 
  TICKET_FIELD_PRODUCTION_STATUS, 
  TICKET_FIELD_PROJECT, 
  TICKET_FIELD_REFERENCE_SCREENSHOTS, 
  TICKET_FIELD_RELEASE_VERSION, 
  TICKET_FIELD_REPORTER, 
  TICKET_FIELD_REVIEWER, 
  TICKET_FIELD_SEVERITY, 
  TICKET_FIELD_SPRINT, 
  TICKET_FIELD_STATUS, 
  TICKET_FIELD_STORY_POINTS, 
  TICKET_FIELD_SUB_TASKS, 
  TICKET_FIELD_SUMMARY, 
  TICKET_FIELD_TASK, 
  TICKET_FIELD_TEST, 
  TICKET_FIELD_TESTER, 
  TICKET_FIELD_TEST_CASES, 
  TICKET_FIELD_TEST_CASE_CREATION_TIME, 
  TICKET_FIELD_TEST_CASE_EXECUTION_TIME, 
  TICKET_FIELD_TEST_STEPS, 
  TICKET_FIELD_TICKET_ID, 
  TICKET_FIELD_TO_LOCATION, 
  TICKET_FIELD_TYPE, 
  TICKET_FIELD_UPDATED_AT, 
  TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID, 
  TICKET_FIELD_USER_IMPACT } from "../../../helpers/ProjectTicketType";

// Services
import ProjectTicketTypeService from "../../../services/ProjectTicketTypeService";

// Lib
import Url from "../../../lib/Url";
import TagSelect from "../../../components/TagSelect";
import { TagTypeName } from "../../../helpers/Tag";
import ArrayList from "../../../lib/ArrayList";

const Tabs = {
  GENERAL: "General",
  FIELD: "Field",
  STATUS: "Status",
};

const TicketTypeDetail = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.GENERAL);
  const [projectTicketTypeData, setProjectTicketTypeData] = useState();
  const [userList, setUserList] = useState();
  const [statusValue, setStatusValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defaultStoryPoint, setDefauleStory] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [subTaskTicketIds, setSubTaskTicketIds] = useState(null);
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [row, setRow] = useState("");
  const [fineTypeList, setFineTypeList] = useState([]);

  let dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getData();
    getTicketTypeList();
  }, []);

  const { projectData } = props;
  const statusOptions = [
    {
      label: Projects.STATUS_ACTIVE,
      value: Projects.STATUS_ACTIVE_VALUE,
    },
    {
      label: Projects.STATUS_INACTIVE,
      value: Projects.STATUS_INACTIVE_VALUE,
    },
  ];

  const getData = async () => {
    const id = props?.match?.params?.typeid;
    const response = await ProjectTicketTypeService.getProjectTicketType(id);
    setProjectTicketTypeData(response);
  };

  const updateData = (values) => {
    const id = props.match.params.typeid;
    (values.status = statusValue?.value),
     
      (values.default_story_point = values?.story_points?.value
        ? values?.story_points?.value
        : null),
      (values.sub_task_ticket_types = subTaskTicketIds);
    ProjectTicketTypeService.updateProjectTicketType(id, values, (res) => {
      if (res) {
        setIsLoading(true);
        getData();
        setIsLoading(false);
      }
    });
  };

  const deleteTicketType = () => {
    const id = props.match.params.typeid;
    dispatch(
      ProjectTicketTypeService.deleteProjectTicketType(id, {
        project_id: props.match.params.id,
      })
    );

    props.history.push(
      `/project/${props.match.params.id}?projectId=${Url.GetParam("projectId")}`
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/dashboard" },
    {
      label: "Project",
      link: `/project`,
    },
    {
      label: "Project Details",
      link: `/project/${props.match.params.id}?projectId=${Url.GetParam(
        "projectId"
      )}`,
    },
    {
      label: "TicketType Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
   
  ];

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
  };

  const handleStatusChange = (values) => {
    setStatusValue(values?.values?.status);
  };

  const getTicketTypeList = async () => {
    const data = await ProjectTicketTypeService.list();
    setTypeList(data);
  };

  const initialValues = {
    ...projectTicketTypeData,

    status: statusValue
      ? {
          value: statusValue?.value,
          label: statusValue?.label,
        }
      : statusOptions &&
        statusOptions.find(
          (status) => status.value == projectTicketTypeData?.status
        ),
   
    story_points: defaultStoryPoint
      ? defaultStoryPoint
      : projectTicketTypeData
      ? {
          value: projectTicketTypeData?.default_story_point,
          label: projectTicketTypeData?.default_story_point,
        }
      : "",
    sub_task_ticket_types: subTaskTicketIds
      ? typeList.filter((item) => subTaskTicketIds?.includes(item.value))
      : typeList.filter((item) =>
          projectTicketTypeData?.sub_task_ticket_types
            ?.split(",")
            .map(Number)
            .includes(item?.value)
        ),
        fine_type: ArrayList.isArray(fineTypeList) && fineTypeList.find((data)=> data?.value == projectTicketTypeData?.fine_type)
  };


  const handleStoryChange = (e) => {
    setDefauleStory(e.values.story_points);
  };

  const handleTicketTypeChange = ({ values }) => {
    let ids =
      values &&
      values?.sub_task_ticket_types &&
      values?.sub_task_ticket_types?.map((data) => data?.value);
    setSubTaskTicketIds(ids);
  };

  const _toggle = () => {
    setStatusIsOpen(!statusIsOpen);
  };

  const handleCheckBoxValue = async (values) => {
    let objectKeys = Object.keys(values);
    let objectValues = Object.values(values);

    let data = new FormData();

    let arrayValue = [];
    for (let i = 0; i < objectValues.length; i++) {
      const value = objectValues[i];
      if (value == true) {
        arrayValue.push(objectKeys[i]);
      }
    }
    data.append("checkBoxValue", arrayValue);

    dispatch(
      await ProjectTicketTypeService.updateProjectTicketType(
        props?.match?.params?.typeid,
        data,
        (res) => {}
      )
    );
  };

  let fieldValue =
    projectTicketTypeData &&
    projectTicketTypeData?.field &&
    projectTicketTypeData?.field?.split(",");

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <PageTitle label="Ticket Type details " />
        <div className="d-flex">
          {activeTab == Tabs.STATUS && (
            <AddButton
              label={"Add New"}
              className="me-2 mt-1"
              onClick={() => {
                _toggle();
                setRow("");
              }}
            />
          )}
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}  
          />
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Ticket Type"
        label={projectTicketTypeData?.name}
        deleteFunction={() => {
          deleteTicketType();
        }}
      />
      <Nav tabs className="admin-tabs">
        <Tab
          name={Tabs.GENERAL}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.FIELD}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.STATUS}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* General Tab */}
        <TabPane tabId={Tabs.GENERAL}>
          <div className="card">
            <div className="col-12 col-sm-8 col-md-8 col-lg-6 p-3">
              <div className="field-wrapper mb-0 form-wrapper">
                <Form
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    updateData(values);
                  }}
                >
                  <Text name="name" label="Name" placeholder="Enter Name" />

                  <Select
                    name="status"
                    label="Status"
                    options={statusOptions}
                    onInputChange={handleStatusChange}
                  />
                  <NumberComponent label="Sort" name="sort" fontBolded />

                
                  <StoryPointSelect
                    name="story_points"
                    label="Story Points"
                    placeholder="Select Story Points"
                    onChange={handleStoryChange}
                  />
                  <TicketType
                    name="sub_task_ticket_types"
                    label="Sub Task Ticket Type"
                    projectId={Url.GetParam("projectId")}
                    isMulti
                    handleTicketTypeChange={handleTicketTypeChange}
                  />
                    <TagSelect  
                    name={"fine_type"}
                    label="Fine Type"
                    params={{ type: TagTypeName.FINE_TYPE }}
                    TagList={setFineTypeList}
                    />
                  <br />

                  <div className="mt-2">
                    <SaveButton />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        {/* General Tab */}
        <TabPane tabId={Tabs.FIELD}>
          <Form
          enableReinitialize
            initialValues={{
              [TICKET_FIELD_TEST]: fieldValue && fieldValue.includes(TICKET_FIELD_TEST.toString()),
              [TICKET_FIELD_TASK]: fieldValue && fieldValue.includes(TICKET_FIELD_TASK.toString()),
              [TICKET_FIELD_POA]: fieldValue && fieldValue.includes(TICKET_FIELD_POA.toString()),
              [TICKET_FIELD_TEST_CASE_CREATION_TIME]: fieldValue && fieldValue.includes(TICKET_FIELD_TEST_CASE_CREATION_TIME.toString()),
              [TICKET_FIELD_TEST_CASE_EXECUTION_TIME]: fieldValue && fieldValue.includes(TICKET_FIELD_TEST_CASE_EXECUTION_TIME.toString()),
              [TICKET_FIELD_PROJECT]: fieldValue && fieldValue.includes(TICKET_FIELD_PROJECT.toString()),
              [TICKET_FIELD_TYPE]: fieldValue && fieldValue.includes(TICKET_FIELD_TYPE.toString()),
              [TICKET_FIELD_STATUS]: fieldValue && fieldValue.includes(TICKET_FIELD_STATUS.toString()),
              [TICKET_FIELD_SEVERITY]: fieldValue && fieldValue.includes(TICKET_FIELD_SEVERITY.toString()),
              [TICKET_FIELD_COMPONENT]: fieldValue && fieldValue.includes(TICKET_FIELD_COMPONENT.toString()),
              [TICKET_FIELD_LABEL]: fieldValue && fieldValue.includes(TICKET_FIELD_LABEL.toString()),
              [TICKET_FIELD_AFFECTED_VERSION]: fieldValue && fieldValue.includes(TICKET_FIELD_AFFECTED_VERSION.toString()),
              [TICKET_FIELD_PRIORITY]: fieldValue && fieldValue.includes(TICKET_FIELD_PRIORITY.toString()),
              [TICKET_FIELD_RELEASE_VERSION]: fieldValue && fieldValue.includes(TICKET_FIELD_RELEASE_VERSION.toString()),
              [TICKET_FIELD_SPRINT]: fieldValue && fieldValue.includes(TICKET_FIELD_SPRINT.toString()),
              [TICKET_FIELD_DESCRIPTION]: fieldValue && fieldValue.includes(TICKET_FIELD_DESCRIPTION.toString()),
              [TICKET_FIELD_USER_IMPACT]: fieldValue && fieldValue.includes(TICKET_FIELD_USER_IMPACT.toString()),
              [TICKET_FIELD_PRODUCTION_STATUS]: fieldValue && fieldValue.includes(TICKET_FIELD_PRODUCTION_STATUS.toString()),
              [TICKET_FIELD_ACCEPTANCE_CRITERIA]: fieldValue && fieldValue.includes(TICKET_FIELD_ACCEPTANCE_CRITERIA.toString()),
              [TICKET_FIELD_APPLICABLE_DEVICES]: fieldValue && fieldValue.includes(TICKET_FIELD_APPLICABLE_DEVICES.toString()),
              [TICKET_FIELD_ENVIRONMENTS]: fieldValue && fieldValue.includes(TICKET_FIELD_ENVIRONMENTS.toString()),
              [TICKET_FIELD_BUILD]: fieldValue && fieldValue.includes(TICKET_FIELD_BUILD.toString()),
              [TICKET_FIELD_TEST_STEPS]: fieldValue && fieldValue.includes(TICKET_FIELD_TEST_STEPS.toString()),
              [TICKET_FIELD_ACTUAL_RESULTS]: fieldValue && fieldValue.includes(TICKET_FIELD_ACTUAL_RESULTS.toString()),
              [TICKET_FIELD_EXPECTED_RESULTS]: fieldValue && fieldValue.includes(TICKET_FIELD_EXPECTED_RESULTS.toString()),
              [TICKET_FIELD_REFERENCE_SCREENSHOTS]: fieldValue && fieldValue.includes(TICKET_FIELD_REFERENCE_SCREENSHOTS.toString()),
              [TICKET_FIELD_SUB_TASKS]: fieldValue && fieldValue.includes(TICKET_FIELD_SUB_TASKS.toString()),
              [TICKET_FIELD_REPORTER]: fieldValue && fieldValue.includes(TICKET_FIELD_REPORTER.toString()),
              [TICKET_FIELD_ASSIGNEE]: fieldValue && fieldValue.includes(TICKET_FIELD_ASSIGNEE.toString()),
              [TICKET_FIELD_REVIEWER]: fieldValue && fieldValue.includes(TICKET_FIELD_REVIEWER.toString()),
              [TICKET_FIELD_DUE_DATE]: fieldValue && fieldValue.includes(TICKET_FIELD_DUE_DATE.toString()),
              [TICKET_FIELD_DUE_DATE_TIME]: fieldValue && fieldValue.includes(TICKET_FIELD_DUE_DATE_TIME.toString()),
              [TICKET_FIELD_INITIAL_DUE_DATE]: fieldValue && fieldValue.includes(TICKET_FIELD_INITIAL_DUE_DATE.toString()),
              [TICKET_FIELD_CREATED_AT]: fieldValue && fieldValue.includes(TICKET_FIELD_CREATED_AT.toString()),
              [TICKET_FIELD_UPDATED_AT]: fieldValue && fieldValue.includes(TICKET_FIELD_UPDATED_AT.toString()),
              [TICKET_FIELD_JIRA_CREATED_AT]: fieldValue && fieldValue.includes(TICKET_FIELD_JIRA_CREATED_AT.toString()),
              [TICKET_FIELD_COMPLETED_AT]: fieldValue && fieldValue.includes(TICKET_FIELD_COMPLETED_AT.toString()),
              [TICKET_FIELD_ESTIMATED_HOURS]: fieldValue && fieldValue.includes(TICKET_FIELD_ESTIMATED_HOURS.toString()),
              [TICKET_FIELD_STORY_POINTS]: fieldValue && fieldValue.includes(TICKET_FIELD_STORY_POINTS.toString()),
              [TICKET_FIELD_DELIVERY_DATE]: fieldValue && fieldValue.includes(TICKET_FIELD_DELIVERY_DATE.toString()),
              [TICKET_FIELD_CUSTOMER_DELIVERY_DATE]: fieldValue && fieldValue.includes(TICKET_FIELD_CUSTOMER_DELIVERY_DATE.toString()),
              [TICKET_FIELD_TICKET_ID]: fieldValue && fieldValue.includes(TICKET_FIELD_TICKET_ID.toString()),
              [TICKET_FIELD_PARENT_TICKET_ID]: fieldValue && fieldValue.includes(TICKET_FIELD_PARENT_TICKET_ID.toString()),
              [TICKET_FIELD_JIRA_TICKET_ID]: fieldValue && fieldValue.includes(TICKET_FIELD_JIRA_TICKET_ID.toString()),
              [TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID]: fieldValue && fieldValue.includes(TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID.toString()),
              [TICKET_FIELD_JIRA_ASSIGNEE]: fieldValue && fieldValue.includes(TICKET_FIELD_JIRA_ASSIGNEE.toString()),
              [TICKET_FIELD_ATTACHMENT_PAGE_NAME]: fieldValue && fieldValue.includes(TICKET_FIELD_ATTACHMENT_PAGE_NAME.toString()),
              [TICKET_FIELD_ATTACHMENT_PLATFORM]: fieldValue && fieldValue.includes(TICKET_FIELD_ATTACHMENT_PLATFORM.toString()),
              [TICKET_FIELD_ATTACHMENT_SUMMARY]: fieldValue && fieldValue.includes(TICKET_FIELD_ATTACHMENT_SUMMARY.toString()),
              [TICKET_FIELD_SUMMARY]: fieldValue && fieldValue.includes(TICKET_FIELD_SUMMARY.toString()),
              [TICKET_FIELD_ATTACHMENT_IMAGE]: fieldValue && fieldValue.includes(TICKET_FIELD_ATTACHMENT_IMAGE.toString()),
              [TICKET_FIELD_DEVELOPER]: fieldValue && fieldValue.includes(TICKET_FIELD_DEVELOPER.toString()),
              [TICKET_FIELD_TESTER]: fieldValue && fieldValue.includes(TICKET_FIELD_TESTER.toString()),
              [TICKET_FIELD_DATE]: fieldValue && fieldValue.includes(TICKET_FIELD_DATE.toString()),
              [TICKET_FIELD_COMMENT]: fieldValue && fieldValue.includes(TICKET_FIELD_COMMENT.toString()),
              [TICKET_FIELD_TEST_CASES]: fieldValue && fieldValue.includes(TICKET_FIELD_TEST_CASES.toString()),
              [TICKET_FIELD_HISTORY]: fieldValue && fieldValue.includes(TICKET_FIELD_HISTORY.toString()),
              [TICKET_FIELD_FROM_LOCATION]: fieldValue && fieldValue.includes(TICKET_FIELD_FROM_LOCATION.toString()),
              [TICKET_FIELD_TO_LOCATION]: fieldValue && fieldValue.includes(TICKET_FIELD_TO_LOCATION.toString()),

            }}
          >
            <h5 className="my-3">Ticket Details Tabs Settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_COMMENT}
              label={"Comments"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_SUB_TASKS}
              label={"Sub Tasks"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TEST}
              label={"Tests"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TEST_CASES}
              label={"Test Cases"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_HISTORY}
              label={"History"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TASK}
              label={"Tasks"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_POA}
              label={"POA"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TEST_CASE_CREATION_TIME}
              label={"Test Case Creation TIme"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TEST_CASE_EXECUTION_TIME}
              label={"Test Case EXecution time"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_FROM_LOCATION}
              label={"From Location"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TO_LOCATION}
              label={"To Location"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">Ticket Details Settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_PROJECT}
              label={"Project"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TYPE}
              label={"Type"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_STATUS}
              label={"Status"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_SEVERITY}
              label={"Severity"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_COMPONENT}
              label={"Component"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_LABEL}
              label={"Label"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_AFFECTED_VERSION}
              label={"Affected Version"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />{" "}
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_PRIORITY}
              label={"Priority"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_RELEASE_VERSION}
              label={"Release Version"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_SPRINT}
              label={"Sprint"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">Ticket Detail Fields</h5>
            <SingleCheckbox
              name={TICKET_FIELD_DESCRIPTION}
              label={"Description"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_USER_IMPACT}
              label={"User Impact"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_PRODUCTION_STATUS}
              label={"Production Status"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_ACCEPTANCE_CRITERIA}
              label={"Acceptence Criteria"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />{" "}
            <SingleCheckbox
              name={TICKET_FIELD_APPLICABLE_DEVICES}
              label={"Applicable Devices"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />{" "}
            <SingleCheckbox
              name={TICKET_FIELD_ENVIRONMENTS}
              label={"Environment"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />{" "}
            <SingleCheckbox
              name={TICKET_FIELD_BUILD}
              label={"Build"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TEST_STEPS}
              label={"Test steps"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_ACTUAL_RESULTS}
              label={"Actual results"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_EXPECTED_RESULTS}
              label={"Expected Results"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_REFERENCE_SCREENSHOTS}
              label={"Reference Screenshots"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_SUMMARY}
              label={"Summary"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">TIcket Details People Settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_REPORTER}
              label={"Reporter"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_ASSIGNEE}
              label={"Assignee"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_REVIEWER}
              label={"Reviewer"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_DEVELOPER}
              label={"Developer"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_TESTER}
              label={"Tester"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">TIcket Details Due Date settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_DUE_DATE}
              label={"Due Date"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_DUE_DATE_TIME}
              label={"Due Date Time"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_INITIAL_DUE_DATE}
              label={"Initial Due Date"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">Ticket Details Dates settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_CREATED_AT}
              label={"Created At"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_UPDATED_AT}
              label={"Updated At"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_JIRA_CREATED_AT}
              label={"Jira Created At"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_COMPLETED_AT}
              label={"Completed At"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_DATE}
              label={"Date"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">Ticket details Hours Settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_ESTIMATED_HOURS}
              label={"Estimated Hours"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_STORY_POINTS}
              label={"Story Points"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">TIcket Details Delivery Date Settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_DELIVERY_DATE}
              label={"Delivery Date"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_CUSTOMER_DELIVERY_DATE}
              label={"Customer Delivery Date"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">Ticket Details TIcket Ids Settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_TICKET_ID}
              label={"Ticket Id"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_PARENT_TICKET_ID}
              label={"Parent Ticket Id"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_JIRA_TICKET_ID}
              label={"Jira Ticket Id"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID}
              label={"Update Ticket Id With Jira Id"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_JIRA_ASSIGNEE}
              label={"Jira Assignee"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <h5 className="my-3">Ticket Attachments Settings</h5>
            <SingleCheckbox
              name={TICKET_FIELD_ATTACHMENT_PAGE_NAME}
              label={"Attachment Page Name"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_ATTACHMENT_PLATFORM}
              label={"Attachment Platform"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_ATTACHMENT_SUMMARY}
              label={"Attachment Summary"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
            <SingleCheckbox
              name={TICKET_FIELD_ATTACHMENT_IMAGE}
              label={"Attachment Image"}
              className="accepted-terms pb-0 me-3"
              handleOnChangeSubmit={(value, name) =>
                handleCheckBoxValue(value, name)
              }
            />
            <br />
          </Form>
        </TabPane>
        <TabPane tabId={Tabs.STATUS}>
          <StatusTab
            _toggle={_toggle}
            isOpen={statusIsOpen}
            history={props.history}
            project_id={
              Url.GetParam("projectId") ? Url.GetParam("projectId") : ""
            }
            row={row}
            setRow={setRow}
            ticketTypeId={props.match.params.typeid}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default TicketTypeDetail;
