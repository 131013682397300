import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Components
import PageTitle from "../../components/PageTitle";
import DeleteModal from "../../components/DeleteModal";
import SaleSettlementForm from "./components/saleSettlementForm";
import AddButton from "../../components/AddButton";
import { fetchList } from "../../actions/table";
import SaleSettlementList from "./components/saleSettlementList";
import Drawer from "../../components/Drawer";
import SaveButton from "../../components/SaveButton";
import Action from "../../components/Action";
import Toast from "../../components/Toast";

// Helpers
import { PAGESIZE, SORT_DIR } from "../../helpers/Status";
import * as Constants from "../../helpers/SaleSettlement";
import Cookie from "../../helpers/Cookie";
import ObjectName from "../../helpers/ObjectName";

// Lib
import Url, { UpdateUrl } from "../../lib/Url";
import Cookies, { setCookie } from "../../lib/Helper";
import Currency from "../../lib/Currency";
import Number from "../../lib/Number";
import ArrayList from "../../lib/ArrayList";
import String from "../../lib/String";
import { isBadRequest } from "../../lib/Http";
import DateTime from "../../lib/DateTime";

// API
import { apiClient } from "../../apiClient";
import { endpoints } from "../../api/endPoints";

// Services
import UserService from "../../services/userServices";
import ShiftService from "../../services/ShiftService";
import { getStoresList } from "../../services/StoreListService";
import SaleSettlementService from "../../services/SaleSettlementService";

const Tab = {
  DRAFT: "Draft",
  REVIEW: "Review",
  ALL: "All",
};

const index = (props) => {
  const { history, allCurrentPageSize, allCurrentPage } = props;

  // Defining the param based on url search values
  const Param = new URLSearchParams(props.history.location.search);

  // Defining the clicked tab value from section in url
  const role = Param.get("section");
  const [isOpen, setIsOpen] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [saleSettlementId, setSaleSettlementId] = useState();
  const [salesExecutive, setSalesExecutive] = useState([]);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [param, setParam] = useState({});
  const [currentPage, setCurrentPage] = useState();
  const [showSidebar, setShowSidebar] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [amountCash, setAmountCash] = useState(null);
  const [amountUpi, setAmountUpi] = useState("");
  const [totalAmount, setTotalAmount] = useState();
  const [amount_cash, setCash] = useState();
  const [amount_upi, setUpi] = useState();
  const [location, setName] = useState();
  const [shift, setShift] = useState();
  const [sales_executive, setsalesExecutive] = useState([]);
  const [date, setDate] = useState();
  const dispatch = useDispatch();
  const [saleSettlementData, setSaleSettlementData] = useState("");
  const [currentData, setCurrentData] = useState();
  const [activeTab, setActiveTab] = useState(role ? role : Tab.ALL);
  const [status, setStatus] = useState("");
  const [officeData, setOfficeData] = useState();

  const toggleSidebar = useCallback(() => setShowSidebar((value) => !value));
  const [cashInStore, setCashInStore] = useState();
  const [shiftList, setShiftList] = useState("");
  const [rowValue, setRowValue] = useState();
  const [isSubmit, setIsSubmit] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [dueDate, setDueDate] = useState();
  const [reviewer, setReviewer] = useState("");
  const [userList, setUserList] = useState([]);

  //Sort By Option Values
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "date:ASC",
      label: "Date",
    },
  ];

  const actionOptions = [
    {
      label: "Update Order Amount",
      value: "Update Order Amount",
    },
  ];

  // Toggle model
  const toggle = (Tab) => {
    setIsOpen(!isOpen);
    setAmountCash("");
    setAmountUpi("");
    setTotalAmount("");
    setCashInStore("");
    setShift("");
    setsalesExecutive("");
    setCashInStore("");
    setName("");
    setOfficeData("");
    setDate("");
    setDueDate("");
    setReviewer("");
  };

  const getShift = async () => {
    const list = await ShiftService.getShiftLists();
    setShiftList(list);
  };

  const getParamsStatus = () => {
    const status = Url.GetParam("section");
    setStatus({ status });
  };

  const onCashChange = (e) => {
    const cashAmount = e.values.amount_cash ? e.values.amount_cash : " ";
    let totalAmounts =
      (Currency.Get(cashAmount) ? Currency.Get(cashAmount) : null) +
      Currency.Get(amountUpi ? amountUpi : rowValue.amount_upi);
    setTotalAmount(totalAmounts);

    setAmountCash(cashAmount);
    setCash(cashAmount);
  };

  const onUpiChange = (e) => {
    const upiAmount = e.values.amount_upi ? e.values.amount_upi : " ";
    let total_amount =
      (Currency.Get(upiAmount) ? Currency.Get(upiAmount) : null) +
      Currency.Get(amountCash ? amountCash : rowValue.amount_cash);
    setTotalAmount(total_amount);
    setAmountUpi(upiAmount);
    setUpi(upiAmount);
  };

  const onStoreChange = (e) => {
    const locationName = e?.id ? e?.id : "";
    setName(locationName);
  };

  const onShiftChange = (e) => {
    const shiftValue = e.values.shift.id;
    setShift(shiftValue);
  };

  const onSalesExecutiveChange = (e) => {
    const value = e.id;
    setsalesExecutive(value);
  };

  const onStoreCashChange = (e) => {
    const storeCash = e.values.cash_in_store ? e.values.cash_in_store : " ";
    setCashInStore(storeCash);
  };

  const onDateChange = (e) => {
    setDate(e);
  };

  const onCashToOfficeChange = (x) => {
    const data = x.values.cash_to_office;
    setOfficeData(data);
  };

  // Initial values
  const initialValues = {
    location: location
      ? locationList.find((data) => data.id == location)
      : locationList.find((data) => data.id == rowValue?.storeId),
    date: date
      ? date
      : rowValue?.date
        ? rowValue?.date
        : DateTime.getTodayDateByUserTimeZone(new Date()),
    shift:
      shiftList && shiftList.length > 0
        ? shiftList.find((data) => data.id == shift || rowValue?.shiftId)
        : shiftList &&
        shiftList.find(
          (data) => data.value == Cookies.get(Cookie.SALE_SHIFT)
        ),
    amount_cash: amountCash ? amountCash : rowValue?.amount_cash,
    amount_upi: amountUpi ? amountUpi : rowValue?.amount_upi,
    salesExecutive: sales_executive
      ? salesExecutive &&
      salesExecutive.find((data) => data.id == sales_executive)
      : salesExecutive &&
      salesExecutive.find((data) => data.id == rowValue?.sales_executive),
    notes: "",
    total_amount: totalAmount ? totalAmount : rowValue?.total_amount,
    cash_in_store: cashInStore ? cashInStore : rowValue?.cash_in_store,
    cash_to_office: officeData ? officeData : rowValue?.cash_to_office,
    notes: rowValue?.notes ? rowValue?.notes : "",
    order_total_amount: rowValue?.order_total_amount,
    order_cash_amount: rowValue?.order_cash_amount,
    order_upi_amount: rowValue?.order_upi_amount,
    due_date: dueDate ? dueDate : rowValue?.due_date,
    reviewer: userList.find(
      (value) =>
        value?.id == (Number.Get(reviewer) || Number.Get(rowValue?.reviewer))
    ),
    draft_order_amount: rowValue?.draft_order_amount,
  };


  const getUserRoleValue = async () => {
    let data = await UserService.getSalesExecutive();
    setSalesExecutive(data.activeList);
  };

  useEffect(() => {
    getShift();
    getUserRoleValue();
    getParamsStatus();

    const { page, pageSize, sort, sortDir } = getParams();

    const data = {
      page,
      pageSize,
      sort,
      sortDir,
    };

    setParam(data);
  }, []);

  // get params
  const getParams = () => {
    const selectedPageSize = Url.GetParam("pageSize");
    const pageSize = selectedPageSize ? selectedPageSize : PAGESIZE;
    const selectedSort = Url.GetParam("sort");
    const sort = selectedSort ? selectedSort : "id";
    const selectedSortDir = Url.GetParam("sortDir");
    const sortDir = selectedSortDir ? selectedSortDir : SORT_DIR;
    const searchTerm = Url.GetParam("search");
    const search = String.Get(searchTerm);
    const selectedPage = Url.GetParam("page");
    const page = selectedPage ? selectedPage : "";
    const data = {
      pageSize,
      sort,
      sortDir,
      search,
      page,
      objectName: ObjectName.SALE_SETTLEMENT,
    };

    return data;
  };

  const handleReviewerChange = (e) => {
    let value = e && e.id;
    setReviewer(value);
  };

  const handleDueDateChange = (values) => {
    setDueDate(values);
  };

  const saleSettlementForm = (
    <SaleSettlementForm
      location={locationList}
      salesExecutive={salesExecutive}
      shiftList={shiftList}
      showDiscrepancy={false}
      showCalculatedAmount={false}
      showReceivedAmount={false}
      showMediaTab={false}
      onCashChange={onCashChange}
      onUpiChange={onUpiChange}
      addSaleSettlementForm={true}
      showCashInStore={true}
      onStoreCashChange={onStoreCashChange}
      onStoreChange={onStoreChange}
      onShiftChange={onShiftChange}
      onSalesExecutiveChange={onSalesExecutiveChange}
      onDateChange={onDateChange}
      onCashToOfficeChange={onCashToOfficeChange}
      showUserDetailsPageLink={rowValue?.id ? true : false}
      userId={rowValue?.id ? rowValue?.sales_executive : null}
      id={rowValue?.id}
      userList={setUserList}
      handleReviewerChange={handleReviewerChange}
      reviewer={rowValue?.reviewer}
      handleDueDateChange={handleDueDateChange}
      setLocationList={setLocationList}
      locationId={rowValue?.storeId}
    />
  );

  const addSaleSettlementFooter = (
    <SaveButton
      type="submit"
      label={rowValue && rowValue?.id ? "Save" : "Add"}
      loading={isSubmit == false}
    />
  );

  // Function to handle form submit
  const handleSubmit = async (values) => {
    setIsSubmit(false);
    // Form data
    const data = new FormData();
    // location id
    data.append("storeId", values?.location?.id);

    // date
    data.append("date", values?.date);

    // Shift
    data.append("shift", values?.shift.id);

    // Amount_cash
    data.append("amount_cash", Currency.Get(values.amount_cash));

    //Amount_upi
    data.append("amount_upi", Currency.Get(values.amount_upi));

    // SalesExecutive
    data.append(
      "salesExecutive",
      values &&
      values.salesExecutive.value &&
      Number.Get(values.salesExecutive.id)
    );

    // Notes
    data.append("notes", values.notes);
    data.append("due_date", values.due_date ? values.due_date : "");
    data.append(
      "reviewer",
      values.reviewer && values.reviewer ? values.reviewer.id : ""
    );

    data.append("total_amount", Currency.Get(values.total_amount));

    data.append(
      "cash_in_store",
      Currency.Get(cashInStore ? cashInStore : values.cash_in_store)
    );
    data.append(
      "cash_to_office",
      Currency.Get(officeData ? officeData : values.cash_to_office)
    );

    const params = {
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      startDate: Url.GetParam("startDate"),
      endDate: Url.GetParam("endDate"),
      location: Url.GetParam("location"),
      status: Url.GetParam("status"),
      salesExecutive: Url.GetParam("salesExecutive"),
      shift: Url.GetParam("shift"),
      showTotalAmount: true
    };

    if (rowValue.id) {
      dispatch(
        await SaleSettlementService.update(
          rowValue?.id,
          data,
          params,
          toggle,
          setIsSubmit
        )
      );
    } else {
      dispatch(
        await SaleSettlementService.create(data, params, (res) => {
          if (res) {
            toggle();
          }
        })
      );
    }
    setIsSubmit(true);
    setCookie(Cookie.SALE_SHIFT, values?.shift?.value);
    setCookie(Cookie.SALE_DATE, values?.date);
    setCookie(Cookie.SALE_TYPE, values?.type);
    setCookie(Cookie.SALE_STORE, values?.location?.value);
  };

  const handleDelete = async (id) => {
    dispatch(
      await SaleSettlementService.delete(id, allCurrentPage, allCurrentPageSize)
    );
    setIsDeleteModel(false);
  };

  // Handle Reset
  const handleReset = async () => {
    setResetValue(true);
    setParam("");
    dispatch(await SaleSettlementService.search());
    Url.UpdateUrl({ location: "", shift: "", salesExecutive: "" }, props);
  };

  // Handle Close
  const handleClose = () => {
    toggleSidebar();
    setParam("");
  };

  const updateOrderAmount = async () => {
    try {
      const data = new FormData();
      // status
      data.append("id", selectedIds);
      await apiClient
        .post(`${endpoints().saleSettlementAPI}/updateOrderAmount`, data)
        .then((response) => {
          if (response && response.data) {
            Toast.success(response.data.message);
          }
          dispatch(
            fetchList(
              "SaleSettlement",
              `${endpoints().saleSettlementAPI}/list`,
              Url.GetParam("page"),
              Url.GetParam("pageSize"),
              {
                startDate: Url.GetParam("startDate"),
                endDate: Url.GetParam("endDate"),
              }
            )
          );
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleBulkUpdate = (value) => {
    setSelectedIds(value);
  };

  const handleChange = (e) => {
    if (e == "Update Order Amount") {
      updateOrderAmount();
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Delete Sales"
        id={saleSettlementId}
        label={saleSettlementData}
        deleteFunction={handleDelete}
      />
      <Drawer
        modelTitle={
          rowValue?.id ? "Edit Sales Settlement" : "Add Sales Settlement"
        }
        DrawerBody={saleSettlementForm}
        DrawerFooter={addSaleSettlementFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
      <div className="d-flex justify-content-between">
        <PageTitle label="Sales Settlement" />
        <div className="d-flex">
          <AddButton
            className=" ms-3 me-1 mt-0 mt-sm-1"
            label="Add New"
            onClick={(e) => {
              setRowValue("");
              toggle();
            }}
          />
          <div className="ps-1">
            <Action dropdownLinks={actionOptions} handleChange={handleChange} />
          </div>
        </div>
      </div>

      <div className="bg-white mt-3 card-body">
        <SaleSettlementList
          id="SaleSettlement"
          sortByOption={sortByOption}
          history={history}
          objectName={ObjectName.SALE_SETTLEMENT}
          setIsDeleteModel={setIsDeleteModel}
          setCurrentPage={setCurrentPage}
          setCurrentData={setCurrentData}
          setSaleSettlementId={setSaleSettlementId}
          setSaleSettlementData={setSaleSettlementData}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          allCurrentPage={allCurrentPage}
          allCurrentPageSize={allCurrentPageSize}
          setRowValue={setRowValue}
          toggle={toggle}
          handleBulkUpdate={handleBulkUpdate}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;

  const allCurrentPage =
    reduxTable["SaleSettlement"] && !reduxTable["SaleSettlement"].isFetching
      ? reduxTable["SaleSettlement"].currentPage
      : 1;

  const allCurrentPageSize =
    reduxTable["SaleSettlement"] && !reduxTable["SaleSettlement"].isFetching
      ? reduxTable["SaleSettlement"].pageSize
      : 25;

  return {
    allCurrentPage,
    allCurrentPageSize,
  };
}

// Map Dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(index);
