import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../components/Breadcrumb";
import SaveButton from "../../../components/SaveButton";
import { saveSetting } from "../../../services/SettingService";
import GoogleService from "../../../services/googleService";

const GoogleConnect = (props) => {
  const [status, setStatus] = useState();

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Integrations", link: "/integrations/Chat" },
    { label: "Google", link: "" },
  ];

  const statuses = {
    connect: 0,
    disConnect: 1,
  };

  const connect = async () => {
    let response = await GoogleService.connect();

    if (response && response.redirectTo) {
      window.location.replace(response.redirectTo);
      getSettings();
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const data = await GoogleService.status();
    setStatus(data);
  };

  const disConnect = async () => {
    await saveSetting(
      { google_access_token: "", google_refresh_token: "" },
      "",
      () => {}
    );
    getSettings();
  };

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="col-lg-6 col-md-6 col-sm-6 mt-2">
        <div className="card">
          <div className="card-body d-flex align-items-center">
            <p className="m-2 flex-grow-1 text-start fw-bold">{"Google"}</p>
            <SaveButton
              onClick={status === statuses.disConnect ? disConnect : connect}
              label={status === statuses.disConnect ? "Disconnect" : "Connect"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleConnect;
