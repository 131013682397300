import React from "react";
import { Link } from "react-router-dom";

// Components
import StatusText from "../../../components/StatusText";
import UserCard from "../../../components/UserCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";

// API
import { endpoints } from "../../../api/endPoints";

// Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxesPacking } from "@fortawesome/free-solid-svg-icons";

// Lib
import DateTime from "../../../lib/DateTime";
import Currency from "../../../lib/Currency";
import Url from "../../../lib/Url";

const PurchaseOrdersList = (props) => {
  const {
    history,
    id,
    sortByOption,
    toggleSidebar,
    showStatus,
    objectName,
    params,
    showAccountFilter
  } = props;

  return (
    <>
      <div className="tab-content-wrapper">
        <div className="mt-4">
          <ReduxTable
            id={id}
            newTableHeading
            searchPlaceholder="Search Purchase Order"
            icon={<FontAwesomeIcon icon={faBoxesPacking} />}
            apiURL={`${endpoints().purchaseOrderAPI}/search`}
            history={history}
            sortByOptions={sortByOption}
            paramsToUrl={true}
            selectedPageSize={Url.GetParam("pageSize")}
            buttonOnClick={toggleSidebar}
            params={{
              pageSize: Url.GetParam("pageSize"),
              objectName: objectName,
              vendor: params && params.vendor
            }}
            showHeader
            showStatusFilter
            showAccountFilter={showAccountFilter === false ? false : true}
            objectName={objectName}
            showDateFilter
          >
            <ReduxColumn
              className="text-center"
              field="purchase_order_number"
              sortBy="purchase_order_number"
              isClickable="true"
              type="link"
              width="110px"
              maxWidth="110px"
              minWidth="110px"
              renderField={(row) => (
                <Link to={`/purchaseOrder/detail/${row.id}`}>
                  {row.purchase_order_number}
                </Link>
              )}
            >
              PO#
            </ReduxColumn>
            <ReduxColumn
              sortBy="date"
              width="110px"
              maxWidth="110px"
              minWidth="110px"
              className="text-center"
              renderField={(row) => <span>{DateTime.getDate(row.date)}</span>}
            >
              Date
            </ReduxColumn>

            <ReduxColumn
              field="vendor_name"
              sortBy="vendor_name"
              width="110px"
              maxWidth="110px"
              minWidth="110px"
              className="text-center"
            >
              Vendor
            </ReduxColumn>
            <ReduxColumn
              field="amount"
              sortBy="amount"
              width="110px"
              maxWidth="110px"
              minWidth="110px"
              className="text-center"
              renderField={(row) => <span>{Currency.Format(row.amount)}</span>}
            >
              Amount
            </ReduxColumn>
            <ReduxColumn
              sortBy="delivery_date"
              width="110px"
              maxWidth="110px"
              minWidth="110px"
              className="text-center"
              renderField={(row) => <span>{DateTime.getDate(row.delivery_date)}</span>}
            >
              Delivery Date
            </ReduxColumn>
            <ReduxColumn
              className="ellipsis text-start"
              field="description"
              sortBy="description"
            >
              Description
            </ReduxColumn>
            <ReduxColumn
              field="owner_name"
              sortBy="owner_name"
              className="text-center"
              minWidth="210px"
              maxWidth="210px"
              renderField={(row) => (
                <>
                  <UserCard
                    customSize={parseInt(50, 10)}
                    firstName={row.owner_name}
                    url={row.image}
                  />
                </>
              )}
            >
              Owner
            </ReduxColumn>
            {showStatus && (
              <ReduxColumn
                field="status"
                sortBy="status"
                width="110px"
                maxWidth="110px"
                minWidth="110px"
                renderField={(row) => (
                  <StatusText backgroundColor={row.statusColor} status={row.status} />
                )}
              >
                Status
              </ReduxColumn>
            )}
          </ReduxTable>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrdersList;
