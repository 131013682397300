import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Form from "../../components/Form";
import TextArea from "../../components/TextArea";
import PageTitle from "../../components/PageTitle";
import HorizontalSpace from "../../components/HorizontalSpace";
import SaveButton from "../../components/SaveButton";
import CancelButton from "../../components/CancelButton";
import Spinner from "../../components/Spinner";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import MediaCarousel from "../../components/MediaCarousel";
import UserSelect from "../../components/UserSelect";
import Action from "../../components/Action";

// Helpers
import Media from "../../helpers/Media";
import MediaUpload from "../../helpers/MediaUpload";
import ObjectName from "../../helpers/ObjectName";

// Services
import GatePassService from "../../services/GatePassService";

function GatePassDetail(props) {
  let { history } = props;
  let id = props.match.params.id;
  const [detail, setDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  let dispatch = useDispatch();

  useEffect(() => {
    getGatePassDetail();
  }, []);

  let initialValues = {
    notes: detail ? detail?.notes : "",
  };

  const handleUpdate = async (values) => {
    const data = new FormData();
    data.append("notes", values?.notes ? values?.notes : "");
    data.append(
      "owner_id",
      values?.owner_id?.id ? values?.owner_id?.id : detail?.owner_id
    );
    data.append("media_file", selectedFile ? selectedFile : "");
    dispatch(
      await GatePassService.update(id, data, (res) => {
        if (res) {
          getGatePassDetail();
          setSelectedFile("");
        }
      })
    );
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleImageUpload = async (values) => {
    if (id) {
      await MediaUpload.uploadFile(
        selectedFile,
        id,
        ObjectName.LEAD,
        values,
        Media.VISIBILITY_PRIVATE,
        ""
      );
    }
  };

  const getGatePassDetail = async () => {
    let data = await GatePassService.get(id);
    setDetail(data);
  };

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    { label: "Gate Pass", link: "/gatePass" },
    { label: "Detail Page", link: "" },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
   
  ];

  const handleActionChange = (e) => {
    if (e === "Delete") {
      deleteToggle();
    }
  };

  const handleDelete = async () => {
    dispatch(
      await GatePassService.delete(id, (res) => {
        if (res) {
          history.push("/gatePass");
        }
      })
    );
  };

  const deleteToggle = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DeleteModal
        isOpen={isOpen}
        toggle={() => {
          deleteToggle();
        }}
        deleteFunction={handleDelete}
        title="Delete Image"
        label={detail?.id}
      />
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Gate Pass Detail Page" />
        <div className="d-flex justify-content-end">
           <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}        
           />
        </div>
      </div>

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-7">
            <Form
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleUpdate}
            >
              <TextArea
                name="notes"
                label="Notes"
                placeholder="Enter Notes..."
                error=""
                fontBolded
              />
              <UserSelect
                name="owner_id"
                label="Owner"
                selectedUserId={detail?.owner_id}
                showUserDetailsPageLink={detail?.owner_id ? true : false}
                userId={detail?.owner_id}
              />

              <HorizontalSpace bottom="2">
                <SaveButton label="Save" />
                <CancelButton
                  onClick={() => {
                    history.push("/gatePass");
                  }}
                />
              </HorizontalSpace>
            </Form>
          </div>
          <div className="col-sm-5 bill-web-view ">
            <MediaCarousel
              showCarasoul
              modalTitle="Upload File"
              title="leads"
              objectName={ObjectName.GATE_PASS}
              objectId={id}
              history={history}
              billView={true}
              attachmentsList={true}
              modalOpen={isModelOpen}
              toggle={toggle}
              setIsModelOpen={setIsModelOpen}
              Attachments={"Attachments"}
              handleImageUpload={handleImageUpload}
              selectedFileValue={setSelectedFile}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default GatePassDetail;
