// Actions
import ProjectUser from "../actions/projectUser";
import { fetchList } from "../actions/table";

// API
import { endpoints } from "../api/endPoints";

// Config
import { apiClient } from "../apiClient";

// Components
import Toast from "../components/Toast";

// Helpers
import { HttpStatus } from "../helpers/HttpStatus";

// Lib
import { isBadRequest } from "../lib/Http";

class ProjectUserService {
  static addProjectUser = (data, params, toggle) => {
    return (dispatch) => {
      dispatch(ProjectUser.requestAddProjectUser);

      apiClient
        .post(endpoints().ProjectUserApi, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            // toggle();
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "projectUser",
              `${endpoints().ProjectUserApi}/search`,
              params.page ? params.page : 1,
              params.pageSize ? params.pageSize : 25,
              ...params
            )
          );
          dispatch(ProjectUser.receiveProjectAddError());
        })
        .catch((error) => {
          dispatch(ProjectUser.projectUserCreateError(error));

          if (
            error.response &&
            error.response.status >= HttpStatus.BAD_REQUEST
          ) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }

            Toast.error(errorMessage);
            // toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  };

  static deleteProjectUser = (id, params) => {
    try {
      return (dispatch) => {
        dispatch(ProjectUser.requestDeleteProjectUser());

        apiClient
          .delete(`${endpoints().ProjectUserApi}/${id}`)
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
            }
          })
          .then(() => {
            dispatch(
              fetchList(
                "projectUser",
                `${endpoints().ProjectUserApi}/search`,
                params.page ? params.page : 1,
                params.pageSize ? params.pageSize : 25,
                params
              )
            );
          })
          .catch((error) => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      };
    } catch (error) {
      console.log(error);
    }
  };

  static async updateStatus(id, status, callback) {
    let data = {};
    data.status = status;
    apiClient
      .put(`${endpoints().ProjectUserApi}/updateStatus/${id}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage);
        }
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }
}

export default ProjectUserService;
