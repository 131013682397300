export const Tabs = {
    STOCK_ENTRY: "Stock Entry",
    ORDERS:"Orders",
    PURCHASE:"Purchase",
    PURCHASE_ORDER:"Purchase_order",
    SALES_SETTLEMENT:"sale_settlement",
    TRANSFER:"Transfers",
    FINE:"fine",
    ORDERS:"Orders",
    DELIVERY_ORDERS:"Delivery Orders",
    PAYMENTS:"Payments"
  };