import React, { useEffect, useState } from "react";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import TagSelect from "../../../components/TagSelect";
import { Setting } from "../../../helpers/Setting";
import { TagTypeName } from "../../../helpers/Tag";
import { getKeyValueByObject } from "../../../lib/Helper";
import { getCompanySettings, saveSetting } from "../../../services/SettingService";
import TagTypeService from "../../../services/TagTypeService";
const SettingTab = (props) => {
  const [typeList, setTypeList] = useState([]);
  const [fineValue, setFineValue] = useState([]);
  const [bonusTypeValue, setBonusTypeValue] = useState("")
  const [earlyCheckOutValue, setEarlyCheckOutValue] = useState("")
  useEffect(() => {
    getFineTypes();
    getSettings();
  }, []);

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let cash = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_LATE_HOURS_FINE_TYPE
    );
    let bonusTypeValue = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_EARLY_HOURS_BONUS_TYPE
    );
    let earlyCheckoutFineTypeValue = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE
    );
    setFineValue(cash);
    setBonusTypeValue(bonusTypeValue)
    setEarlyCheckOutValue(earlyCheckoutFineTypeValue)
  };
  const getFineTypes = async () => {
    let response = await TagTypeService.TagList("");
    let data = response && response?.data;
    if (data && data.length > 0) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { id, name } = data[i];
        list.push({
          value: id,
          label: name,
        });
      }
      setTypeList(list);
    }
  };
  const handleUpdate = async (values) => {
    let data = new FormData();
    data.append(
      Setting.ATTENDANCE_LATE_HOURS_FINE_TYPE,
      values && values?.attendance_late_hours_fine_type?.value
    );

    data.append(
      Setting.ATTENDANCE_EARLY_HOURS_BONUS_TYPE,
      values && values?.attendance_early_hours_bonus_type?.value
    );

    data.append(
      Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE,
      values && values?.attendance_early_check_out_fine_type?.value
    );

    saveSetting(data, null, (res) => {
      if (res) {
      }
    });
  };

  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={{
          attendance_late_hours_fine_type:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == fineValue),
            [Setting.ATTENDANCE_EARLY_HOURS_BONUS_TYPE]:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == bonusTypeValue),
            [Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE]:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == earlyCheckOutValue),
        }}
        onSubmit={(values) => {
          handleUpdate(values);
        }}
      >
        <div className="card bg-white mb-3">
          <div className="card-body">
            <TagSelect  name={Setting.ATTENDANCE_LATE_HOURS_FINE_TYPE}
              label="Attendance Late Hours Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
              />
               <TagSelect  name={Setting.ATTENDANCE_EARLY_HOURS_BONUS_TYPE}
              label="Attendance Early Hours Bonus Type"
              params={{ type: TagTypeName.BONUS_TYPE }}
              />
                <TagSelect  name={Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE}
              label="Attendance Early CheckOut Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
              />
            <div className="mb-4">
              <div>
                <SaveButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default SettingTab;
