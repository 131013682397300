import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { bindActionCreators } from "redux";
import classNames from "classnames";

// Components
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import PurchaseForm from "./components/purchaseForm";
import Drawer from "../../components/Drawer";
import SaveButton from "../../components/SaveButton";
import PurchaseProductList from "./components/PurchaseProductList";
import PurchaseListPage from "./components/purchaseList";
import AddButton from "../../components/AddButton";
import UserCard from "../../components/UserCard";

// API
import { endpoints } from "../../api/endPoints";

//Config
import { apiClient } from "../../apiClient";

// Action
import {
  addPurchase,
  deletePurchase,
  updatePurchase,
} from "../../actions/purchase";
import { fetchList } from "../../actions/table";

// Lib
import { isLoggedIn } from "../../lib/Helper";
import String from "../../lib/String";
import Url from "../../lib/Url";
import DateTime from "../../lib/DateTime";
import Number from "../../lib/Number";

// Helpers

import ObjectName from "../../helpers/ObjectName";
import { purchase } from "../../helpers/Purchase";
import { PAGE, PAGESIZE, SORT, SORT_DIR } from "../../helpers/Status";
import { HttpStatus } from "../../helpers/HttpStatus";
import Media from "../../helpers/Media";

// Services
import StatusService from "../../services/StatusService";
import UserService from "../../services/UserService";
import MediaService from "../../services/MediaService";

export const Tabs = {
  PURCHASE: "Purchase",
  PURCHASE_PRODUCTS: "PurchaseProduct",
};

const Purchase = (props) => {
  const {
    history,
    draftCurrentPage,
    draftCurrentPageSize,
    reviewCurrentPage,
    reviewCurrentPageSize,
    allCurrentPage,
    allCurrentPageSize,
  } = props;

  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [purchaseId, setPurchaseId] = useState();
  const [purchaseData, setPurchaseData] = useState("");
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [param, setParam] = useState({});
  const [currentPage, setCurrentPage] = useState();
  const [amountValue, setAmountValue] = useState();
  const [netAmount, setNetAmount] = useState();
  const [purchaseNumberValue, setPurchaseNumberValue] = useState();
  const [storeValue, setStoreValue] = useState();
  const [vendorValue, setVendorValue] = useState();
  const [descriptionValue, setDescriptionValue] = useState();
  const [statusId, setStatusId] = useState(null);
  const [vendorInvoiceDate, setVendorInvoiceDateChange] = useState();
  const [dueDate, setDueDate] = useState();
  const [isSubmit, setIsSubmit] = useState(true);
  const [returnItemAmount, setReturnItemAmount] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [purchaseDate, setPurchaseDate] = useState("");
  const [rowValue, setRowValue] = useState(null);
  const [ownerValue, setOwnerValue] = useState(null);
  const buttonLabel = true;
  const [userList, setUserList] = useState([]);
  const [reviewer, setReviewer] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageurl, setImageUrl] = useState([]);

  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") || Tabs.PURCHASE
  );

  const dispatch = useDispatch();

  useEffect(() => {
    isLoggedIn();
    getAccountList();
    getStatus();

    const { vendor, pageSize, sort, sortDir, search, page } = getParams();

    const data = { vendor, pageSize, sort, sortDir, search, page };

    setParam(data);
  }, []);

  useEffect(() => {
    setInvoiceAmount(rowValue?.invoice_amount);
  }, [rowValue]);

  useEffect(() => {
    if (selectedFile) {
      getUrl();
    }
  }, [isLoading, selectedFile]);

  const getUrl = () => {
    let url = [];
    for (let i = 0; i < selectedFile.length; i++) {
      const file = selectedFile[i];
      const imageUrl = URL.createObjectURL(file && file[0]);
      url.push({ url: imageUrl, image_id: file.id });
    }
    setImageUrl(url);
  };


  // Get params
  const getParams = () => {
    const vendor = Url.GetParam("vendor") ? Url.GetParam("vendor") : "";
    const selectedPageSize = Url.GetParam("pageSize");
    const pageSize = selectedPageSize ? selectedPageSize : PAGESIZE;
    const selectedSort = Url.GetParam("sort");
    const sort = selectedSort ? selectedSort : SORT;
    const selectedSortDir = Url.GetParam("sortDir");
    const sortDir = selectedSortDir ? selectedSortDir : SORT_DIR;
    const searchTerm = Url.GetParam("search");
    const search = searchTerm ? searchTerm : "";
    const selectedPage = Url.GetParam("page");
    const page = selectedPage ? selectedPage : PAGE;

    const data = { vendor, pageSize, sort, sortDir, search, page };

    return data;
  };

  // Toggle sidebar for filter
  const toggleSidebar = useCallback(() => setShowSidebar((value) => !value));

  const openToogle = () => {
    setStoreModalOpen(!storeModalOpen);
    setIsSubmit(true);
  };

  const closeToggle = () => {
    setStoreModalOpen(!storeModalOpen);
    setAmountValue("");
    setPurchaseDate("");
    setSelectedFile("")
    setNetAmount("");
    setPurchaseNumberValue("");
    setStoreValue("");
    setVendorValue("");
    setDescriptionValue("");
    setVendorInvoiceDateChange("");
    setInvoiceAmount("");
    setReturnItemAmount("");
    setRowValue(null);
    setIsSubmit(true);
    setReviewer(null);
  };

  const onPurchaseNumberChange = (x) => {
    const value = x.target.value;
    setPurchaseNumberValue(value);
  };

  const onVendorInvoiceDateChange = (x) => {
    const value = x ? x : "";
    setVendorInvoiceDateChange(value);
  };

  const onDueDateDateChange = (e) => {
    const value = e ? e : "";
    setDueDate(value);
  };

  const onStoreChange = (x) => {
    const value = x ? x : "";
    setStoreValue(value);
  };

  const onVendorChange = (x) => {
    const value = x ? x : "";
    setVendorValue(value);
  };

  const onDescriptionChange = (x) => {
    const value = x.target.value;
    setDescriptionValue(value);
  };

  const handleReturnedAmount = (e) => {
    const value = e.target.value ? e.target.value : " ";
    setReturnItemAmount(value);
    let net_amount = Number.Float(invoiceAmount) - Number.Float(value);
    setNetAmount(net_amount);
  };

  const handleInvoiceAmount = (e) => {
    const value = parseFloat(e.target.value ? e.target.value : 0);
    setInvoiceAmount(value);
    let netAmount =
      Number.Float(value) -
      Number.Float(
        returnItemAmount
          ? returnItemAmount
          : rowValue?.returned_items_amount
            ? rowValue?.returned_items_amount
            : 0
      );
    setNetAmount(netAmount);
  };

  const onDueDateChange = (e) => {
    setDueDate(e);
  };

  const handlePurchaseChange = (date) => {
    const value = date ? date : "";
    setPurchaseDate(value);
  };

  const handleUserChange = (owner) => {
    setOwnerValue(owner);
  };

  const handleReviewerChange = (values) => {
    let value = values;
    setReviewer(value);
  };


  const onDropImage = (images) => {
    handleImageValue({
      ...images,
      id: selectedFile.length + 1,
    });
  };

  const handleImageValue = (images) => {
    setSelectedFile((prevFileList) => [...prevFileList, { ...images }]);
  };


  const handleImageRemove = (deletedvalue) => {
    setIsLoading(true);
    const updatedImageUrlArray = selectedFile.filter(
      (item) => item.id !== deletedvalue.image_id
    );
    setSelectedFile(updatedImageUrlArray);
    setIsLoading(false);
  };

  const addStoreForm = (
    <PurchaseForm
      history={history}
      accountList={accountList}
      onPurchaseNumberChange={onPurchaseNumberChange}
      handlePurchaseChange={handlePurchaseChange}
      onStoreChange={onStoreChange}
      onVendorChange={onVendorChange}
      onDescriptionChange={onDescriptionChange}
      onVendorInvoiceDateChange={onVendorInvoiceDateChange}
      onDueDateDateChange={onDueDateDateChange}
      handleInvoiceAmount={handleInvoiceAmount}
      handleReturnedAmount={handleReturnedAmount}
      onDueDateChange={onDueDateChange}
      handleUserChange={handleUserChange}
      handleReviewerChange={handleReviewerChange}
      rowValue={rowValue}
      handleImageRemove={handleImageRemove}
      onDropImage={onDropImage}
      imageurl={imageurl}
      userList={setUserList}
      userId={rowValue?.owner_id}
      reviewerId={rowValue?.reviewer_id}
    />
  );

  // Get Payment Accounts
  const getAccountList = async () => {
    try {
      // Create new array for payment accounts
      let accountList = new Array();

      // get account list response
      let response = await apiClient.get(`${endpoints().accountAPI}/list`);

      // Validate response
      if (response && response.data && response.data.data) {
        // get account list
        let accounts = response.data.data;

        // Validate accounts length exist or not
        if (accounts && accounts.length > 0) {
          for (let i = 0; i < accounts.length; i++) {
            accountList.push({
              label: accounts[i].account_name,
              value: accounts[i].id,
            });
          }
          setAccountList(accountList);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFile = async (objectId, showToastMessage = false) => {
    try {
      if (selectedFile && selectedFile.length > 0 && objectId) {
        for (let i = 0; i < selectedFile.length; i++) {
          const File = selectedFile[i];
          const mediaFile = File ? File[0] : "";
          const media = File[0]?.name;

          const data = new FormData();

          if (mediaFile) {
            data.append([Media.MEDIA_FILE], mediaFile ? mediaFile : "");
          }
          if (media !== undefined) {
            data.append([Media.MEDIA_NAME], media ? media : "");
          }
          data.append("object", ObjectName.PURCHASE);

          data.append("object_id", objectId);

          data.append([Media.MEDIA_VISIBILITY], Media.VISIBILITY_PUBLIC);

          await MediaService.saveImage(data, showToastMessage);
          setImageUrl("");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const purchaseAdd = (data) => {
    let storeId = data.location;
    dispatch(
      addPurchase(data, {}, history, (response) => {
        if (response && response.purchase) {
          uploadFile(response?.purchase?.id);
          closeToggle();
          dispatch(history.push(`/purchase/${response.purchase.id}`));
          //get stock entry details
          let stockEntryDetails = response.purchase;
          //validate stock entry details exist or not
          if (stockEntryDetails) {
            history.push(`
            /purchase/${stockEntryDetails.id}`);
          }
        }
      })
    );
  };

  const getStatus = async () => {
    const status = await StatusService.search(ObjectName.PURCHASE);
    for (let i = 0; i < status.length; i++) {
      const order = status.find(
        (order) => order.sortOrder === status[i]?.sortOrder
      );
      setStatusId(order?.id);
      break;
    }
  };

  const handleSubmit = (values) => {
    try {
      const data = new FormData();
      // const formData = new formData();
      data.append("date", new Date(values.date));

      data.append(
        "vendor_invoice_number",
        values && String.Get(values.vendor_invoice_number)
      );

      if (values.description !== undefined) {
        data.append("description", values && String.Get(values.description));
      }
      data.append("location", values && String.Get(values.location.value));
      data.append("vendor_id", values && String.Get(values.vendor_name.value));
      data.append("net_amount", values && values.net_amount);
      data.append("status", statusId);
      data.append(
        "vendor_invoice_date",
        values?.vendor_invoice_date ? values?.vendor_invoice_date : ""
      );
      data.append("due_date", values?.due_date ? values?.due_date : "");
      data.append("owner", values?.owner?.id ? values?.owner?.id : ownerValue?.id);
      data.append("reviewer", values?.reviewer?.id ? values?.reviewer?.id : "");
      data.append(
        "invoice_amount",
        values.invoice_amount && Number.Get(values?.invoice_amount)
      );
      data.append(
        "returnedItemAmount",
        values?.returnedItemAmount ? Number.Get(values?.returnedItemAmount) : ""
      );

      if (rowValue && rowValue?.id) {
        dispatch(
          updatePurchase(rowValue?.id, data, {}, (response) => {
            if (response.status == HttpStatus.OK) {
              dispatch(
                fetchList(
                  purchase.DRAFT_PURCHASE,
                  `${endpoints().purchaseAPI}/search`,
                  1,
                  25,
                  {
                    ...Url.GetAllParams(),
                  }
                )
              );
              setRowValue(null);
              closeToggle()
            }
          })
        );
      } else {
        dispatch(purchaseAdd(data));
      }

      setStoreModalOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const _handleStatusChange = (tabStatus) => {
    props.history.push(`/purchases?tab=${tabStatus}`);
  };

  const addStoreFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit == false}
      label={rowValue?.id ? "Edit" : "Add"}
    />
  );

  const delPurchase = (id) => {
    dispatch(
      deletePurchase(
        id,
        {
          page: currentPage,
        },
        draftCurrentPageSize,
        draftCurrentPage,
        reviewCurrentPage,
        reviewCurrentPageSize,
        allCurrentPageSize,
        allCurrentPage
      )
    );

    setIsDeleteModel(false);
  };

  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  const initialValues = {
    date: purchaseDate
      ? purchaseDate
      : rowValue?.purchaseDate
        ? rowValue?.purchaseDate
        : DateTime.getTodayDateByUserTimeZone(new Date()),
    status: "",
    location: storeValue
      ? storeValue
      : rowValue?.location
        ? { label: rowValue?.location_name, value: rowValue?.location }
        : "",
    amount: amountValue,
    vendor_name: vendorValue
      ? vendorValue
      : rowValue?.vendor_id
        ? { label: rowValue?.vendorName, value: rowValue?.vendor_id }
        : "",
    vendor_invoice_number: purchaseNumberValue
      ? purchaseNumberValue
      : rowValue?.vendorInvoiceNumber
        ? rowValue?.vendorInvoiceNumber
        : "",
    description: descriptionValue
      ? descriptionValue
      : rowValue?.description
        ? rowValue?.description
        : "",
    vendor_invoice_date: vendorInvoiceDate
      ? vendorInvoiceDate
      : rowValue?.vendor_invoice_date
        ? rowValue?.vendor_invoice_date
        : "",
    due_date: dueDate
      ? dueDate
      : rowValue?.dueDate
        ? rowValue?.dueDate
        : new Date(),
    owner: ownerValue
      ? userList && userList.find((data) => data.id == ownerValue)
      : (userList && userList.find((data) => data.id == rowValue?.owner_id)) ||
      "",
    reviewer: reviewer
      ? reviewer
      : rowValue?.reviewer_id
        ? userList && userList.find((data) => data.id == rowValue?.reviewer_id)
        : "",
    net_amount: netAmount
      ? netAmount
      : rowValue?.net_amount
        ? rowValue?.net_amount
        : netAmount,
    invoice_amount: invoiceAmount
      ? invoiceAmount
      : rowValue?.invoice_amount
        ? rowValue?.invoice_amount
        : "",
    returnedItemAmount:
      returnItemAmount === 0
        ? ""
        : returnItemAmount
          ? returnItemAmount
          : rowValue?.returned_items_amount
            ? rowValue.returned_items_amount
            : "",
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Delete Purchase"
        id={purchaseId}
        label={purchaseData}
        deleteFunction={delPurchase}
      />
      <Drawer
        modelTitle={rowValue?.id ? "Edit Purchase" : "New Purchase"}
        DrawerBody={addStoreForm}
        DrawerFooter={addStoreFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        handleOpenModal={openToogle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={storeModalOpen}
        buttonLabel={buttonLabel}
        enableReinitialize
      />
      <div className="d-flex justify-content-between">
        <PageTitle label="Purchases" />
        {activeTab === Tabs.PURCHASE && (
          <AddButton
            label="Add New"
            onClick={(_e) => {
              openToogle();
            }}
          />
        )}
      </div>

      <Nav tabs className="admin-tabs mb-1">
        <Link
          style={{ color: "inherit" }}
          onClick={(e) => e.preventDefault()}
          className="text-decoration-none"
        >
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tabs.PURCHASE,
              })}
              onClick={() => {
                tabToggle(Tabs.PURCHASE);
                _handleStatusChange(Tabs.PURCHASE);
              }}
            >
              Purchases
            </NavLink>
          </NavItem>
        </Link>

        <Link
          style={{ color: "inherit" }}
          onClick={(e) => e.preventDefault()}
          className="text-decoration-none"
        >
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tabs.PURCHASE_PRODUCTS,
              })}
              onClick={() => {
                tabToggle(Tabs.PURCHASE_PRODUCTS);
                _handleStatusChange(Tabs.PURCHASE_PRODUCTS);
              }}
            >
              Purchase Products
            </NavLink>
          </NavItem>
        </Link>
      </Nav>

      <TabContent activeTab={activeTab}>
        {activeTab === Tabs.PURCHASE && (
          <TabPane tabId={Tabs.PURCHASE}>
            <PurchaseListPage
              id={purchase.DRAFT_PURCHASE}
              history={history}
              toggleSidebar={toggleSidebar}
              setPurchaseId={setPurchaseId}
              setPurchaseData={setPurchaseData}
              setIsDeleteModel={setIsDeleteModel}
              draftCurrentPage={draftCurrentPage}
              draftCurrentPageSize={draftCurrentPageSize}
              setCurrentPage={setCurrentPage}
              StoreSelectModal={openToogle}
              setRowValue={setRowValue}
            />
          </TabPane>
        )}

        {activeTab === Tabs.PURCHASE_PRODUCTS && (
          <TabPane tabId={Tabs.PURCHASE_PRODUCTS}>
            <PurchaseProductList history={history} />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;
  // Get transferExpired count
  const DraftPurchase =
    reduxTable[purchase.DRAFT_PURCHASE] &&
      reduxTable[purchase.DRAFT_PURCHASE].isFetching == false
      ? reduxTable[purchase.DRAFT_PURCHASE].totalCount
      : 0;

  // Get transferReturn count
  const ReviewPurchase =
    reduxTable[purchase.REVIEW_PURCHASE] &&
      reduxTable[purchase.REVIEW_PURCHASE].isFetching == false
      ? reduxTable[purchase.REVIEW_PURCHASE].totalCount
      : 0;

  // Get transferAll count
  const allPurchase =
    reduxTable[purchase.ALL_PURCHASE] &&
      reduxTable[purchase.ALL_PURCHASE].isFetching == false
      ? reduxTable[purchase.ALL_PURCHASE].totalCount
      : 0;

  // Draft
  const draftCurrentPage =
    reduxTable[purchase.DRAFT_PURCHASE] &&
      !reduxTable[purchase.DRAFT_PURCHASE].isFetching
      ? reduxTable[purchase.DRAFT_PURCHASE].currentPage
      : 1;

  const draftCurrentPageSize =
    reduxTable[purchase.DRAFT_PURCHASE] &&
      !reduxTable[purchase.DRAFT_PURCHASE].isFetching
      ? reduxTable[purchase.DRAFT_PURCHASE].pageSize
      : 25;

  // Review
  const reviewCurrentPage =
    reduxTable[purchase.REVIEW_PURCHASE] &&
      !reduxTable[purchase.REVIEW_PURCHASE].isFetching
      ? reduxTable[purchase.REVIEW_PURCHASE].currentPage
      : 1;

  const reviewCurrentPageSize =
    reduxTable[purchase.REVIEW_PURCHASE] &&
      !reduxTable[purchase.REVIEW_PURCHASE].isFetching
      ? reduxTable[purchase.REVIEW_PURCHASE].pageSize
      : 25;

  // All
  const allCurrentPage =
    reduxTable[purchase.ALL_PURCHASE] &&
      !reduxTable[purchase.ALL_PURCHASE].isFetching
      ? reduxTable[purchase.ALL_PURCHASE].currentPage
      : 1;

  const allCurrentPageSize =
    reduxTable[purchase.ALL_PURCHASE] &&
      !reduxTable[purchase.ALL_PURCHASE].isFetching
      ? reduxTable[purchase.ALL_PURCHASE].pageSize
      : 25;

  return {
    DraftPurchase,
    ReviewPurchase,
    allPurchase,
    draftCurrentPage,
    draftCurrentPageSize,
    reviewCurrentPage,
    reviewCurrentPageSize,
    allCurrentPage,
    allCurrentPageSize,
  };
}

// Map Dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
