import { faCartShopping, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { Link } from "react-router-dom";
import { endpoints } from "../../../api/endPoints";
import AvatarCard from "../../../components/AvatarCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import StatusText from "../../../components/StatusText";
import { Order } from '../../../helpers/Order';
import { COOKIE_ORDER_LIST_AUTO_REFRESH } from '../../../lib/Cookie';
import Currency from "../../../lib/Currency";
import Cookie from '../../../lib/Helper';
import Number from '../../../lib/Number';

const OrderList = (props) => {

  const orderSortOptions = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "date:ASC",
      label: "Date",
    },
  ];

  const typeOptions = [
    {
      label: "Store",
      value: 1,
    },
    {
      label: "Delivery",
      value: 2,
    },
  ];

  let isVerifiedUpiPayment = (verifiedValue) => {
    if (Number.isNotNull(verifiedValue) || verifiedValue == 0) {
      return verifiedValue == Order.UPI_PAYMENT_VERIFIED ? (
        <FontAwesomeIcon
          icon={faCheck}
          size="2x"
          className="ml-2"
          style={{ color: "green" }}
        />
      ) : verifiedValue == Order.UPI_PAYMENT_NOT_VERIFIED ? (
        <FontAwesomeIcon
          icon={faXmark}
          size="2x"
          className="ml-2"
          style={{ color: "red" }}
        />
      ) : (
        ""
      );
    }
  };


  return (
    <>
      <ReduxTable
        id={"Orders"}
        showHeader
        newTableHeading
        apiURL={`${endpoints().orderAPI}/search`}
        searchPlaceholder="Search"
        params={props?.params}
        sortByOptions={orderSortOptions}
        paramsToUrl={true}
        history={props.history}
        bulkSelect={props?.bulkSelect}
        onBulkSelect={props?.onBulkSelect}
        selectedCheckBox={props.selectedCheckBox}
        autoRefresh={Cookie.get(COOKIE_ORDER_LIST_AUTO_REFRESH) == "true" ? true : false}
        ShowOrderFilter
        showStoreFilter
        showDateFilter
        showStatusFilter
        showUserFilter
        showBackgroundColor
        showShiftFilter
        showPaymentTypeFilter
        showTypeFilter
        customTypeOption={typeOptions}
        icon={<FontAwesomeIcon icon={faCartShopping} />}
        message="You can start by clicking on Add Order"
      >
        <ReduxColumn
          width="130px"
          minWidth="100px"
          maxWidth="90px"
          field="order_number"
          sortBy="order_number"
          type="link"
          isClickable="true"
          className="text-center"
          renderField={(row) => (
            <Link to={props?.pathName == "/deliveryOrders" ? `/deliveryOrder/${row.id}` : `/order/${row.id}`}
              className='link-opacity-75'
            >
              {row.order_number}
            </Link>
          )}
        >
          Order#
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          sortBy="date"
          renderField={(row) => (
            <span>{row.date}</span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="locationName"
          className="ellipsis"
          sortBy="locationName"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
        >
          Location
        </ReduxColumn>
        {props?.pathName == "/deliveryOrders" ? (
          <ReduxColumn
            field="assignee"
            width="310px"
            minWidth="310px"
            maxWidth="310px"
            className="ellipsis"
            sortBy="assignee"
            renderField={(row) => (
              <>
                <AvatarCard
                  firstName={row?.delivery_executive_firstName}
                  lastName={row?.delivery_executive_lastName}
                  url={row?.delivery_executive_media_url}
                />

              </>
            )}
          >
            Assignee
          </ReduxColumn>
        ) : (
          <ReduxColumn
            field="salesExecutive"
            width="310px"
            minWidth="310px"
            maxWidth="310px"
            className="ellipsis"
            sortBy="salesExecutive"
            renderField={(row) => (
              <>

                <AvatarCard
                  firstName={row?.salesExecutiveFirstName}
                  lastName={row?.salesExecutiveSecondName}
                  url={row?.salesExecutiveMediaUrl}
                />

              </>
            )}
          >
            Sales Executive
          </ReduxColumn>

        )}

        <ReduxColumn
          field="shift"
          className="ellipsis text-center"
          sortBy="shift"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Shift
        </ReduxColumn>
        <ReduxColumn
          field="type"
          className="ellipsis text-center"
          sortBy="payment_type"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Type
        </ReduxColumn>
        <ReduxColumn
          field="cash_amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          sortBy="cash_amount"
          className="text-right"
          renderField={(row) => (
            <span>{Currency.Format(row?.cash_amount)}</span>
          )}
        >
          Cash Amount
        </ReduxColumn>
        <ReduxColumn
          field="upi_amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-right"
          sortBy="upi_amount"
          renderField={(row) => (
            <span>{Currency.Format(row?.upi_amount)}{!row?.isLastRecord && isVerifiedUpiPayment(row?.upi_payment_verified)}</span>
          )}
        >
          UPI Amount
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-right"
          sortBy="total_amount"
          renderField={(row) => (
            <span>{Currency.Format(row.total_amount)}</span>
          )}
        >
          Total Amount
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy={"status"}
          width="130px"
          minWidth="130px"
          maxWidth="130px"
          className="column-status"
          renderField={(row) => (
            <StatusText backgroundColor={row?.statusDetail?.color_code} status={row?.status} />
          )}
        >
          Status
        </ReduxColumn>
      </ReduxTable>
    </>
  );
}

export default OrderList
