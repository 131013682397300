import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import FeatureImage from "../../components/Image";
import Status from "../../components/Status";
import CandidateForm from "./components/form";
import Action from "../../components/Action";

// Services
import CandidateService from "../../services/CandidateService";
import JobService from "../../services/JobService";

// Helper
import ObjectName from "../../helpers/ObjectName";
import { Jobs } from "../../helpers/Job";

const CandidateDetail = (props) => {
  const [details, setDetails] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [jobsDetail, setJobsDetail] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState();
  const [position, setPosition] = useState();
  const [gender, setGender] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    getCandidateDetail();
    getJobDetails();
  }, []);

  const genderOptions = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  const maritialStatusOptions = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const initialValues = {
    firstName: firstName ? firstName : details?.first_name || "",
    lastName: lastName ? lastName : details?.last_name || "",
    email:
      email === ""
        ? email
        : email
          ? email
          : details?.email
            ? details?.email
            : "",
    gender: gender
      ? gender
      : details?.gender
        ? genderOptions.find((data) => details?.gender == data.value)
        : "",
    skills: details?.skills || "",
    interviewDate: details?.interview_date || null,
    phone:
      phoneNumber === ""
        ? phoneNumber
        : phoneNumber
          ? phoneNumber
          : details?.phone || "",
    maritalStatus: maritalStatus
      ? maritalStatus
      : details?.marital_status
        ? maritialStatusOptions.find(
          (data) => details?.marital_status == data.value
        )
        : "",
    age: details?.age || null,
    position: position
      ? position
      : details?.position
        ? jobsDetail.find((data) => details?.position == data.label)
        : "",
    qualification: details?.qualification || "",
    currentAddress: details?.current_address || "",
    currentArea: details?.current_area || "",
    currentCountry: details?.current_country || "",
    currentCity: details?.current_city || "",
    currentState: details?.current_state || "",
    currentPincode:
      (details?.current_pincode && (details?.current_pincode).toString()) || "",
    permanentAddress: details?.permanent_address || "",
    permanentArea: details?.permanent_area || "",
    permanentCountry: details?.permanent_country || "",
    permanentCity: details?.permanent_city || "",
    permanentState: details?.permanent_state || "",
    permanentPincode:
      (details?.permanent_pincode && (details?.permanent_pincode).toString()) ||
      "",
    department: details?.department || "",
    yearOfPassing: details?.year_of_passing || "",
    over_all_experience: details?.overall_experience || "",
    projectTitle: details?.project_title || "",
    projectPeriod: details?.project_period || "",
    projectDescription: details?.project_description || "",
    courseName: details?.course_name || "",
    coursePeriod: details?.course_period || "",
    courseInstitution: details?.course_institution || "",
    currentSalary: details?.current_salary || null,
    expected_salary: details?.expected_salary || null,
    message: details?.message || "",
    percentage: details?.percentage || "",
    positionType: details?.position_type || "",
    dob: details?.dob || null,
    relevantExperience: details?.relevant_experience || null,
    expectedCostPerHour: details?.expected_cost_per_hour || null,
    jobReferenceType: details?.job_reference_type || "",
    jobReferenceName: details?.job_reference_name || "",
    willingToWorkInShift: details?.willing_to_work_in_shift || "",
    stayingWith: details?.staying_with || "",
    tenthPercentage: details?.tenth_percentage || "",
    tenthYearOfPassing: details?.tenth_year_of_passing || null,
    twelvethPercentage: details?.twelveth_percentage || "",
    twelvethYearOfPassing: details?.twelveth_year_of_passing || null,
    degreeArrear: details?.degree_arrear || "",
    didCourse: details?.did_course || false,
    didProject: details?.did_project || false,
    linkedinProfileName: details?.linkedin_profile_name || "",
    facebookProfileName: details?.facebook_profile_name || "",
    jobTitle: details?.job_title || "",
    joinedMonth: details?.joined_month || null,
    joinedYear: details?.joined_year || null,
    companyName: details?.company_name || "",
    knownLanguages: details?.known_languages || "",
    employmentEligibility: details?.employment_eligibility || "",
    didVaccineStatus: details?.vaccine_status || "",
  };

  const getCandidateDetail = async () => {
    const id = props.match.params.id;
    const response = await CandidateService.get(id);
    setDetails(response?.data?.data);
  };

  const getJobDetails = async () => {
    let response = await JobService.search({
      status: Jobs.STATUS_ACTIVE,
    });
    let data = response && response?.data && response?.data?.data;
    if (response && response?.data && response?.data?.data) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { job_title, id } = data[i];
        list.push({
          label: job_title,
          id: id,
          value: id,
        });
      }

      setJobsDetail(list);
    } else {
      setJobs([]);
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/job/dashboard" },
    {
      label: "Candidate",
      link: "/jobs/candidate",
    },
    {
      label: "Candidate Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  const handleDelete = async (id) => {
    dispatch(await CandidateService.delete(id));
    props.history.push("/jobs/candidate");
  };

  const handleSubmit = async (values) => {
    try {
      values.maritalStatus = values?.maritalStatus?.value;
      values.gender = values?.gender?.value;
      values.position = values?.position?.label;
      const id = props.match.params.id;
      await CandidateService.update(id, values);
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
  };

  const onStatusChange = async (values) => {
    const data = {};
    data.status = values;
    CandidateService.updateStatus(props.match.params.id, data);
  };

  const handleMaritalStatusChange = (e) => {
    let value = e?.values?.maritalStatus;
    setMaritalStatus(value);
  };

  const handlePositionChange = (e) => {
    let value = e?.values?.position;
    setPosition(value);
  };

  const handleGenderChange = (e) => {
    let value = e?.values?.gender;
    setGender(value);
  };

  const handleFirstNameChange = (e) => {
    let value = e?.target?.value;
    setFirstName(value);
  };

  const handleLastNameChange = (e) => {
    let value = e?.target?.value;
    setLastName(value);
  };

  const handlePhoneNumberChange = (e) => {
    let value = e;
    setPhoneNumber(value);
  };

  const handleEmailChange = (e) => {
    let value = e?.target?.value;
    setEmail(value);
  };

  return (
    <div className="mb-4 pb-4 mb-sm-0 pb-sm-0">
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label={"Candidate Details"} />
        <div className="d-flex">
          <div className="mx-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
          <Status
            objectName={ObjectName.CANDIDATE}
            handleChange={onStatusChange}
            buttonLabel={details?.status?.name}
            currentStatusId={details?.status?.id}
          />
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        title="Delete Candidate"
        toggle={() => {
          setDeleteModal(false);
        }}
        label={details?.first_name}
        deleteFunction={() => handleDelete(details.id)}
      />
      <div className="card p-3">
        <Form
          enableReinitialize
          initialValues={initialValues}
          // onSubmit={(values)=>onSubmit(values)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <div className="row">
            <div className="col-sm-7">
              <CandidateForm
                handlePositionChange={handlePositionChange}
                handleGenderChange={handleGenderChange}
                handleMaritalStatusChange={handleMaritalStatusChange}
                handleFirstNameChange={handleFirstNameChange}
                handleLastNameChange={handleLastNameChange}
                handlePhoneNumberChange={handlePhoneNumberChange}
                handleEmailChange={handleEmailChange}
              />
              <div className="d-flex">
                <SaveButton />
                <CancelButton
                  onClick={() => props.history.push("/jobs/candidate")}
                />
              </div>
            </div>
            <div className="pt-4 mt-2 col-sm-5">
              <FeatureImage
                src={details?.image_url}
                alt="Feature product image"
                size={"large"}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CandidateDetail;
