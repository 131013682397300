import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";




class AttendanceTypeService {

    static create (data,callback){
        apiClient
        .post(`${endpoints().attendanceTypeAPI}/create`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(successMessage)
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    }

    static update (id,data,callback){
        apiClient
        .put(`${endpoints().attendanceTypeAPI}/update/${id}`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(successMessage)
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    }

    static delete (id,callback){
        apiClient
        .delete(`${endpoints().attendanceTypeAPI}/delete/${id}`)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(successMessage)
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    }

    static async get(id) {
        let { data } = await apiClient
            .get(`${endpoints().attendanceTypeAPI}/${id}`)
        return data && data?.data
    }

    static async list() {
      try {
        let list = new Array();
        let response = await apiClient
        .get(`${endpoints().attendanceTypeAPI}`)
          let data = response?.data?.data;
          if (data && data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                list.push({
                  label:data[i]?.name, 
                  value:data[i]?.id, 
                  type:data[i]?.type,
                  is_leave:data[i]?.is_leave,
                  is_working_day:data[i]?.is_working_day,
                  is_additional_leave:data[i]?.is_additional_leave,
                  is_additional_shift:data[i]?.is_additional_shift,
                  is_additional_day:data[i]?.is_additional_day,
                  is_absent:data[i]?.is_absent,
                  days_count:data[i]?.days_count,
                });
              }
          }
          return list;
      } catch (err) {
        console.log(err);
      }
    }
    
}

export default AttendanceTypeService;