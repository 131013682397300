import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

// Components
import Form from '../../components/Form';
import Text from '../../components/Text';
import StatusSelect from '../../components/SelectStatus';
import PageTitle from '../../components/PageTitle';
import SaveButton from '../../components/SaveButton';
import DeleteModal from '../../components/DeleteModal';
import AddButton from '../../components/AddButton';
import DeviceList from '../mobileApp/deviceList';
import Settings from '../setting/components/Message';
import VersionTab from './components/versionTab';
import DeleteButton from '../../components/DeleteButton';
import BreadCrumb from '../../components/Breadcrumb';
import FeatureTab from './components/FeatureTab';
import Action from '../../components/Action';

// Helpers
import App from '../../helpers/App';

// Services
import DeviceInfoService from '../../services/DeviceInfoService';
import AppService from '../../services/AppService';

// Actions
import { fetchList } from '../../actions/table';

// API
import { endpoints } from '../../api/endPoints';

export const Type = {
  MOBILE: "Mobile",
};

const Tab = {
  GENERAL_TAB: "General",
  FEATURE_TAB: "Feature",
  VERSIONS: "Versions",
  DEVICES: "Devices",
  SETTINGS: "Settings",
};

const appDetailPage = (props) => {
  const [detail, setDetail] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Tab.GENERAL_TAB);
  const [isTypeModelOpen, setIsTypeModelOpen] = useState(false);
  const [versionDeleteModal, setVersionDeleteModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    getDetail();
  }, []);

  let getDetail = async () => {
    await AppService.get(
      { params: { id: props?.match?.params?.id } },
      (res) => {
        setDetail(res && res?.data);
      }
    );
  };

  const onBulkSelect = (ids) => {
    setSelectedIds({ selectedIds: ids });
  };

  const deviceDelete = async () => {
    try {
      await dispatch(DeviceInfoService.delete(selectedIds));
      dispatch(
        fetchList(
          "deviceInfo",
          `${endpoints().userDeviceInfo}/search`,
          1,
          25,
          {}
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const toggle = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${activeTab}${tab == Tab.VERSIONS ? `&status=Active`:"" }`);
  };

  const _toggles = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
  };

  let customStatusOption = [
    {
      label: App.STATUS_ACTIVE_TEXT,
      value: App.STATUS_ACTIVE,
    },
    {
      label: App.STATUS_INACTIVE_TEXT,
      value: App.STATUS_INACTIVE,
    },
  ];

  const actionsMenuList = [
    {
      value: "Bulk Update",
      label: "Bulk Update",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "Bulk Update") {
      if (
        selectedIds &&
        selectedIds.selectedIds &&
        selectedIds.selectedIds.length > 0
      ) {
        setProjectDeleteModal(true);
      }
    }
  };

  let initialValues = {
    name: detail?.name ? detail?.name : "",
    nameSpace: detail?.name_space ? detail?.name_space : "",
    status: customStatusOption.find((data) => data?.value == detail?.status),
  };

  const handleSubmit = async (values) => {
    try {
      let data = new FormData();
      data.append("name", values && values?.name ? values?.name : "");
      data.append(
        "nameSpace",
        values && values?.nameSpace ? values?.nameSpace : ""
      );
      data.append(
        "status",
        values && values?.status ? values?.status?.value : ""
      );
      data.append("id", props?.match?.params?.id);
      dispatch(await AppService.update(data));
    } catch (error) {
      console.log(error);
    }
  };

  const closeDeleteToggle = () => {
    setIsDeleteOpen(false);
  };

  const handleDelete = async (value) => {
    dispatch(
      await AppService.delete(
        { data: { id: props?.match?.params?.id } },
        (res) => {
          props.history.push("/setting/Apps");
        }
      )
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Apps", link: "/setting/Apps" },
    { label: "App Detail", link: "" },
  ];

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <DeleteModal
        isOpen={activeTab === Tab.DEVICES ? versionDeleteModal : isDeleteOpen}
        toggle={
          activeTab === Tab.DEVICES
            ? () => {
                setVersionDeleteModal(false);
              }
            : closeDeleteToggle
        }
        title={activeTab === Tab.DEVICES ? "Delete Devices" : "Delete Image"}
        label={activeTab !== Tab.DEVICES && detail?.name}
        deleteFunction={activeTab === Tab.DEVICES ? deviceDelete : handleDelete}
      />
      <div className="d-flex justify-content-between">
        <PageTitle label={detail && detail?.name} />
        {activeTab === Tab.GENERAL_TAB && (
          <DeleteButton
            label="Delete"
            onClick={() => {
              setIsDeleteOpen(true);
            }}
          />
        )}
        {activeTab === Tab.VERSIONS && (
          <AddButton
            label="Add New"
            onClick={(e) => {
              _toggles();
            }}
          />
        )}
        {activeTab === Tab.DEVICES && (
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        )}
      </div>

      <Nav tabs className="admin-tabs">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.GENERAL_TAB,
            })}
            onClick={() => {
              setActiveTab(Tab.GENERAL_TAB);
            }}
          >
            {Tab.GENERAL_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.VERSIONS,
            })}
            onClick={() => {
              toggle(Tab.VERSIONS);
            }}
          >
            {Tab.VERSIONS}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.DEVICES,
            })}
            onClick={() => {
              toggle(Tab.DEVICES);
            }}
          >
            {Tab.DEVICES}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.SETTINGS,
            })}
            onClick={() => {
              toggle(Tab.SETTINGS);
            }}
          >
            {Tab.SETTINGS}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.FEATURE_TAB,
            })}
            onClick={() => {
              setActiveTab(Tab.FEATURE_TAB);
            }}
          >
            {Tab.FEATURE_TAB}
          </NavLink>
        </NavItem>
      </Nav>

      {activeTab === Tab.GENERAL_TAB && (
        <div className="card card-body">
          <Form
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            <Text name="name" label="Name" required />
            <Text name="nameSpace" label="Name Space" required />
            <StatusSelect
              name="status"
              label="Status"
              customStatusOption={customStatusOption}
              required
            />
            <SaveButton />
          </Form>
        </div>
      )}
      {activeTab === Tab.VERSIONS && (
        <VersionTab
          nameFieldLabel={"Version"}
          app_id={props?.match?.params?.id}
          noTagDetail
          showPageTitle
          __toggle={_toggles}
          isModel={isTypeModelOpen}
          history={props?.history}
          showTagTypefield={true}
          tagType={Type.MOBILE}
          mobileApp
          columnName="Version"
          label="Version"
          props={props}
          customTypeOption={[
            {
              label: Type.MOBILE,
              value: Type.MOBILE,
            },
          ]}
          hideQueryParams={{
            type: true,
          }}
        />
      )}
      {activeTab === Tab.DEVICES && (
        <DeviceList
          setProjectDeleteModal={setVersionDeleteModal}
          onBulkSelect={onBulkSelect}
          history={props?.history}
          params={{ app_id: props?.match?.params?.id }}
        />
      )}
      {activeTab === Tab.SETTINGS && (
        <Settings
          history={props.history}
          toggle={toggle}
          app_id={props?.match?.params?.id}
        />
      )}

      {activeTab == Tab.FEATURE_TAB && (
        <FeatureTab app_id={props?.match?.params?.id} />
      )}
    </>
  );
};

export default appDetailPage;
