import React from "react";
import { endpoints } from "../../api/endPoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import { Link } from "react-router-dom";
import DateTime from "../../lib/DateTime";
import AvatarCard from "../../components/AvatarCard";
import Currency from "../../lib/Currency";
import StatusText from "../../components/StatusText";

const invoiceTab = (props) => {
  const orderSortOptions = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "date:ASC",
      label: "Date",
    },
  ];

  const typeOptions = [
    {
      label: "Store",
      value: 1,
    },
    {
      label: "Delivery",
      value: 2,
    },
  ];

  return (
    <>
      <ReduxTable
        id={"invoice"}
        showHeader
        newTableHeading
        apiURL={`${endpoints().invoiceApi}`}
        searchPlaceholder="Search"
        params={{ showTotal: true }}
        sortByOptions={orderSortOptions}
        paramsToUrl={true}
        history={props.history}
        autoRefresh
        ShowOrderFilter
        showStoreFilter
        showDateFilter
        showStatusFilter
        showUserFilter
        showShiftFilter
        showPaymentTypeFilter
        showTypeFilter
        customTypeOption={typeOptions}
        icon={<FontAwesomeIcon icon={faCartShopping} />}
        message="You can start by clicking on Add Order"
        showBackgroundColor
      >
        <ReduxColumn
          width="130px"
          minWidth="100px"
          maxWidth="90px"
          field="id"
          sortBy="id"
          isClickable="true"
          className="text-center"
        >
          Invoice#
        </ReduxColumn>
        <ReduxColumn
          width="100px"
          className="text-center"
          minWidth="90px"
          maxWidth="90px"
          sortBy="date"
          renderField={(row) => (
            <span>{DateTime.getDateTimeByUserProfileTimezone(row.date)}</span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="locationName"
          className="ellipsis"
          sortBy="locationName"
        >
          Location
        </ReduxColumn>
        {props?.pathName == "/deliveryOrders" ? (
          <ReduxColumn
            field="assignee"
            className="ellipsis"
            sortBy="assignee"
            renderField={(row) => (
              <>
                <AvatarCard
                  firstName={row?.delivery_executive_firstName}
                  lastName={row?.delivery_executive_lastName}
                  url={row?.delivery_executive_media_url}
                />
              </>
            )}
          >
            Assignee
          </ReduxColumn>
        ) : (
          <ReduxColumn
            field="salesExecutive"
            className="ellipsis"
            sortBy="salesExecutive"
            renderField={(row) => (
              <>
                <AvatarCard
                  firstName={row?.salesExecutiveFirstName}
                  lastName={row?.salesExecutiveSecondName}
                  url={row?.salesExecutiveMediaUrl}
                />
              </>
            )}
          >
            Sales Executive
          </ReduxColumn>
        )}

        <ReduxColumn
          field="shift"
          className="ellipsis text-center"
          sortBy="shift"
        >
          Shift
        </ReduxColumn>
        <ReduxColumn
          field="type"
          className="ellipsis text-center"
          sortBy="payment_type"
        >
          Type
        </ReduxColumn>
        <ReduxColumn
          field="cash_amount"
          width="90px"
          minWidth="100px"
          sortBy="cash_amount"
          className="text-end"
          renderField={(row) => (
            <span>{Currency.Format(row?.cash_amount)}</span>
          )}
        >
          Cash Amount
        </ReduxColumn>
        <ReduxColumn
          field="upi_amount"
          width="90px"
          minWidth="100px"
          className="text-end"
          sortBy="upi_amount"
          renderField={(row) => <span>{Currency.Format(row?.upi_amount)}</span>}
        >
          UPI Amount
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          width="90px"
          minWidth="100px"
          className="text-end"
          sortBy="total_amount"
          renderField={(row) => (
            <span>{Currency.Format(row.total_amount)}</span>
          )}
        >
          Total Amount
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          width={"120px"}
          minWidth="120px"
          maxWidth="120px"
          className="column-status"
          renderField={(row) => (
            <StatusText
              backgroundColor={row?.statusDetail?.color_code}
              status={row?.status}
            />
          )}
        >
          Status
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default invoiceTab;
