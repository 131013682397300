import React, { useEffect, useState } from "react";
import TimeSelector from "../../components/TimeSelector";
import MultiSelect from "../../components/MultiselectCreatable";
import Select from "../../components/Select";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import {
  RecurringTaskType,
  dateOption,
  dateTypeOption,
  monthOption,
  typeOptions,
} from "../../helpers/recurringTask";
import DateTime from "../../lib/DateTime";
import { SchedulerApiUrl, schedulerJob } from "./constants";
import SchedulerJobService from "../../services/SchedulerJobService";
import SlackService from "../../services/SlackService";
import DateSelector from "../../components/Date";
import ObjectName from "../../helpers/ObjectName";
import StatusSelect from "../../components/SelectStatus";

function Checkbox({ day, checked, onChange }) {
  return (
    <label>
      <input
        className="me-2 ms-2"
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(day, e.target.checked)}
      />
      {day}
    </label>
  );
}

const SchedulerJobForm = (props) => {
  const {
    handleSchedulerChange,
    handleNameChange,
    handleStatusChange,
    handleEmailChange,
    handleNotesChange,
    handleSlackchange,
    handleTypeChange,
    row,
    type,
    selectedDays,
    handleIntervalOnchange,
    handleCheckboxChange,
    handleMonthChange,
    handleDate,
    handleStartTimeChange,
    handleEndTimeChange,
    handleEndDate,
    handleStartDate,
    objectNameChange,
    handleDateTypeChange,
    handleObjectStatusChange,
    objectName,
    schedulerName
  } = props;

  const [slackChannelList, setSlackChannelList] = useState();
  const [dateOptions, setDataOption] = useState([]);
  const [values, setValues] = useState([]);
  const statusOptions = [
    {
      label: schedulerJob.STATUS_ACTIVE_TEXT,
      value: schedulerJob.STATUS_ACTIVE,
    },
    {
      label: schedulerJob.STATUS_INACTIVE_TEXT,
      value: schedulerJob.STATUS_INACTIVE,
    },
  ];

  useEffect(() => {
    getDateOptions();
  }, []);

  const getDateOptions = async () => {
    let dateOptions = dateOption();
    setDataOption(dateOptions);
  };

  useEffect(() => {
    getSlackChannelList();
  }, []);
  useEffect(() => {
    getList();
  }, []);

  const getSlackChannelList = async () => {
    try {
      //set loader

      //create new array fro slack channel
      let response = await SlackService.getChannelList();

      if (response && response.channels) {
        let channel = [];

        let channelList = response.channels;

        if (channelList && channelList.length > 0) {
          for (let i in channelList) {
            let { id, name } = channelList[i];
            channel.push({
              label: name,
              value: id,
              id: id,
            });
          }
        }
        //set channel list
        setSlackChannelList(channel);
      }
    } catch (err) {}
  };
  const getList = async () => {
    try {
      let matchingValues = [];
      let list = await SchedulerJobService.search();

      SchedulerApiUrl.forEach((item) => {
        let data = list.find((job) => job.job_name === item.label);
        if (data) {
          let labelContent = (
            <div className="w-100 d-flex justify-content-between">
              <div>{data.job_name}</div>
              <div className="text-danger">(Added)</div>
            </div>
          );

          matchingValues.push({
            value: data.api_url,
            label: labelContent,
            name: data.job_name,
          });
        } else {
          matchingValues.push({
            value: item.value,
            label: item.label,
            name: item.label,
          });
        }
      });
      setValues(matchingValues);
    } catch (error) {
      console.error("Error occurred while fetching the job list:", error);
      return [];
    }
  };

  const handleObjectNameChange =({values})=>{
    objectNameChange && objectNameChange(values?.object_name?.value)
  }
  return (
    <div className=" field-wrapper row">
      <div className=" col-lg-12 col-sm-6">
        <Select
          name="job"
          placeholder="Select Scheduler Job"
          label="Scheduler Job"
          options={row?.id ? SchedulerApiUrl : values}
          onInputChange={handleSchedulerChange}
          required
        />
        <Text
          name="name"
          label="Name"
          placeholder="Name"
          onChange={handleNameChange}
          required
        />
        <Select
          name="status"
          placeholder="Select status"
          label="Status"
          options={statusOptions}
          handleChange={handleStatusChange}
          required
        />
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="w-100">
              <TimeSelector
                label="Start Time"
                name="startTime"
                isClearable={true}
                onChange={handleStartTimeChange}
              />
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="w-100">
              <TimeSelector
                label="End Time"
                name="endTime"
                isClearable={true}
                onChange={handleEndTimeChange}
              />
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <Select
              name="interval"
              placeholder="Select"
              onInputChange={handleIntervalOnchange}
              label="Interval"
              options={DateTime.getSchedulerTime()}
            />
          </div>
        </div>

        <div className="mb-3 w-100">
          <MultiSelect
            name="to_email"
            placeholder="Enter To Email"
            label="To Email"
            onInputChange={handleEmailChange}
          />
        </div>
        <Select
          name="to_slack"
          label={"To Slack"}
          placeholder="Select Slack Channel"
          options={slackChannelList}
          onInputChange={handleSlackchange}
        />
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="w-100">
              <DateSelector
                name="start_date"
                label={"Start Date"}
                placeholder="Select Start Date"
                isClearable
                onChange={handleStartDate}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="w-100">
              <DateSelector
                name="end_date"
                label={"End Date"}
                placeholder="Select End Date"
                isClearable
                onChange={handleEndDate}
              />
            </div>
          </div>
        </div>

        <Select
          name="taskType"
          label="Recurring Type"
          placeholder="Select Task"
          options={typeOptions}
          handleChange={handleTypeChange}
        />

        {type == RecurringTaskType.WEEKLLY ||
        (row?.type == RecurringTaskType.WEEKLLY && !type) ? (
          <div className="d-flex justify-content-between">
            <Checkbox
              day="Monday"
              checked={selectedDays.includes("Monday")}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              day="Tuesday"
              checked={selectedDays.includes("Tuesday")}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              day="Wednesday"
              checked={selectedDays.includes("Wednesday")}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              day="Thursday"
              checked={selectedDays.includes("Thursday")}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              day="Friday"
              checked={selectedDays.includes("Friday")}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              day="Saturday"
              checked={selectedDays.includes("Saturday")}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              day="Sunday"
              checked={selectedDays.includes("Sunday")}
              onChange={handleCheckboxChange}
            />
          </div>
        ) : (
          ""
        )}

        {type == RecurringTaskType.ANNUALLY ||
        (row?.type == RecurringTaskType.ANNUALLY && !type) ? (
          <>
            <Select
              name="month"
              label="Month"
              options={monthOption}
              onInputChange={handleMonthChange}
            />
          </>
        ) : (
          ""
        )}
        {type == RecurringTaskType.MONTHLY ||
        type == RecurringTaskType.ANNUALLY ||
        (row?.type == RecurringTaskType.MONTHLY && !type) ||
        (row?.type == RecurringTaskType.ANNUALLY && !type) ? (
          <>
            <Select
              name="date"
              label="Date"
              options={dateOptions}
              onInputChange={handleDate}
            />
          </>
        ) : (
          ""
        )}

      {(schedulerName?.value == "/v1/scheduler/order/orderReport" || (!schedulerName && row?.api_url == "/v1/scheduler/order/orderReport") ) &&  <div className="row">
          <div className="col">
            <Select
              name="date_type"
              label="Date Type"
              options={dateTypeOption}
            onInputChange={handleDateTypeChange}
            />
          </div>
          <div className="col">
            <Select
              name="object_name"
              label="Object Name"
              options={ObjectName.Options}
            onInputChange={handleObjectNameChange}
            />
          </div>
         {(objectName || row?.object_status || row?.object_name) && <div className="col">
            <StatusSelect
              name="object_status"
              label="Object Status"
              placeholder="Select Object Status"
              objectName={objectName}
              handleStatusChange={handleObjectStatusChange}
            />
          </div>}
        </div>}

        <TextArea label="Notes" name="notes" onChange={handleNotesChange} />
      </div>
    </div>
  );
};
export default SchedulerJobForm;
