import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import AddButton from "../../components/AddButton";
import PageTitle from "../../components/PageTitle";
import Drawer from "../../components/Drawer";
import SaveButton from "../../components/SaveButton";
import UserCard from "../../components/UserCard";
import DeleteModal from "../../components/DeleteModal";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import VisitorForm from "./visitorForm";

// Services
import visitorService from "../../services/VisitorService";

// Lib
import Numbers from "../../lib/Number";
import Url from "../../lib/Url";

// Helpers
import { TagTypeName } from "../../helpers/Tag";

// API
import { endpoints } from "../../api/endPoints";

const Visitor = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isSubmit, setIsSubmit] = useState(true);
  const [userList, setUserList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most recent",
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
  };

  const onDrop = (selectedFile) => {
    setSelectedFile(selectedFile && selectedFile[0]);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    setSelectedFile("");
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const visitorForm = (
    <VisitorForm
      onDrop={onDrop}
      setUserList={setUserList}
      selectedFile={selectedFile}
      handleDelete={handleDelete}
      handleTypeChange={handleTypeChange}
      selectedType={selectedType}
      rowValue={rowValue}
      showUserDetailsPageLink={
        rowValue?.id && rowValue?.person_to_meet ? true : false
      }
      userId={rowValue?.id ? rowValue?.person_to_meet : null}
    />
  );

  const initialValues = {
    visitor: rowValue ? rowValue?.name : "",
    mobileNumber: rowValue ? rowValue?.phone : "",
    purpose: rowValue ? rowValue?.purpose : "",
    title: rowValue ? rowValue?.title : "",
    notes: rowValue ? rowValue?.notes : "",
    type: rowValue
      ? {
        value: rowValue?.type,
        label: rowValue?.typeName,
      }
      : "",
    person_to_meet: rowValue
      ? userList && userList.find((data) => data.id == rowValue?.person_to_meet)
      : "",
    date: rowValue?.created_at ? rowValue?.created_at : "",
  };

  const visitorFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit == false}
      label={rowValue?.id ? "Save" : "Add"}
    />
  );

  const params = {
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    visitorType: Url.GetParam("visitorType"),
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmit(true);
      const data = new FormData();
      data.append("name", values?.visitor);
      data.append("mobileNumber", values?.mobileNumber);
      data.append("purpose", values?.purpose);
      data.append("title", values?.title);
      data.append("notes", values?.notes);
      data.append("type", Numbers.Get(values?.type?.value));
      data.append("person_to_meet", values?.person_to_meet?.id);

      data.append("media_file", selectedFile ? selectedFile : "");
      dispatch(
        await visitorService.add(data, params, () => {
          toggle();
          setIsSubmit(false);
          setSelectedFile("");
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const toggleModelClose = () => {
    setRowValue("");
    setSelectedFile("");
    setSelectedType("");
    toggle();
    setIsSubmit(true);
  };

  const handleUpdate = async (values) => {
    const data = new FormData();
    data.append("name", values?.visitor ? values?.visitor : "");
    data.append("phone", values?.mobileNumber ? values?.mobileNumber : "");
    data.append("purpose", values?.purpose ? values?.purpose : "");
    data.append(
      "person_to_meet",
      values?.person_to_meet ? values?.person_to_meet?.id : ""
    );
    data.append("title", values?.title ? values?.title : "");
    data.append("notes", values?.notes ? values?.notes : "");
    data.append(
      "type",
      Numbers.Get(values?.type?.value ? values?.type?.value : "")
    );
    dispatch(
      await visitorService.update(
        rowValue?.id,
        data,
        params,
        {},
        {},
        toggle,
        dispatch
      )
    );
  };

  const deleteFunction = async () => {
    dispatch(
      await visitorService.delete(rowValue?.id, null, {
        ...params,
      })
    );
    setRowValue("");
    setIsDeleteOpen(false);
  };
  return (
    <>
      <DeleteModal
        isOpen={isDeleteOpen}
        toggle={() => {
          setIsDeleteOpen(false);
        }}
        title="Delete Visitor"
        id={rowValue?.id}
        label={rowValue?.name}
        deleteFunction={deleteFunction}
      />
      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggleModelClose}
        isModalOpen={isOpen}
        enableReinitialize
        initialValues={initialValues}
        DrawerBody={visitorForm}
        DrawerFooter={visitorFooter}
        modelTitle={`${rowValue?.id ? "Edit" : "Add"} Visitor`}
        onSubmit={(values) => {
          if (rowValue?.id) {
            handleUpdate(values);
          } else {
            handleSubmit(values);
          }
        }}
      />
      <div className="d-flex justify-content-between pb-3">
        <PageTitle label="Visitors" />
        <div className="d-flex">
          <AddButton
            label="Add New"
            onClick={() => {
              toggle();
              setRowValue("");
              setSelectedType("");
            }}
          />
        </div>
      </div>

      <ReduxTable
        id="visitors"
        showHeader
        searchPlaceholder="Search"
        newTableHeading
        icon={<FontAwesomeIcon icon={faCubes} />}
        message="You can start by clicking on Add New"
        apiURL={`${endpoints().visitor}/search`}
        sortByOptions={sortByOption}
        showVisitorTypeFilter
        tagPlaceholder={"Visitor Type"}
        tagFilterType={{ type: TagTypeName.VISITOR_TYPE }}
        paramsToUrl={true}
        history={props.history}
      >
        <ReduxColumn
          className="text-start"
          width="210px"
          sortBy="name"
          minWidth="210px"
          maxWidth="210px"
          renderField={(row) => (
            <Link to={`/visitor/${row.id}`} className="link-opacity-75 text-decoration-none">
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.name}
                url={row.media_url}
                mobileNumber={row.phone}
              />
            </Link>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="purpose"
          width="170px"
          minWidth="170px"
          maxWidth="170px"
          sortBy="purpose"
        >
          Purpose
        </ReduxColumn>
        <ReduxColumn
          field="created_at"
          sortBy="created_at"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>
              {(row.created_at)}
            </span>
          )}
        >
          Created At
        </ReduxColumn>
        <ReduxColumn
          field="typeName"
          sortBy="typeName"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"        >
          Type
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      toggle();
                      setRowValue(row);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      setRowValue(row);
                      setIsDeleteOpen(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default Visitor;
