import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";

// Components
import Form from "../../components/Form";
import UserSelect from "../../components/UserSelect";
import Avatar from "../../components/Avatar";
import BreadCrumb from "../../components/Breadcrumb";
import AddModal from "../../components/Modal";
import SaveButton from "../../components/SaveButton";
import AddButton from "../../components/AddButton";
import TitleWithStatusBadge from "../../components/TitleWithStatusBadge";
import Image from "../../components/static/image";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ShareModal from "./components/ShareModal";
import Action from "../../components/Action";
import LeaveList from "./components/LeaveList";
import VerticalSapce from "../../components/VerticalSapce";
import StoreNotAssignedUserList from "./components/StoreNotAssignedUserList";

// Services
import ShiftService from "../../services/ShiftService";
import LocationAllocationService from "../../services/LocationAllocationService";
import LocationAllocationUserService from "../../services/LocationAllocationUserService";
import StoreService from "../../services/StoreService";
import CompanyUserService from "../../services/UserService";

// Helpers
import Status from "../../helpers/LocationAllocationUser";

// Lib
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";
import String from "../../lib/String";

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #292934;
    color: white;
    font-weight: bold;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  td {
    background-color: #fff;
    color: #333;
  }
`;

const DropdownList = styled.div`
  position: relative;
  z-index: 10;
`;

const LocationAllocationDetailPage = (props) => {
  const [shiftList, setShiftList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [detail, setDetail] = useState([]);
  const [userList, setUserList] = useState([]);
  const [locationAllocationDetail, setLocationAllocationDetail] = useState({});
  const [allowedShiftIds, setAllowedShiftIds] = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [rowValue, setRowValue] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  let dispatch = useDispatch();
  useEffect(() => {
    getShifts();
    getLocations();
    getDetail();
    getUsers();
    getLocationAllocationDetail();
  }, []);
  const getLocationAllocationDetail = async () => {
    await LocationAllocationService.get(
      props?.match?.params?.id,
      ({ data }) => {
        setLocationAllocationDetail(data);
      }
    );
  };
  const getShifts = async () => {
    let {
      data: { data },
    } = await ShiftService.list();
    setShiftList(data);
  };
  const getLocations = async () => {
    let params = {
      type: 3,
    };
    await StoreService.list((res) => {
      let shiftIds = [];
      if (res && res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          const { allowedShift } = res[i];
          let allowedShiftIds = allowedShift ? allowedShift?.split(",") : [];
          shiftIds.push(...allowedShiftIds);
        }
      }
      setAllowedShiftIds(shiftIds);
      setLocationList(res);
    }, params);
  };
  const getUsers = async () => {
    const users = await CompanyUserService.getOption({});
    setUserList(users);
  };
  const getDetail = async () => {
    let response = await LocationAllocationUserService.search({
      location_allocation_id: props?.match?.params?.id,
    });
    let data = [];
    if (response && response?.data && response?.data.length > 0) {
      for (let i = 0; i < response?.data.length; i++) {
        const value = response?.data[i];
        data.push(value);
      }
    }
    setDetail(data);
  };
  let initialValues = (location, shift) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id === location.id && data.shift_id === shift.id
        )) ||
      null;
    let userValue =
      (ArrayList.isArray(userList) &&
        userList.find((data) => data?.id === isDetail?.user_id)) ||
      null;
    return {
      user: userValue,
    };
  };
  let isRecord = (location, shift) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id === location.id &&
            data.shift_id === shift.id &&
            data.user_id !== null
        )) ||
      null;
    return isDetail ? true : false;
  };
  let handleStatusChange = async (location, shift, status) => {
    let data = new FormData();
    data.append("location_allocation_id", props?.match?.params?.id);
    data.append("location_id", location?.id);
    data.append("shift_id", shift?.id);
    data.append("status", status);
    dispatch(
      await LocationAllocationUserService.updateStatus(data, (res) => {
        getDetail();
      })
    );
  };
  const handleUserSelect = async (location, shift, selectedUser) => {
    let data = new FormData();
    data.append("location_allocation_id", props?.match?.params?.id);
    data.append("location_id", location?.id);
    data.append("shift_id", shift?.id);
    data.append("user_id", selectedUser ? selectedUser : null);
    dispatch(
      await LocationAllocationUserService.create(data, (res) => {
        getDetail();
        setIsOpenModel(false);
        setRowValue({});
      })
    );
  };

  const handleResetToDefault = async () => {
    dispatch(
      await LocationAllocationUserService.resetToDefault(
        props?.match?.params?.id,
        (res) => {
          if (res) {
            getDetail();
            setIsOpenModel(false);
          }
        }
      )
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    {
      label: "Location Allocation",
      link: "/locationAllocation",
    },
    {
      label: "Location Allocation Detail",
      link: "",
    },
  ];
  let isValidate = (location, shift) => {
    let isAllowedShift = location?.allowedShift?.includes(
      shift?.id?.toString()
    );
    return isAllowedShift;
  };
  let avatarCardProps = (location, shift) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id === location.id && data.shift_id === shift.id
        )) ||
      null;
    let userValue =
      (ArrayList.isArray(userList) &&
        userList.find((data) => data?.id === isDetail?.user_id)) ||
      null;
    return {
      firstName: userValue?.first_name,
      lastName: userValue?.last_name,
      url: userValue?.media_url,
    };
  };
  let statusProps = (location, shift) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id === location.id && data.shift_id === shift.id
        )) ||
      null;
    let color =
      isDetail && isDetail?.status === Status.STATUS_PENDING
        ? "red"
        : isDetail && isDetail?.status === Status.STATUS_CONFIRMED
          ? "green"
          : "";
    let statusName =
      isDetail && isDetail?.status === Status.STATUS_PENDING
        ? "Pending"
        : isDetail && isDetail?.status === Status.STATUS_CONFIRMED
          ? "Confirmed"
          : "";
    return { backgroundColor: color, status: statusName };
  };

  let isPendingStatus = (location, shift) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id === location.id && data.shift_id === shift.id
        )) ||
      null;
    let isPending =
      isDetail && isDetail?.status === Status.STATUS_PENDING ? true : false;
    return isPending;
  };

  const closeToggle = () => {
    setIsOpenModel(!isOpenModel);
    setRowValue({});
  };

  const filteredUserList =
    ArrayList.isArray(userList) &&
    userList.filter(
      (obj) => !detail?.map((data) => data?.user_id)?.includes(obj?.id)
    );

  let modalBody = (
    <>
      <UserSelect required customUserOption={filteredUserList} />
    </>
  );
  let modalFooter = (
    <>
      <SaveButton label={rowValue?.isAdd ? "Add" : "Change"} />
    </>
  );
  const filteredShifts =
    ArrayList.isArray(shiftList) &&
    shiftList?.filter((shift) =>
      allowedShiftIds?.includes(shift?.id?.toString())
    );

  let actionsMenuList = [
    {
      label: "Reset to Default",
      value: "Reset to Default",
    },
  ];

  const handleActionChange = (actionValue) => {
    if (actionValue == "Reset to Default") {
      handleResetToDefault();
    }
  };

  return (
    <>
      <ShareModal
        locationList={locationList}
        filteredShifts={filteredShifts}
        date={locationAllocationDetail?.date}
        isRecord={isRecord}
        detail={detail}
        userList={userList}
        visible={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
      />
      <AddModal
        modalTitle={rowValue?.isAdd ? "Add User" : "Change User"}
        modalBody={modalBody}
        isOpen={isOpenModel}
        initialValues={{
          user: "",
        }}
        toggle={closeToggle}
        modalFooter={modalFooter}
        toggleModalClose={closeToggle}
        hideDefaultButtons
        onSubmit={(values) => {
          handleUserSelect(
            rowValue?.location,
            rowValue?.shift,
            values && values?.user?.id
          );
        }}
      />
      <BreadCrumb list={breadcrumbList} />
      <TableContainer>
        <TitleWithStatusBadge
          badgeColor={locationAllocationDetail?.statusColor}
          badgeLabel={locationAllocationDetail?.statusName}
          title={`Date: ${DateTime.getDate(locationAllocationDetail?.date)}`}
        >
          <div className="d-flex">
            <div className="">
              <DropdownList>
                <Action
                  dropdownLinks={actionsMenuList}
                  handleChange={handleActionChange}
                />
              </DropdownList>
            </div>
            <div className="ms-3">
              <AddButton
                onClick={() => {
                  setIsVisible(true);
                }}
                label="Share"
              />
            </div>
          </div>
        </TitleWithStatusBadge>

        <div className="overflow-y-scroll" style={{ height: "72vh" }}>
          <Table>
            <thead>
              <tr>
                <th></th>
                {ArrayList.isArray(filteredShifts) &&
                  filteredShifts.map((shift, index) => (
                    <th key={index}>{shift?.name}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {ArrayList.isArray(locationList) &&
                locationList.map((location, rowIndex) => (
                  <tr key={rowIndex}>
                    <td
                      style={{
                        backgroundColor: "#292934",
                      }}
                      className="text-white fw-bold text-start"
                    >
                      {location?.label}
                    </td>
                    {ArrayList.isArray(filteredShifts) &&
                      filteredShifts.map((shift, colIndex) => (
                        <td key={colIndex}>
                          {isValidate(location, shift) && (
                            <Form
                              enableReinitialize={true}
                              initialValues={initialValues(location, shift)}
                            >
                              {isRecord(location, shift) && (
                                <div className="row align-items-center">
                                  <div className="col" style={{ flexGrow: 0 }}>
                                    {avatarCardProps(location, shift)?.url ? (
                                      <Image
                                        bannerStyle={{
                                          backgroundImage: `url(${avatarCardProps(location, shift)
                                              ?.url
                                            })`,
                                          height: "290px",
                                          backgroundPosition: "center center",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "30px",
                                        }}
                                      />
                                    ) : (
                                      <Avatar
                                        firstName={
                                          avatarCardProps(location, shift)
                                            ?.firstName
                                        }
                                        lastName={
                                          avatarCardProps(location, shift)
                                            ?.lastName
                                        }
                                      />
                                    )}
                                  </div>
                                  <div className="col">
                                    <p className="m-0">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex">
                                          <div>
                                            {String.concatName(
                                              avatarCardProps(location, shift)
                                                ?.firstName,
                                              avatarCardProps(location, shift)
                                                ?.lastName
                                            )}
                                          </div>
                                          {isPendingStatus(location, shift) && (
                                            <div className="text-danger ms-1">
                                              (Pending)
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <div style={{ textAlign: "end" }}>
                                            <div className="action-group-dropdown">
                                              <MoreDropdown>
                                                {statusProps(location, shift)
                                                  .status ===
                                                  Status.STATUS_PENDING_TEXT && (
                                                    <DropdownItem
                                                      onClick={() =>
                                                        handleStatusChange(
                                                          location,
                                                          shift,
                                                          Status.STATUS_CONFIRMED
                                                        )
                                                      }
                                                    >
                                                      Confirmed
                                                    </DropdownItem>
                                                  )}
                                                {statusProps(location, shift)
                                                  .status ===
                                                  Status.STATUS_CONFIRMED_TEXT && (
                                                    <DropdownItem
                                                      onClick={() =>
                                                        handleStatusChange(
                                                          location,
                                                          shift,
                                                          Status.STATUS_PENDING
                                                        )
                                                      }
                                                    >
                                                      Pending
                                                    </DropdownItem>
                                                  )}
                                                <DropdownItem
                                                  onClick={() => {
                                                    setIsOpenModel(true),
                                                      setRowValue({
                                                        location,
                                                        shift,
                                                      });
                                                  }}
                                                >
                                                  Change
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="text-danger"
                                                  onClick={() => {
                                                    handleUserSelect(
                                                      location,
                                                      shift,
                                                      null
                                                    );
                                                  }}
                                                >
                                                  Remove
                                                </DropdownItem>
                                              </MoreDropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              )}
                              {!isRecord(location, shift) && (
                                <div className="text-center">
                                  <Link
                                    to="#"
                                    className="link-opacity-75 text-decoration-none"
                                    onClick={() => {
                                      setIsOpenModel(true),
                                        setRowValue({
                                          location,
                                          shift,
                                          isAdd: true,
                                        });
                                    }}
                                  >
                                    Select
                                  </Link>
                                </div>
                              )}
                            </Form>
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <VerticalSapce bottom={3} />
        {locationAllocationDetail && locationAllocationDetail?.date && (
          <LeaveList
            history={props.history}
            params={{
              date: locationAllocationDetail?.date,
            }}
          />
        )}
        <StoreNotAssignedUserList filteredUserList={filteredUserList} />
      </TableContainer>
    </>
  );
};
export default LocationAllocationDetailPage;
