import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from "reactstrap";
import * as commonConstant from "../../common/constants";
import Logo from "../Logo";

const PublicHeader = ({
  portalName,
  headerColor,
  headerTextColor,
  portalLogo,
  portalLogoRedirectUrl,
  history
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  portalLogo = portalLogo ? portalLogo : "";



const handleRedirect=(isSignUp)=>{

  if(isSignUp){
  history.push("/company-signup")
  }else{
    history.push("/login")
  } 
}

  return (
    <div>
      <Navbar
        className={`navbar navbar-expand-lg text-white`}
        light
        expand="md"
        style={{
          backgroundColor: headerColor
            ? headerColor
            : commonConstant.DEFAULT_HEADER_COLOR,
          color: headerTextColor,
        }}
      >
        <div className={["container-fluid d-flex justify-content-between align-items-center"].join(" ")}>
          <Logo
            src={portalLogo}
            link={portalLogoRedirectUrl}
            altText={portalName}
            label={portalName}
          />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ms-auto" navbar>
              <NavItem>
                <NavLink style={{ color: headerTextColor ? headerTextColor :"#009dde"}} href="#" onClick={()=>handleRedirect(true)}>Sign Up</NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ color: headerTextColor ? headerTextColor :"#009dde"}} href="#" onClick={()=>handleRedirect(false)}>Login</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default PublicHeader;
